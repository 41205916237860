<template>
  <div>
    <div class="cdb-warp" v-if="!isMifi">
      <div class="cdb-lang-row">
        <img class="cdb-logo" src="@/assets/imgs/chargenow.png" alt="" />
        <div class="cdb-lang" @click="isLangView = true">
          <img v-if="langImg" :src="langImg" alt="" />
          <span>{{ lang }}</span>
        </div>
      </div>
      <div class="cdb-welecome">
        <img src="@/assets/imgs/img_welecome.png" alt="" />
      </div>
      <div class="cdb-title">{{ i18n.release_powerbank }}</div>

      <div class="cdb-body" @click="rentCostShow = true">
        <img src="@/assets/imgs/icon_time2.png" alt="" />
        <div
          class="cdb-price"
          v-if="cabinet && cabinet.priceStrategy.pType !== 2"
        >
          {{ (cabinet && cabinet.currencyName) || "$"
          }}{{ priceFormat(cabinet && cabinet.priceStrategy.pPrice) }}
          {{ i18n.per }} {{ cabinet && cabinet.priceStrategy.pPriceMinute
          }}{{
            cabinet && cabinet.priceStrategy.priceUnit
              ? $t(`home.${priceUnitMap[cabinet.priceStrategy.priceUnit]}`)
              : i18n.minute
          }}
        </div>
        <div class="cdb-price" v-else-if="cabinet && cabinet.priceStrategy">
          {{ i18n.deposit }}: {{ (cabinet && cabinet.currencyName) || "$" }}
          {{ cabinet.priceStrategy.pDepositAmount }}
        </div>
        <div class="cdb-info">{{ i18n.see_pricing }}</div>
        <img src="@/assets/imgs/icon_tips2.png" alt="" />
      </div>

      <div class="btn btn-cdb" @click="onSubmit">
        <span>{{ $t("paymethod.confrimpay").toUpperCase() }}</span>
      </div>

      <div class="cdb-agreen">
        {{ i18n.agreen }}
        <a @click="privacyPolicyURL">{{ i18n.privacy_policy }}</a>
        {{ i18n.and }}
        <a @click="userAgreementURL">{{ i18n.terms_and_conditions }}</a>
      </div>
      <!-- <tr
                  v-for="item in cabinet.priceStrategy.priceStrategyDetail"
                  :key="item.pId"
                >
                  <td>{{ item.pStartMinute }}~{{ item.pEndMinute }}</td>
                  <td class="text-success" style="font-weight: 600">
                    {{ item.pTotalFee }}
                  </td>
                </tr> -->
      <RentCostTip
        v-if="rentCostShow"
        :type="cabinet.priceStrategy.pType || 1"
        :jifei="cabinet.priceStrategy.pPrice || 2"
        :jifeiDanwei="cabinet.priceStrategy.priceUnit || 60"
        :fengding="cabinet.priceStrategy.pDayAmount || 6"
        :yajin="cabinet.yajin"
        :currencyName="cabinet.currencyName"
        :currency="cabinet.currency"
        :priceStrategyDetail="cabinet.priceStrategy.priceStrategyDetail"
        :overtimeAmount="cabinet.priceStrategy.pOvertimeAmount"
        :priceUnit="cabinet.priceStrategy.priceUnit"
        @close="(res) => (rentCostShow = res)"
      ></RentCostTip>
    </div>
    <div v-else>
      <div class="page-top"></div>
      <div class="page-title">
        {{
          (cabinet && cabinet.shopname) || (wifiBoxData && wifiBoxData.typeName)
        }}
      </div>
      <div class="vcard padding-h">
        <template v-if="isCdb">
          <div class="flex-al-c line-wrap">
            <van-icon
              :name="require('../../assets/imgs/icon_fix.png')"
              size="0.58rem"
            />
            <span class="pakage-info">{{
              cabinet && cabinet.shopAddress
            }}</span>
          </div>
          <div class="flex-al-c line-wrap">
            <van-icon
              :name="require('../../assets/imgs/icon_time_green.png')"
              size="0.58rem"
            />
            <span class="pakage-info"
              >{{ i18nHome.shopTime }}：{{ cabinet && cabinet.shopTime }}</span
            >
          </div>
        </template>
        <template v-else>
          <div class="flex-al-c line-wrap">
            <van-icon
              :name="require('../../assets/imgs/icon_web.png')"
              size="0.58rem"
            />
            <span class="pakage-info"
              >{{ i18nMine.deposit }}:
              {{
                (cabinet && cabinet.currencyName) ||
                (wifiBoxData && wifiBoxData.currencyName) ||
                "$"
              }}{{ wifiDeposit || 0 }}</span
            >
          </div>
        </template>
        <div class="flex-al-c line-wrap">
          <van-icon
            :name="require('../../assets/imgs/icon_web.png')"
            size="0.58rem"
          />
          <span class="pakage-info">{{ cabinet && cabinet.shopContent }}</span>
        </div>
      </div>
      <div
        v-if="isCdb"
        class="vcard flex-s padding-h"
        @click="deviceType = 'cdb'"
      >
        <div class="cdbflex">
          <van-image
            class="device-img"
            width="0.68rem"
            height="1.16rem"
            fit="contain"
            :src="require('../../assets/imgs/icon_power_box.png')"
          />
          <div>
            <div class="device-title">
              {{
                cabinet && cabinet.energyStorageFlag
                  ? i18n.powerstation
                  : i18n.powerbank
              }}
            </div>
            <template v-if="cabinet && cabinet.priceStrategy.pType !== 2">
              <div class="device-info">
                {{ (cabinet && cabinet.currencyName) || "$"
                }}{{ cabinet && cabinet.priceStrategy.pPrice }}/{{
                  cabinet && cabinet.priceStrategy.pPriceMinute
                }}{{
                  cabinet && cabinet.priceStrategy.priceUnit
                    ? $t(
                        `home.${priceUnitMap[cabinet.priceStrategy.priceUnit]}`
                      )
                    : i18n.minute
                }}
              </div>
              <div class="device-info">
                {{ i18n.首次免费
                }}{{ cabinet && cabinet.priceStrategy.pFreeuseMinute
                }}{{ i18n.minute }}.
              </div>
              <div class="device-info">{{ yajinInfo }}</div>
            </template>
            <template v-else-if="cabinet && cabinet.priceStrategy">
              <table class="timetable">
                <tr>
                  <th>
                    <span class="timetable-th-title">{{
                      i18n.lengthofuse
                    }}</span>
                    <span class="timetable-th-subtitle"
                      >({{ i18n.minute }})</span
                    >
                  </th>
                  <th>
                    <span class="timetable-th-title">{{
                      i18n.amountcharged
                    }}</span>
                    <span class="timetable-th-subtitle"
                      >({{ cabinet.currencyName }})</span
                    >
                  </th>
                </tr>
                <tr
                  v-for="item in cabinet.priceStrategy.priceStrategyDetail"
                  :key="item.pId"
                >
                  <td>{{ item.pStartMinute }}~{{ item.pEndMinute }}</td>
                  <td class="text-success" style="font-weight: 600">
                    {{ item.pTotalFee }}
                  </td>
                </tr>
              </table>
            </template>
          </div>
        </div>
        <van-icon
          v-if="deviceType == 'cdb'"
          :name="require('../../assets/imgs/icon_select.png')"
          size="0.6rem"
        />
        <van-icon
          v-else
          :name="require('../../assets/imgs/icon_unselect.png')"
          size="0.6rem"
        />
      </div>
      <div
        v-if="isMifi"
        class="vcard flex-s padding-h"
        @click="deviceType = 'mifi'"
      >
        <div class="flex-al-c">
          <van-image
            class="device-img"
            width="0.68rem"
            height="1.16rem"
            fit="contain"
            :src="require('../../assets/imgs/icon_wifi.png')"
          />
          <div>
            <div class="device-title">{{ i18n.wifibox }}</div>
            <div class="device-info">
              {{ i18n.devicedeposit
              }}{{
                (cabinet && cabinet.currencyName) ||
                (wifiBoxData && wifiBoxData.currencyName) ||
                "$"
              }}{{ wifiDeposit || 0 }}
            </div>
            <div class="device-info">{{ i18n.return_will_back }}</div>
          </div>
        </div>
        <van-icon
          v-if="deviceType == 'mifi'"
          :name="require('../../assets/imgs/icon_select.png')"
          size="0.6rem"
        />
        <van-icon
          v-else
          :name="require('../../assets/imgs/icon_unselect.png')"
          size="0.6rem"
        />
      </div>
      <div v-if="deviceType == 'mifi'" class="vcard" style="padding: 0">
        <div
          class="choose-card flex-s"
          @click="goRouterReplace('/wifiMall?type=QR')"
        >
          <span class="choose-title">{{ i18n.choosepackage }}</span>
          <van-icon
            :name="require('../../assets/imgs/icon_more.png')"
            size="0.53rem"
          />
        </div>
        <OrderItem
          v-if="mifiPackage"
          :width="6.5"
          :src="mifiPackage.flagUrl"
          :text="mifiPackage.text"
          :currencyName="mifiPackage.currencyName"
          :price="price"
          :useDay="diffday"
        ></OrderItem>
        <!-- <div class="order-box flex-al-c" style="display: flex;">
          <van-image class="order-img" width="7.04rem" height="3.18rem" fit="contain" :src="require('../../assets/imgs/img_orderDetail.png')" />
          <div>
            <div class="order-left-title">15 countries in Asia region - 500M/day </div>
            <div class="order-price">$ 35.00</div>
            <div class="order-info">Days of use: 7 days</div>
          </div>
        </div> -->
      </div>
      <div class="btn-box">
        <van-button class="p-btn" @click="onSubmit">{{
          i18nHome.borrow
        }}</van-button>
      </div>
      <div style="height: 20px"></div>
    </div>
    <select-lang v-model="isLangView"></select-lang>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { domain } from "../../utils/http"
import OrderItem from "../../components/orderItem/orderItem.vue"
import {
  MIFI_CHECK,
  QR_CODE,
  ZH_CODE,
  ORDER_TEMP,
  MIFI_PICKUP
} from "../../apis/cabinet"
import { MIFI_ORDERLIST, USER_CARD } from "../../apis/user"
import { SAVED_CARD_TO_RENT } from "../../apis/payment"
import dayjs from "dayjs"
import RentCostTip from "./component/rentcosttip.vue"
import { SelectLang } from "../../components/select"
import LT from "@/assets/imgs/lt-LT.png"
import US from "@/assets/imgs/en-US.png"
import CN from "@/assets/imgs/zh-CN.png"
import PT from "@/assets/imgs/pt-PT.png"
export default {
  components: {
    OrderItem,
    RentCostTip,
    SelectLang
  },
  computed: {
    ...mapState([
      "mificode",
      "qrcode",
      "zhcode",
      "cabinet",
      "mifiPackage",
      "lang"
    ]),
    i18n() {
      return this.$t("selectpackage")
    },
    i18nHome() {
      return this.$t("home")
    },
    i18nMine() {
      return this.$t("mine")
    },
    i18nMsg() {
      return this.$t("msg")
    },
    yajinInfo() {
      return this.$t("home.yajin_info", {
        currencyName: this.cabinet.currencyName,
        fengding: this.cabinet.priceStrategy.pDayAmount
      })
      // `${this.i18nHome.yajin_info}`.format(this.cabinet)
    },
    webDoMain() {
      return domain
    },
    scanUrl() {
      if (this.qrcode) {
        return `${QR_CODE}?id=${this.qrcode}`
      }
      if (this.zhcode) {
        return `${ZH_CODE}/${this.zhcode}`
      }
      return ""
    },
    cdbAmount() {
      if (this.cabinet) {
        if (this.cabinet.yajintype !== 0) {
          return (this.cabinet.yajin - this.cabinet.myyue).toFixed(2)
        }
        return this.cabinet.yajin
      } else {
        return 0
      }
    },
    wifiDeposit() {
      if (this.cabinet && this.cabinet.haveMifiBattery) {
        return this.cabinet.mifiDeposit
      } else if (this.wifiBoxData) {
        return this.wifiBoxData.depositAmount
      } else {
        return 0
      }
    },
    diffday() {
      if (this.mifiPackage && this.mifiPackage.price) {
        return this.mifiPackage.selectDays
      } else if (this.mifiPackage) {
        return dayjs(this.mifiPackage.reserverEndTime).diff(
          this.mifiPackage.reserverStartTime,
          "day"
        )
      }
      return 0
    },
    price() {
      if (
        this.mifiPackage &&
        this.mifiPackage.packageName &&
        this.mifiPackage.packageName.indexOf("Days") !== -1
      ) {
        return this.mifiPackage.price
          ? this.mifiPackage.price
          : this.mifiPackage.packagePrice
      } else if (this.mifiPackage) {
        let _price =
          Number(this.mifiPackage.price) * Number(this.mifiPackage.selectDays)
        return this.mifiPackage.price
          ? _price.toFixed(2)
          : (Number(this.mifiPackage.packagePrice) * this.diffday).toFixed(2)
      }
      return 0
    },
    priceUnitMap() {
      return {
        0: "分钟",
        1: "小时",
        2: "天"
      }
    },
    langImg() {
      if (this.lang === "lt-LT") {
        return LT
      } else if (this.lang === "zh-CN") {
        return CN
      } else if (this.lang === "pt-PT") {
        return PT
      } else if (this.lang === "en-US") {
        return US
      } else {
        return ""
      }
    }
  },
  watch: {
    deviceType(newVal, oldVal) {
      if (newVal === "mifi") {
        this.isMifi = true
      } else {
        this.isCdb = true
      }
    }
  },
  data() {
    return {
      isMifi: false,
      isCdb: false,
      deviceType: "cdb",
      wifiBoxData: null,
      rentCostShow: false,
      isLangView: false,
      cardList: []
    }
  },
  methods: {
    priceFormat(val) {
      if (val) {
        return val % 1 === 0 ? val : val.toFixed(2)
      }
      return val
    },
    goRouterReplace(path) {
      this.$router.replace(path)
    },
    goRouter(path) {
      this.$router.push(path)
    },
    onSubmit() {
      const cabinet = this.cabinet
      const uWallet = cabinet.userWallet || {}
      // 余额租借（用户余额 >= 超时金额 && 押金金额）
      if (
        uWallet.pCurrencySymbol === "XAF" &&
        uWallet.pBalance >= cabinet.yajin &&
        uWallet.pBalance >= cabinet.overtimeAmount
      ) {
        this.onBorrow()
        return
      }
      if (this.deviceType === "cdb") {
        this.$store.commit("mifiOrder", "")
        if (this.cabinet.zujieNum > 0) {
          this.$toast(`${this.i18nHome.msg_rentcount}`.format(this.cabinet))
          return
        }
        // /** 租借模式逻辑 **
        // userWallet 用户钱包 && yajin 押金金额  && overtimeAmount 超时金额
        const { userWallet = {}, yajin, overtimeAmount } = this.cabinet
        // 余额/免费 - 我的余额>押金金额>=超时金额
        const pBalance = userWallet.pBalance || 0
        if (pBalance >= yajin && pBalance >= overtimeAmount) {
          return this.onBorrow()
        }
        /*
        // 保存卡
        var _isUserCard = this.cardList.length > 0
        // 卡授权 - 押金金额 = 0 && 超时金额 > 0
        if (yajin <= 0 && overtimeAmount > 0) {
          if (_isUserCard) return this.saveToRent()
        } */
        // 需要卡授权的代理商户
        let isRentChanel =
          uWallet.pDailiId === "235317255863778" ||
          // 支付通道 & 押金大于余额 - 余额租借
          (this.cabinet.currency === "NGN" && pBalance < yajin)
        if (this.cabinet.yajinChongzhi === "Y" || isRentChanel) {
          this.onCdbRent()
        } else {
          this.onBorrow()
        }
      } else if (
        this.mifiPackage &&
        this.mifiPackage.depositAmount > 0 &&
        this.mifiPackage.packageStatus !== 33
      ) {
        // 交了押金和套餐费用的
        this.onScan(true)
      } else if (this.mifiPackage && this.mifiPackage.packageStatus === 33) {
        // 继续支付
        this.$router.push({
          path: "/confirm",
          query: {
            price: this.price,
            payType: "mifi",
            mifiOrderId: this.mifiPackage.orderId
          }
        })
      } else {
        // 仅设备或者设备+套餐
        let order = {
          productId: this.cabinet && this.cabinet.productId,
          depositAmount: this.cabinet && this.cabinet.mifiDeposit
        }
        if (
          this.mifiPackage &&
          (this.mifiPackage.depositAmount <= 0 ||
            !this.mifiPackage.depositAmount)
        ) {
          // order.productId = this.cabinet && this.cabinet.productId
          // order.depositAmount = this.cabinet && this.cabinet.mifiDeposit
          if (
            this.mifiPackage &&
            (this.mifiPackage.packageStatus === 0 ||
              this.mifiPackage.packageStatus)
          ) {
            order.currency = this.mifiPackage.currency
            order.pickUpOrderId = this.mifiPackage.pickUpOrderId
            order.mifiOrderId = this.mifiPackage.orderId
          }
        }
        if (
          this.mifiPackage &&
          (this.mifiPackage.packageStatus === undefined ||
            this.mifiPackage.packageStatus === null)
        ) {
          order = {
            ...order,
            ...this.mifiPackage
          }
          order.startTime = order.myStartime
            ? order.myStartime
            : dayjs().add(20, "minute").format("YYYY-MM-DD HH:mm:ss")
          order.endTime = dayjs(order.startTime)
            .add(order.selectDays, "day")
            .format("YYYY-MM-DD HH:mm:ss")
        }
        order.xugou = false
        let totalAmount = order.depositAmount
        if (
          this.mifiPackage &&
          this.mifiPackage.price &&
          (this.mifiPackage.packageStatus === undefined ||
            this.mifiPackage.packageStatus === null)
        ) {
          totalAmount += Number(this.mifiPackage.price)
        }
        this.$store.commit("mifiOrder", order)
        this.$router.push({
          path: "/confirm",
          query: { price: totalAmount, payType: "mifi" }
        })
      }
    },
    pickUpMifi(code) {
      this.$loading(true)
      this.$post(
        `${MIFI_PICKUP}`,
        {
          qrcode: code,
          pickOrderId: this.mifiPackage.orderId
        },
        (resp) => {
          this.$loading(false)
          console.log("数据===", resp)
          this.$router.push(
            `/borrowSuccess?tradeNo=${resp.data.orderId}&loading=true`
          )
        },
        (error) => {
          this.$loading(false)
          this.$toast(error)
        }
      )
    },
    // mifiCheck () {
    //   this.$loading(true)
    //   this.$post(
    //     `${MIFI_CHECK}?url=${encodeURIComponent(this.mificode)}`,
    //     {},
    //     resp => {
    //       console.log('检查是否可租借===', resp)
    //       this.wifiBoxData = resp
    //       if (resp.havePickOrder) {
    //         console.log('存套餐信息到本地')
    //       }
    //       this.isMifi = true
    //     },
    //     error => {
    //       this.$loading(false)
    //       this.$toast(error)
    //       console.log(error)
    //     }
    //   )
    // },
    onScan(isOrderId) {
      let isUrl = this.mificode.indexOf("http") !== -1
      let url = `${QR_CODE}?${isUrl ? "url" : "id"}=${encodeURIComponent(
        this.mificode
      )}${isOrderId ? `&mifiOrderId=${this.mifiPackage.orderId}` : ""}`
      this.$loading(true)
      this.$post(
        url,
        {
          usePriceUnit: true
        },
        (resp) => {
          this.$loading(false)
          if (isOrderId) {
            this.$store.commit("mifiOrder", this.mifiPackage)
            this.pickUpMifi(this.mificode)
            return
          }
          this.$store.commit("cabinet", {
            qrcode: this.code,
            ...resp
          })
          if (resp.zujieNum && resp.zujieNum > 0 && !resp.haveMifiBattery) {
            this.$confirm({
              title: this.i18nHome.msg_fail_title,
              content: `${this.i18nHome.msg_rentcount}`.format(resp),
              confirmText: this.i18nMsg.vieworder,
              cancelText: this.i18nMsg.cancel
            })
              .then(() => {
                this.$router.push("/pastOrder")
                // this.$store.commit('qrcode', '')
              })
              .catch(() => {
                // this.$store.commit('qrcode', '')
              })
          }
          this.isMifi = !!resp.haveMifiBattery
          this.isCdb = !!resp.haveBatteryCount
          if (this.$route.query.deviceType) {
            this.deviceType = this.$route.query.deviceType
          } else {
            if (resp.haveMifiBattery && resp.haveBatteryCount) {
              this.deviceType = "cdb"
            } else if (resp.haveMifiBattery) {
              this.deviceType = "mifi"
            } else if (resp.haveBatteryCount) {
              this.deviceType = "cdb"
            }
          }
        },
        (error) => {
          this.$loading(false)
          this.$toast(error)
        }
      )
    },
    onCdbRent() {
      this.$store.commit("wallet", null)
      // if (this.cabinet.yajintype === 1 || this.cabinet.yajintype === 2) {
      //   this.$router.push({ path: '/wallet_in', query: { price: this.amount, cabinetID: this.cabinet.qrcode, pbalance: this.cabinet.userWallet.pbalance } })
      //   return
      // }
      this.$router.push({
        path: "/confirm",
        query: {
          price: this.cdbAmount,
          payType: "YJ",
          cabinetID: this.cabinet.qrcode,
          yaJinType: this.cabinet.yajintype
        }
      })
    },
    onBorrow() {
      this.$loading(true)
      this.$get(
        // `${ORDER_TEMP}/${this.cabinet.qrcode}/paypal`,
        `${ORDER_TEMP}?qrcode=${this.cabinet.qrcode}`,
        {},
        (resp) => {
          this.$loading(false)
          console.log(resp)
          if (resp.data.outTradNo) {
            this.$router.push(
              `/borrowSuccess?tradeNo=${resp.data.outTradNo}&shopid=${this.cabinet.shopid}`
            )
          } else {
            this.$confirm({
              title: this.i18nHome.msg_fail_title,
              content: this.i18nHome.msg_fail_content,
              showCancel: false
            })
          }
        },
        (error) => {
          this.$loading(false)
          console.log(error)
          this.$toast(error)
        }
      )
    },
    saveToRent() {
      this.$loading(true)
      this.$get(
        `${SAVED_CARD_TO_RENT}`,
        {
          qrcode: this.cabinet.cabinetID || this.$route.query.qrcode
        },
        (resp) => {
          this.$loading(false)
          console.log(resp)
          if (resp.data && resp.data.out_trade_no) {
            this.checkOrder(resp.data.out_trade_no, this.payType)
          }
        },
        (error) => {
          this.$loading(false)
          console.log(error)
        },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8"
          }
        }
      )
    },
    privacyPolicyURL() {
      switch (this.lang) {
        case "":
        case "en-US":
          window.open("https://app.chargenow.top/content/privacy_policy.html")
          return
        default:
          window.open(
            `https://app.chargenow.top/content/privacy_policy_${this.lang}.html`
          )
      }
    },
    userAgreementURL() {
      switch (this.lang) {
        case "":
        case "en-US":
          window.open("https://app.chargenow.top/content/user_agreement.html")
          return
        default:
          window.open(
            `https://app.chargenow.top/content/user_agreement_${this.lang}.html`
          )
      }
    },
    getUserCardList() {
      let url = ""
      if (this.payType === "CZ") {
        url = `${USER_CARD}?userEnd=web${
          this.walletItem ? `&agentId=${this.wallet.pDailiId}` : ``
        }`
      } else if (this.payType === "mifi") {
        url = `${USER_CARD}?userEnd=mifi`
      } else if (
        (this.mificode && this.mificode.indexOf("http") === -1) ||
        this.payType === "YJ"
      ) {
        url = `${USER_CARD}?userEnd=web&qrcode=${this.mificode}`
      } else {
        url = `${USER_CARD}?userEnd=web&agentId=${this.agentOpenId}`
      }
      this.$loading(true)
      this.$get(
        url,
        {},
        (resp) => {
          this.$loading(false)
          console.log(resp)
          this.cardList = resp.data
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18nMsg.error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.getUserCardList()
          })
          console.log(error)
        },
        {
          headers: {
            agentOpenId: this.agentOpenId
          }
        }
      )
    }
  },
  created() {
    console.log(window.location.href)
    // 去除stripe返回的地址查询参数，防止到支付页后直接确认支付
    window.location.search = ""
    if (this.$route.query.cabinetshow === "true") {
      this.onScan()
    } else if (this.mificode) {
      this.onScan()
    } else {
      this.$router.go(-1)
    }
  },
  mounted() {
    // this.getUserCardList()
  }
}
</script>

<style lang="less" scoped>
.page-top {
  width: 100%;
  height: 442px;
  background: #ffffff;
  border-radius: 4px 4px 100px 100px;
  background-size: cover;
  background-image: url("../../assets/imgs/timg.png");
}

.line-wrap {
  // line-height: 3;
  margin: 20px 0;
}

.page-title {
  text-align: center;
  font-size: 44px;
  font-weight: bold;
  color: #1a2229;
  line-height: 3;
}

.pakage-info {
  font-size: 26px;
  font-weight: 400;
  color: #909090;
  margin-left: 20px;
}
.cdbflex {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.device-title {
  font-size: 28px;
  font-weight: bold;
  color: #1a2229;
}

.device-info {
  font-size: 24px;
  font-weight: 400;
  color: #666666;
}

.device-img {
  margin-right: 30px;
}
.timetable {
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 20px;
  border: solid 1px #d1d1d1;
  margin-right: 10px;
  td {
    border-top: 1px solid #d1d1d1;
    text-align: center;
  }
  th,
  td {
    padding: 10px 15px;
    color: #666666;
    border-right: 1px solid #d1d1d1;
  }
  th:last-child,
  td:last-child {
    border-right-color: transparent;
  }
  &-th-title {
    font-weight: normal;
  }
  &-th-subtitle {
    font-weight: normal;
    display: block;
    font-size: 20px;
  }
  .text-success {
    color: #00ab46;
  }
}
.choose-card {
  background: #cbedd9;
  border-radius: 18px 18px 18px 18px;
  padding: 20px;
}

.choose-title {
  font-size: 28px;
  font-weight: 600;
  color: #00ab46;
}

.p-btn {
  width: 100%;
  background: linear-gradient(132deg, #1a2229 0%, #00ab46 100%);
  box-shadow: 0px 6px 10px 1px rgba(49, 200, 137, 0.2);
  border-radius: 60px 60px 60px 60px;
  opacity: 1;
  color: #fff;
}

.btn-box {
  margin: 76px 40px;
}

.cdb-warp {
  min-height: 100vh;
  background-color: #fff;
  .cdb-welecome {
    padding-bottom: 40px;
    img {
      width: 520px;
      display: block;
      margin: 0 auto;
    }
  }
  .cdb-title {
    font-family: Montserrat-Bold;
    font-size: 52px;
    font-weight: bold;
    text-align: center;
  }
  .cdb-body {
    padding: 120px 32px 50px;
    display: flex;
    align-items: center;
    img {
      width: 48px;
      height: 48px;
    }
    .cdb-price {
      flex: 1;
      font-size: 28px;
      padding-left: 18px;
    }
    .cdb-info {
      font-size: 24px;
      padding: 0 18px;
    }
  }
  .btn-cdb {
    font-family: DIN-Condensed-Bold;
    font-size: 60px;
    margin: 0 32px;
    // height: 108px;
    background: #00b578;
    border-radius: 18px;
    font-weight: normal;
    vertical-align: middle;
    line-height: 1;
    padding-top: 32px;
    span {
      height: 76px;
    }
  }
  .cdb-agreen {
    padding: 30px 50px;
    font-family: Montserrat-Light;
    font-size: 24px;
    text-align: center;
    a {
      font-family: Montserrat-SemiBold;
      text-decoration: none;
      font-weight: bold;
    }
  }
}
.cdb-lang-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 48px 50px;
  .cdb-logo {
    height: 60px;
  }
}
.cdb-lang {
  padding: 20px;
  display: flex;
  border: 1px solid #666666;
  border-radius: 12px;
  img {
    display: block;
    width: 50px;
    margin-right: 14px;
  }
}
</style>
