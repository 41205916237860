<template>
  <page title="Wallet">
    <template v-if="list && list.length > 1 && !walletItem">
      <div
        class="vcard flex-s pay-item"
        v-for="(item, i) in list"
        :key="i"
        @click="chooseWallet(item)"
      >
        <div class="flex-c">
          <div
            class="icondollar"
            :style="{
              'border-color': `rgb(1, ${Math.floor(
                Math.random() * 256
              )}, ${Math.floor(Math.random() * 256)})`,
              color: `rgb(1, ${Math.floor(Math.random() * 256)}, ${Math.floor(
                Math.random() * 256
              )})`
            }"
          >
            {{ item.pCurrencySymbol }}
          </div>
          <!-- <img class="icondollar" :src="require('assets/imgs/img_Dollar.png')" /> -->
          <div>{{ item.pCurrencyType }}</div>
        </div>
        <div class="flex-c">
          <div class="pay-card">
            {{ item.pCurrencySymbol }}{{ item.pBalance }}
          </div>
          <img class="iconmore" :src="require('assets/imgs/icon_more.png')" />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="balance">
        <div class="balance-title">
          {{ i18n.balance }}
        </div>
        <div class="balance-num">
          {{
            walletItem
              ? walletItem.pCurrencySymbol
              : user && user.pcurrencyName
          }}{{
            walletItem ? walletItem.pBalance : (user && user.pbalance) || 0
          }}
        </div>
        <div class="balance-deposits">
          {{ i18n.deposit }}:
          {{ walletItem ? walletItem.pDepositAmount : user.pyajin }}
        </div>
        <div class="balance-line"></div>
        <div class="flex-c" style="padding-top: 10px">
          <div class="balance-Withdrawal" @click="goRouter(`/wallet_out`)">
            {{ i18n.wallet_out }}
          </div>
          <div
            class="balance-Recharge"
            @click="
              goRouter(
                `/wallet_in${query && query.price ? `?${querypath}` : ''}`
              )
            "
          >
            {{ i18n.wallet_in }}
          </div>
        </div>
      </div>
      <div class="pay">
        <div class="pay-head">{{ i18npay.银行卡列表 }}</div>
        <div
          class="vcard flex-s pay-item"
          v-for="item in cardList"
          :key="item.pId"
        >
          <div class="flex-c">
            <van-image
              width="1.2rem"
              fit="contain"
              :src="require('assets/imgs/icon_card@2x.png')"
            />
            <div>
              <!-- <div class="pay-title">{{i18npay.借记卡}}</div> -->
              <div class="pay-card">{{ item.pCardnumber }}</div>
            </div>
          </div>
          <div class="pay-currancy">
            <span
              class="pay-currancy-type"
              @click="removeCardConfirm(item.pId)"
              >{{ i18nbtn.Delete }}</span
            >
          </div>
        </div>
      </div>
      <div v-if="showAddCard" class="btn">
        <van-button class="bottom-pakage-btn" round @click="saveCardBefor">{{
          i18nbtn.添加新卡
        }}</van-button>
      </div>
    </template>
    <popup-warp v-model="paymentShow">
      <div class="payment">
        <div
          class="payment-item"
          v-for="item in payModeList"
          :key="item.pId"
          @click="choosePayment(item)"
        >
          <img :src="require('../../assets/imgs/icon_creditcard.png')" />
          <div class="payment-item-label">{{ item.label }}</div>
        </div>
      </div>
    </popup-warp>
    <popup-warp v-model="isCreditcard">
      <div>
        <div class="creditcard-input">
          <div id="credit-card-element"></div>
        </div>
        <div class="btn creditcard-btn" @click="onStripeSetup">
          {{ i18nConfirm.savenow }}
        </div>
      </div>
    </popup-warp>
    <popup-warp v-model="isDopago">
      <div>
        <div class="creditcard-input">
          <form id="form-checkout">
            <div id="form-checkout__cardNumber" class="container"></div>
            <div id="form-checkout__expirationDate" class="container"></div>
            <div id="form-checkout__securityCode" class="container"></div>
            <input type="text" id="form-checkout__cardholderName" />
            <select id="form-checkout__issuer"></select>
            <select id="form-checkout__installments"></select>
            <select id="form-checkout__identificationType"></select>
            <input type="text" id="form-checkout__identificationNumber" />
            <input type="email" id="form-checkout__cardholderEmail" />

            <button type="submit" id="form-checkout__submit">Pay</button>
            <progress value="0" class="progress-bar">Loading...</progress>
          </form>
        </div>
        <!-- <div class="btn creditcard-btn" @click="onStripeSetup">{{ i18n.confirmpay }}</div> -->
      </div>
    </popup-warp>
  </page>
</template>

<script>
import { loadMercadoPago } from "@mercadopago/sdk-js"
import { USER_INFO, USER_CARD, USER_WALLET_NEW } from "../../apis/user.js"
import {
  PAYMODE,
  STRIPE_SAVE_CARD,
  STRIPE_SAVE_CARD_CONFIRM,
  REMOVE_CARD,
  MERCADO_SAVECARDCONFIRM
} from "../../apis/payment"
import { mapState } from "vuex"
import stripeMixin from "../../mixins/stripe"
import { PopupWarp } from "../../components/popup"
let mp = null
let cardForm = null
export default {
  mixins: [stripeMixin],
  components: {
    PopupWarp
  },
  computed: {
    ...mapState([
      "user",
      "cabinet",
      "qrcode",
      "mificode",
      "agentOpenId"
    ]),
    i18n() {
      return this.$t("mine")
    },
    i18nMsg() {
      return this.$t("msg")
    },
    i18nConfirm() {
      return this.$t("confirm")
    },
    i18npay() {
      return this.$t("paymethod")
    },
    i18nbtn() {
      return this.$t("button")
    },
    userWalletOpenId() {
      if (this.cabinet && this.cabinet.userWallet) {
        return this.cabinet.userWallet.pDailiId
      } else if (process.env.VUE_APP_Domain === 'https://saas.dtoolsz.com') {
        return "BJCD0000000000000000000000000001"
      } else {
        return this.agentOpenId
      }
    },
    showAddCard() {
      const { qrcode, mificode, sn } = this.query || {}
      const { pCurrencyType } = this.walletItem || {}
      // 不支持添加卡的支付渠道：NGN
      const noCard = pCurrencyType !== "NGN"
      return noCard && (this.walletItem || qrcode || mificode || sn)
    }
  },
  watch: {
    walletItem(val) {
      if (val) {
        this.getUserCardList()
        this.getPayMode()
      }
    }
  },
  data() {
    return {
      list: [],
      cardList: [],
      payModeList: [],
      selected: null,
      query: null,
      querypath: window.location.href.split("?")[1],
      walletItem: null,
      creditOrder: null,
      creditcard: null,
      payMode: "",
      paymentShow: false,
      isCreditcard: false,
      isDopago: false
    }
  },
  methods: {
    chooseWallet(item) {
      this.walletItem = item
      this.$store.commit("wallet", item)
    },
    saveCardBefor() {
      if (this.payModeList && this.payModeList.length > 1) {
        this.paymentShow = true
      } else if (this.payMode) {
        if (this.payMode.pPaymentType === "STRIPE_CARD") {
          this.saveCard()
        } else {
          this.onMercadopago()
        }
      }
    },
    choosePayment(item) {
      console.log("选择的支付方式===", item)
      this.payMode = item
      this.paymentShow = false
      if (item.pPaymentType === "STRIPE_CARD") {
        this.saveCard()
      } else {
        this.onMercadopago()
      }
    },
    loadUser() {
      this.$loading(true)
      this.$get(
        USER_INFO,
        {},
        (resp) => {
          this.$loading(false)
          if (resp.user) {
            this.$store.commit("user", resp.user)
          } else {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: this.i18n.msg_fail_user,
              confirmText: this.i18nMsg.retry,
              cancelText: this.i18nMsg.cancel
            }).then(() => {
              this.loadUser()
            })
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18nMsg.error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.loadUser()
          })
          console.log(error)
        }
      )
    },
    goRouter(path) {
      this.$router.push(path)
    },
    getWalletList() {
      this.$loading(true)
      this.$get(
        USER_WALLET_NEW,
        {},
        (resp) => {
          this.$loading(false)
          console.log(resp)
          this.list = resp.data
          if (resp.data && resp.data.length > 0 && resp.data.length <= 1) {
            this.walletItem = resp.data[0]
            this.$store.commit("wallet", resp.data[0])
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18nMsg.error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.getWalletList()
          })
          console.log(error)
        }
      )
    },
    getUserCardList() {
      let url = `${USER_CARD}?userEnd=web`
      if (this.walletItem) {
        url = `${USER_CARD}?userEnd=web${
          this.walletItem ? `&agentId=${this.walletItem.pDailiId}` : ``
        }`
      } else if (this.query && this.query.type === "mifi") {
        url = `${USER_CARD}?userEnd=mifi`
      } else if (this.query && this.query.type === "cdb") {
        url = `${USER_CARD}?qrcode=${this.query.qrcode}`
      }
      this.$loading(true)
      this.$get(
        url,
        {},
        (resp) => {
          this.$loading(false)
          console.log(resp)
          this.cardList = resp.data
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18nMsg.error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.getUserCardList()
          })
          console.log(error)
        },
        {
          headers: {
            agentOpenId: this.agentOpenId
          }
        }
      )
    },
    getPayMode() {
      let url = `${PAYMODE}?agentId=${
        (this.walletItem && this.walletItem.pDailiId) || this.userWalletOpenId
      }&currency=${
        (this.walletItem && this.walletItem.pCurrencyType) ||
        this.user.p_currency
      }`
      if (this.query && this.query.type === "mifi") {
        if (this.query.mificode) {
          url = `${PAYMODE}?url=${encodeURIComponent(this.query.mificode)}`
        } else {
          url = `${PAYMODE}?sn=${this.query.sn}`
        }
      } else if (this.query && this.query.type === "cdb") {
        url = `${PAYMODE}?id=${this.query.qrcode}`
      }
      this.$loading(true)
      this.$get(
        url,
        {},
        (resp) => {
          this.$loading(false)
          if (resp.data && resp.data.length > 0) {
            let list = resp.data.filter((v, i, arr) => {
              if (v.pPaymentType === "STRIPE_CARD") {
                v.label = "Credit Card"
                return v
              }
              if (v.pPaymentType === "MercadoPago") {
                v.label = "MercadoPago"
                return v
              }
            })
            console.log("支付方式==", list, list[0])
            this.payModeList = list
            this.payMode = list[0]
            if (list.length > 0 && list[0].pPaymentType === "STRIPE_CARD") {
              this.loadStripeScript(list[0].publicKey)
            }
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18nConfirm.msg_fail_title,
            content: this.i18nConfirm.msg_fail_paymod,
            confirmText: this.i18nConfirm.msg_fail_retry,
            cancelText: this.i18nConfirm.msg_fail_cancel
          }).then(() => {
            this.getPayMode()
          })
          console.log(error)
        },
        {
          headers: {
            agentOpenId: process.env.VUE_APP_Domain === 'https://saas.dtoolsz.com' ? "BJCD0000000000000000000000000001" : ""
          }
        }
      )
    },
    saveCard() {
      console.log("this.payMode", this.payMode)
      if (!this.user) {
        this.$toast(this.i18nMsg.unlogin)
        return
      }
      if (!this.payMode || !this.payMode.pId) {
        this.$toast(this.i18nMsg.nopaymode)
        return
      }
      let url = `${STRIPE_SAVE_CARD}?paymentId=${this.payMode.pId}`
      this.$loading(true)
      this.$post(
        url,
        {},
        (resp) => {
          this.$loading(false)
          this.creditOrder = resp.data
          this.onLoadElement()
          console.log("saveCard====", resp)
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18nMsg.title,
            content: error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.saveCard()
          })
          console.log(error)
        }
      )
    },
    onLoadElement() {
      const options = {
        clientSecret: this.creditOrder.clientSecret,
        appearance: {
          theme: "stripe"
        }
      }
      if (window.stripe) {
        // this.loadStripeScript(this.payMode.publicKey)
        this.creditcard = window.stripe.elements(options)
        const paymentElement = this.creditcard.create("payment")
        this.isCreditcard = true
        paymentElement.mount("#credit-card-element")
      } else {
        this.$toast("loading...")
        this.loadStripeScript()
        setTimeout(() => {
          this.onLoadElement()
        }, 500)
      }
    },
    onStripeSetup() {
      const vm = this
      vm.$loading(true)
      let pid = this.payMode.pId
      let url = `${window.location.origin}/#/wallet?paymentId=${pid}&pmid=${this.creditOrder.paymentId}`
      window.stripe
        .confirmSetup({
          elements: this.creditcard,
          confirmParams: {
            return_url: url
          }
        })
        .then((result) => {
          console.log(result)
          vm.$loading(false)
          if (result.error) {
            vm.$toast(result.error.message)
          } else {
            console.log("成功====", result)
          }
        })
    },
    onConfirmSaveCard(pid, id) {
      this.$loading(true)
      this.$post(
        STRIPE_SAVE_CARD_CONFIRM,
        {
          paymentId: pid,
          paymentMethod: id
        },
        (resp) => {
          this.$loading(false)
          console.log("saveCardConfirm====", resp)
          this.getUserCardList()
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18nMsg.title,
            content: error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.onConfirmSaveCard(pid, id)
          })
          console.log(error)
        }
      )
    },
    // 阿根廷保存卡
    mercaDoPaGoPre(params) {
      let url = MERCADO_SAVECARDCONFIRM
      this.$loading(true)
      this.$post(
        url,
        params,
        (resp) => {
          this.$loading(false)
          console.log("mercaDoPaGo保存卡===", resp)
          if (resp.data) {
            this.isDopago = false
            mp = null
            cardForm = null
            delete window.MercadoPago
            this.getUserCardList()
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18nMsg.title,
            // content: this.i18n.msg_fail_order,
            content: error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.mercaDoPaGoPre(params)
          })
          console.log(error)
        },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8"
          }
        }
      )
    },
    async loadMercadopagoVo() {
      let that = this
      await loadMercadoPago()
      mp = new window.MercadoPago("TEST-dd9d7f32-8e5b-4edb-a6d8-863d1ec2e6a5")
      cardForm = mp.cardForm({
        amount: "500",
        iframe: true,
        form: {
          id: "form-checkout",
          cardNumber: {
            id: "form-checkout__cardNumber",
            placeholder: "Card Number"
          },
          expirationDate: {
            id: "form-checkout__expirationDate",
            placeholder: "MM/YY"
          },
          securityCode: {
            id: "form-checkout__securityCode",
            placeholder: "Security Code"
          },
          cardholderName: {
            id: "form-checkout__cardholderName",
            placeholder: "Cardholder"
          },
          issuer: {
            id: "form-checkout__issuer",
            placeholder: "Issuing bank"
          },
          installments: {
            id: "form-checkout__installments",
            placeholder: "Installments"
          },
          identificationType: {
            id: "form-checkout__identificationType",
            placeholder: "Document type"
          },
          identificationNumber: {
            id: "form-checkout__identificationNumber",
            placeholder: "Document number"
          },
          cardholderEmail: {
            id: "form-checkout__cardholderEmail",
            placeholder: "Email"
          }
        },
        callbacks: {
          onFormMounted: (error) => {
            if (error) {
              return console.warn("Form Mounted handling error: ", error)
            }
            console.log("Form mounted")
          },
          onSubmit: (event) => {
            event.preventDefault()

            const {
              paymentMethodId,
              issuerId,
              // cardholderEmail: email,
              amount,
              token,
              installments,
              identificationNumber,
              identificationType
            } = cardForm.getCardFormData()
            let param = {
              paymentId: this.payMode.pId,
              token,
              paymentMethodId,
              issuerId
            }
            this.mercaDoPaGoPre(param)
            console.log("MercadoPagotoken====", token)
          },
          onFetching: (resource) => {
            console.log("Fetching resource: ", resource)

            // Animate progress bar
            const progressBar = document.querySelector(".progress-bar")
            progressBar.removeAttribute("value")

            return () => {
              progressBar.setAttribute("value", "0")
            }
          }
        }
      })
    },
    onMercadopago() {
      this.isDopago = true
      mp = null
      cardForm = null
      this.loadMercadopagoVo()
    },
    // 移除卡
    removeCardConfirm(id) {
      this.$confirm({
        title: this.i18nMsg.title,
        content: this.i18nMsg.deletecard,
        confirmText: this.i18nMsg.confirm,
        cancelText: this.i18nMsg.cancel
      }).then(() => {
        this.removeCard(id)
      })
    },
    removeCard(id) {
      this.$loading(true)
      this.$get(
        `${REMOVE_CARD}/${id}`,
        {},
        (resp) => {
          this.$loading(false)
          console.log("removecard====", resp)
          this.getUserCardList()
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18nMsg.title,
            content: error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.removeCard(id)
          })
          console.log(error)
        }
      )
    }
  },
  created() {
    console.log(window.location.origin)
    this.query = this.$route.query || {}
    this.loadUser()
    if (this.query.qrcode || this.query.mificode || this.query.sn) {
      this.getPayMode()
    } else {
      this.getWalletList()
    }
    let { paymentId, pmid } = this.$route.query
    if (paymentId) {
      this.onConfirmSaveCard(paymentId, pmid)
    }
  }
}
</script>

<style lang="less" scoped>
.btn {
  margin: 40px;

  .bottom-pakage-btn {
    background: linear-gradient(132deg, #1a2229 0%, #00ab46 100%);
    box-shadow: 0px 6px 10px 1px rgba(0, 171, 70, 0.2);
    border-radius: 60px 60px 60px 60px;
    padding: 23px 73px;
    color: #fff;
    width: 100%;
    font-size: 36px;
    font-family: Work Sans-Bold, Work Sans;
    font-weight: bold;
  }
}
.iconmore {
  width: 50px;
}
.icondollar {
  padding: 5px;
  width: 50px;
  line-height: 50px;
  height: 50px;
  border-width: 2px;
  border-style: solid;
  border-radius: 100px;
  text-align: center;
  margin-right: 10px;
}

.pay {
  &-card {
    color: #00ab46;
    font-size: 30px;
    font-family: Work Sans-Medium, Work Sans;
    margin-left: 35px;
  }
  &-head {
    font-size: 32px;
    font-family: Montserrat-Bold, Montserrat;
    font-weight: bold;
    color: #1a2229;
    padding: 20px 40px;
  }

  &-item {
    margin-bottom: 20px;
  }

  &-title {
    font-size: 30px;
    font-family: Work Sans-Medium, Work Sans;
    color: #444444;
    margin-left: 35px;
  }

  &-currancy {
    font-size: 28px;
    font-family: Work Sans-Regular, Work Sans;
    color: #ff1919;

    &-type {
      margin-right: 10px;
    }
  }
}

.balance {
  background: url("../../assets/imgs/img_dollar@2x.png") no-repeat center;
  background-size: cover;
  width: 96%;
  height: 5.6rem;
  margin: 0 auto;
  color: #ffffff;
  font-family: Work Sans-Medium, Work Sans;

  &-title {
    font-size: 32px;
    padding: 20px 30px;
  }

  &-num {
    font-size: 60px;
    font-weight: bold;
    text-align: center;
    padding: 35px 0;
  }

  &-deposits {
    font-size: 24px;
    text-align: end;
    padding-right: 30px;
  }

  &-line {
    width: 90%;
    height: 0px;
    opacity: 0.2;
    border: 2px solid #ffffff;
    margin: 0px auto;
    margin-top: 40px;
  }

  &-Withdrawal {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 57px;
    border-right: 2px solid #ffffff;
  }

  &-Recharge {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 57px;
  }
}

.selected {
  border: solid 2px #309df7;
  border-top: solid 2px #309df7 !important;
}

.Mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.payment {
  overflow-y: scroll;
  padding: 20px 40px 40px;
  .payment-item {
    display: flex;
    align-items: center;
    margin-top: 20px;
    font-size: 40px;
    box-shadow: 0 0 10px rgb(201, 201, 201);
    padding: 10px 20px;
    img {
      width: 80px;
      margin-right: 10px;
    }
  }
}
</style>
