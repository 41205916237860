<template>
  <page :title="i18n.title">
    <div v-if="second > 0" class="result-loading-warp">
      <div class="result-loading circle">
        <circle-progress v-model="percent" />
        <span>{{ secondFmt }}s</span>
      </div>
      <div class="result-loading-tips">
        {{ i18n.msg_tips_loading }}
      </div>
    </div>
    <div v-else-if="order">
      <div class="result-modal-head">
        <img src="../../assets/imgs/success.png">
        <div>{{ i18n.msg_success }}</div>
      </div>
      <div class="result-modal-warp">
        <div class="result-modal-cabinet">
          <img :src="imgSrc">
        </div>
        <div class="result-modal-remark">
          <div>{{ i18n.return_info }}</div>
        </div>
      </div>
    </div>
    <result-msg v-else-if="loading" type="100" :text="i18n.msg_result_loading" />
    <result-msg v-else-if="error" type="500" :text="error" @click="onReload" />
    <result-msg v-else />
  </page>
</template>

<script>
import { mapState } from 'vuex'
import CircleProgress from '../../components/result/circle_progress.vue'
import ResultMsg from '../../components/result/msg.vue'
import { ORDER_CHECK } from '../../apis/cabinet.js'
import { PAYPAL_EXC_PAY, MPESA_PAYCONFIRM } from '../../apis/payment.js'
let loadingTimer
export default {
  components: {
    ResultMsg,
    CircleProgress
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    paypal (val, oldVal) {
      if (val && val.paymentId) {
        this.onPayExec()
      }
    }
  },
  computed: {
    ...mapState(['cabinet']),
    i18n () {
      return this.$t('result')
    },
    i18nMsg () {
      return this.$t('msg')
    },
    percent () {
      return (this.second % 30) * 3.334
    },
    secondFmt () {
      return (this.second + 0.45).toFixed(0)
    },
    shopDesc () {
      return `${this.i18n.price_info}`.format(this.order)
    },
    infoTips1 () {
      return `${this.i18n.tips_1}`.format(this.order)
    },
    infoTips2 () {
      return this.i18n.tips_2
    },
    imgSrc () {
      if (this.order && this.order.shebei) {
        return require(`../../assets/imgs/cabinet${this.order.shebei}_${this.order.number}.gif`)
      } else {
        return require(`../../assets/imgs/cabinet.png`)
      }
    }
  },
  data () {
    return {
      loading: false,
      error: '',
      paypal: null,
      order: null,
      tradeNo: '',
      second: 0,
      payMode: 'paypal'
    }
  },
  methods: {
    startTimer () {
      if (this.second === 0) {
        loadingTimer = setInterval(() => {
          this.second += 0.1
        }, 100)
      }
    },
    stopTimer () {
      clearInterval(loadingTimer)
      this.second = 0
    },
    onInput (val) {
      this.$emit('input', val)
    },
    onReload () {
      if (this.tradeNo) {
        this.checkOrder(this.tradeNo, this.payMode)
      } else {
        this.onPayExec()
      }
    },
    onPayExec () {
      this.startTimer()
      this.loading = true
      this.$post(
        PAYPAL_EXC_PAY,
        {
          ...this.paypal
        },
        resp => {
          this.loading = false
          this.error = ''
          const data = resp.data
          if (data.out_trade_no) {
            if (data.pay_type === 'CZ') {
              this.$confirm({
                title: this.i18nMsg.title,
                content: this.i18nMsg.success_payment,
                confirmText: this.i18nMsg.confirm,
                showCancel: false
              }).then(() => {
                this.$router.push('/mine')
              })
            } if (data.pay_type === 'YJ') {
              this.checkOrder(data.out_trade_no)
            }
          }
        },
        error => {
          if (this.second < 30) {
            this.onPayExec()
          } else {
            this.stopTimer()
            this.loading = false
            this.error = error
            console.log(error)
          }
        }
      )
    },
    checkOrder (tradeNo, payMode = 'paypal', payType = '') {
      if (!payType) {
        this.startTimer()
      }
      // if (payMode === 'mpesa') {
      //   this.onSMSCheckOrder(tradeNo, payMode, payType)
      //   return
      // }
      this.tradeNo = tradeNo
      this.loading = true
      this.$post(
        `${ORDER_CHECK}/${tradeNo}/${payMode}`,
        {},
        resp => {
          if (payType === 'FK') {
            this.$router.replace({ path: '/order' })
          } else if (payType === 'CZ') {
            this.$router.replace({ path: '/wallet' })
          }
          this.loading = false
          this.error = ''
          this.stopTimer()
          this.order = resp
        },
        error => {
          if (payType === 'FK') {
            setTimeout(() => {
              this.$router.replace({ path: '/order' })
            }, 1500)
          } else if (payType === 'CZ') {
            setTimeout(() => {
              this.$router.replace({ path: '/wallet' })
            }, 1500)
          } else if (error === '正在请求中' || error === 'Processing') {
            setTimeout(() => {
              this.checkOrder(tradeNo, payMode, payType)
            }, 1000)
          } else {
            this.stopTimer()
            this.loading = false
            this.error = error
            console.log(error)
          }
        }
      )
    }
  },
  created () {
    console.log('result route:', this.$route.query)
    let { paymentId, token, PayerID, tradeNo, payMode, payType } = this.$route.query
    this.payMode = payMode
    if (tradeNo) {
      this.checkOrder(tradeNo, payMode, payType)
    } else {
      this.paypal = {
        paymentId,
        token,
        payerId: PayerID
      }
    }
  },
  destroyed () {
    this.$store.commit('cabinet', null)
  }
}
</script>

<style lang="less">
.result-modal-head {
  padding: 40px 32px;
  height: 70px;
  display: flex;
  align-items: center;
}

.result-modal-warp {
  overflow-y: scroll;
}

.result-modal-head img {
  width: 56px;
  height: 56px;
}

.result-modal-head div {
  padding-left: 10px;
}

.result-modal-cabinet {
  padding-bottom: 32px;
  width: 100%;
  text-align: center;
  background: #FFFFFF;
}

.result-modal-cabinet img {
  width: 360px;
  margin: 0 auto;
  height: 360px;
}

.result-modal-info {
  // background: rgba(255,255,255,1);
  // background-img: url(../../static/imgs/card_bg.png);
  // background-size: 100% 100%;
  background: rgba(255, 255, 255, 1);
  padding: 0 32px;

  &-warp {
    box-shadow: 0px 2px 16px 0px rgba(153, 153, 153, 0.32);
    border-radius: 24px;
    padding: 32px 32px 0;
    word-break: normal;
  }

  & .title {
    font-size: 36px;
    font-weight: 500;
    color: rgba(52, 125, 255, 1);
    padding: 8px 0;
  }

  & .desc {
    font-size: 28px;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
  }
}

.result-modal-btn {
  display: flex;
  padding: 40px 16px;
}

.result-modal-btn .help-btn {
  flex: 1;
  height: 80px;
  line-height: 80px;
  border-radius: 48px;
  text-align: center;
  border: 2px solid rgba(52, 125, 255, 1);
  font-size: 28px;
  font-weight: 500;
  color: rgba(52, 125, 255, 1);
  margin: 0 14px;
}

.result-modal-remark {
  font-size: 28px;
  padding: 40px 32px;
  color: rgba(102, 102, 102, 1);

  & .highlight {
    font-size: 50px;
  }
}

.result-modal-remark .bold {
  font-size: 32px;
  color: rgba(51, 51, 51, 1);
}

.result-modal-remark div {
  padding: 8px;
}

.result-loading {
  &.circle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-warp {
    background: #FFFFFF;
    padding: 30px 0;
  }

  & span {
    position: absolute;
    font-size: 70px;
    color: #F13453;
  }

  &-tips {
    text-align: center;
    font-size: 28px;
    color: #347DFF;
  }
}
</style>
