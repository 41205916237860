export default {
  home: {
    minute: 'minuto',
    price_info: 'Gratis durante los primeros {mianfei} minutos. Un cargo máximo diario de {currency) Nombre  {fengding}. El depósito se deducirá automáticamente cuando el alquiler alcance  {currencyName} {yajin}',
    feature_1: 'Se adapta a todo tipo de teléfonos moviles',
    feature_2: 'Se puede devolver en cualquier otra estación',
    feature_3: 'Diseño especial: carga inalámbrica (solo para módulos compatibles)',
    tips_1: 'Tú depósito de {currencyName}{yajin} te será reembolsado después de que devulevas el banco de energía .',
    yajin_info: 'Un cargo diario máximo de {currencyName}{fengding}',
    tips_2: 'Depósito de recarga {currencyName}{yajin} Se puede alquilar, lo agotado se puede reembolsar',
    tips_3: 'El saldo actual es {currencyName}{myyue}, que se puede alquilar directamente',
    submit: 'Recargar',
    borrow: 'Alquilar',
    msg_fail_title: 'Error',
    msg_fail_content: 'El alquiler falló. Inténtalo de nuevo',
    msg_rentcount: 'Actualmente tienes {zujieNum} pedidos que no están cerrados',
    goborrow: 'Ir a cargar!!!',
    nearby: 'Ver puntos de venta cercanos',
    preauth: 'Pre-autorización',
    shopTime: 'Horario comercial',
    分钟: 'minuto',
    小时: 'hora',
    天: 'día'
  },
  button: {
    添加新卡: 'Agregar nueva tarjeta',
    delete_card: "Eliminar tarjeta",
    Delete: 'Borrar'
  },
  selectpackage: {
    powerbank: 'Power Bank',
    powerstation: 'Estación de Carga',
    wifibox: ' Caja WiFi ',
    choosepackage: 'Elija el tiempo de alquiler',
    minute: 'minuto',
    首次免费: 'Primera carga gratis',
    最大每日收费: 'Una carga máxima diaria de',
    devicedeposit: 'Depósito de equipos',
    return_will_back: 'Devolver el depósito después de devolver el equipo',
    lengthofuse: 'Duración del uso',
    amountcharged: 'Monto de la tarifa acumulada',
    release_powerbank: 'Libera tu power bank',
    see_pricing: 'Ver precios',
    deposit: 'depósito',
    agreen: 'Al continuar, aceptas nuestra',
    privacy_policy: 'Política de Privacidad',
    and: ' y ',
    terms_and_conditions: 'Términos y condiciones.',
    per: 'por'
  },
  wifimall: {
    Select: 'Seleccionar',
    subtitle: 'Paquetes recomendados',
    dayuse: 'Días de uso',
    Verify: 'Verificar',
    无限流量: 'Ilimitado',
    天: 'Día',
    days: 'Días',
    useDay: 'Días de uso',
    mypackages: 'Seleccione un enlace de paquete existente',
    没有更多了: 'No más',
    加载中: 'cargando',
    depositof: 'Incluye un depósito de',
    usagetime: 'Tiempo de uso'
  },
  paymethod: {
    payamount: 'Monto del pago',
    choosepaymethod: 'Elija opciones de pago',
    addcard: 'Agregar nueva tarjeta',
    confrimpay: 'Confirmar pago',
    借记卡: 'Tarjeta de débito',
    银行卡列表: 'Lista de tarjetas bancarias'

  },
  login: {
    title: 'Inicia sesión o regístrate',
    code_title: 'Verificación por SMS',
    phone_number: 'Número de teléfono',
    verification_code: 'Ingrese el código de verificación por SMS. Si no hay ningún código de verificación, pruebe con otro método de inicio de sesión.',
    captcha_code: 'Código',
    send_code: 'Enviar código',
    login: 'Acceso',
    agree: 'Al hacer clic en Iniciar sesión, aceptas',
    readme: `Acuerdo de usuario`,
    msg_fail_login: 'Error de inicio de sesion',
    msg_success_login: 'Inicio de sesión exitoso',
    msg_fail_user: 'Error al obtener información del usuario. ¿Quieres intentarlo de nuevo?',
    sign_in_with: 'Iniciar sesión con',
    no_sms: 'No recibí mensaje de texto',
    retry: 'Reenviar',
    applelogin: 'Iniciar sesión con Apple',
    otherlogin: 'Otras opciones',
    quicklogin: 'Inicio de sesión rápido',
    mobilelogin: 'Inicio de sesión móvil',
    emaillogin: 'Iniciar sesión por correo electrónico',
    facelogin: 'Iniciar sesión con Facebook'
  },
  confirm: {
    'Credit Card': 'Tarjeta de crédito',
    WeChat: 'WeChat',
    'Apple Pay': 'Apple Pay',
    'Google Pay': 'Google Pay',
    'SMS Payment': 'SMS Payment',
    'Idael Pay': 'Idael Pay',
    title: 'Confirmación de pago',
    savenow: 'Guardar ahora',
    confirmpay: 'confirmar pago',
    SMSConfirm: 'Pague de acuerdo con el mensaje de texto recibido en su teléfono y haga clic en el botón confirmar para continuar.',
    pay_label: 'Cantidad',
    auth_label: 'Pre-autorización',
    price_tips: 'Su saldo puede ser retirado si así lo solicita. Puede tardar entre 1 y 5 días laborables en aparecer en su cuenta .',
    auth_tips: 'Un monto de preautorización de {0}{1} es obligatorio. El importe del alquiler real se deducirá cuando se devuelva el powerbank.',
    pay_type: 'Por favor seleccione su método de pago preferido',
    submit: 'Confirmar pago',
    msg_loading: 'Cargando...',
    msg_fail_title: 'Advertencia',
    msg_fail_payment: 'No se pudo iniciar el pago del depósito',
    msg_fail_order: 'No se pudo crear la orden de arrendamiento',
    msg_fail_support: 'El sistema no es compatible',
    msg_fail_confirm: 'Confirmar',
    msg_fail_retry: 'Volver a intentar',
    msg_fail_cancel: 'Cancelar',
    msg_none_msg: 'Por favor ingrese otra información que sea conveniente para identificarlo'
  },
  mine: {
    title: 'Perfil de usuario',
    wallet: 'Mi billetera',
    balance: 'Mi saldo',
    deposit: 'Depósito',
    wallet_in: 'Cargar',
    wallet_out: 'Retirar',
    wallet_log: 'Registro de billetera',
    order: 'Orden',
    coupon: 'Cupón',
    login_tips: 'Por favor inicia sesión',
    msg_fail_title: 'Error',
    msg_fail_update: 'La modificación falló, por favor inténtalo de nuevo.',
    msg_loading: 'Cargando...',
    msg_fail_user: 'Error al obtener información del usuario. ¿Quieres intentarlo de nuevo?',
    help: 'Ayuda',
    setting: 'Configuración',
    logout: 'Cerrar sesión',
    buy: 'Comprar equipo',
    aboutus: 'Sobre nosotros',
    contactus: 'Contacta con nosotros'
  },
  msg: {
    no_phone: 'Se requiere número de teléfono',
    loading: 'Cargando...',
    title: 'Pista',
    success: 'Operación exitosa',
    sendsuccess: 'Enviado exitosamente',
    error: 'Error desconocido, inténtalo de nuevo más tarde.',
    deletecard: '¿Estás seguro de eliminar esta tarjeta?',
    confirm: 'Confirmar',
    retry: 'Volver a intentar',
    cancel: 'Cancelar',
    title_fail: 'Advertencia',
    vieworder: 'Ver pedido',
    success_payment: 'Pago completado',
    success_buy: 'Compra completada',
    fail_payment: 'Fallo de pago',
    keyword: 'Palabra clave',
    nodata: 'Sin datos',
    copysuccess: 'Repetir el proceso',
    notopen: 'Aún no abierto',
    devicenotactivated: 'Dispositivo no activado',
    pickup_fail: 'No se pudo recuperar el dispositivo. Por favor inténtalo de nuevo más tarde.',
    msg_none_name: 'Por favor ingresa tu nombre',
    msg_none_email: 'Por favor ingrese su dirección de correo electrónico',
    msg_none_mobile: 'Por favor ingresa tu número de teléfono',
    msg_none_nation: 'Por favor ingresa tu país',
    msg_none_msg: 'Por favor ingrese otra información que sea conveniente para identificarlo',
    msg_fail_choosepackage: 'Seleccione la estacion para recoger el power bank',
    geolocationfailed: 'Error: El servicio de Geolocalización falló.'
  },
  result: {
    title: 'Confirmación de resultados',
    price_plan: 'Estándar de carga',
    msg_success: 'Alquilado con éxito',
    msg_tips_loading: 'La batería portátil está apareciendo, espere pacientemente...',
    msg_result_loading: 'Se está confirmando el resultado del pago....',
    msg_fail_confirm: 'Confirmar',
    btn_usage: 'Nota de uso',
    btn_return: 'Nota de devolución',
    deposit: 'Depósito',
    msg_fail_content: 'La solicitud falló, inténtelo de nuevo',
    price_label: 'por hora',
    price_info: 'Primera carga gratis {mianfei} minutos，Una carga diaria máxima de{ccurrency}{fengding}，El depósito se deducirá automáticamente cuando llegue el alquiler {ccurrency}{yajin}',
    tips_1: 'Por favor recoja el power bank <span class="number">No.{number}</span> como se muestra en la imagen',
    tips_2: 'Ordene los cables e inserte el banco de energía hasta el fondo con el<span class="highlight">[arrow downward]</span> cuando regrese',
    msg_note_usage: 'El power bank está equipado con 3 tipos de cables y cambio inalámbrico, utilícelo según sea necesario.',
    msg_note_return: 'Ordene estos cables e inserte el power bank en el maletero con la [arrow downward] cuando regrese.',
    return_info: 'Cuando regrese, coloque bien los cables e inserte el lado de metal en una ranura vacia hasta que llegue al fondo y se estabilice..'
  },
  result_msg: {
    loading: 'Cargando...',
    refresh: 'Refrescar'
  },
  order: {
    title: 'Orden de alquiler',
    in_use: 'En uso',
    ended: 'Terminado',
    enter_order_email: "Por favor ingrese el correo electrónico",
    msg_error: 'Solicitud fallida'
  },
  order_item: {
    order_number: 'No.',
    venue_rented: 'Lugar de alquiler',
    time_rented: 'tiempo alquilado',
    venue_returned: 'Lugar devuelto',
    time_returned: 'Tiempo de regreso',
    charge_standard: 'Estándar de carga',
    charge_standard_info: '{pJifei} {pCurrency} por hora(first {pMian} mins for free)',
    rent_occurred: 'El alquiler se produjo',
    returned: 'Devuelto',
    unreturned: 'No devuelto',
    type_0: 'No completado',
    type_1: 'Alquilado',
    type_2: 'Cancelado',
    type_3: 'Devuelto',
    type_4: 'Se produjo una falla',
    type_5: 'Sobretiempo',
    min: 'Minutos',
    payment: 'Pago'
  },
  setting: {
    title: 'Configuración',
    lang: 'Idioma'
  },
  help: {
    title: 'Preguntas frecuentes'
  },
  wallet_in: {
    title: 'Recarga de Saldo',
    price_label: 'Monto de recarga',
    price_placeholder: 'Por favor ingresa el monto de la recarga',
    balance: 'Saldo actual',
    price_auto: 'Elija el monto del pago',
    price_tips: 'Your El saldo se puede retirar si así lo solicita. Puede tardar entre 1 y 5 días laborables en aparecer en su cuenta.',
    submit: 'Entregar',
    loading: 'Cargando...',
    paying: 'Saltar a la página de pago...',
    msg_title: 'Consejos',
    msg_fail_order: 'No se pudo crear la orden de recarga',
    msg_fail_payment: 'No se pudo iniciar el pago de recarga',
    msg_fail_retry: 'Volver a intentar',
    选择充值金额: 'Seleccione el monto de la recarga',
    输入充值金额: 'Ingrese el monto de la recarga',
    确认充值: 'Confirmar la recarga'
  },
  wallet_out: {
    title: 'Retiro',
    price_label: 'Withdrawal amount',
    price_placeholder: 'Por favor ingrese el monto del retiro',
    balance: 'Saldo actual',
    balance_all: 'Retirar todo',
    deposit: 'Depósito',
    deposit_tips: 'Hay una orden de alquiler. El depósito de{pcurrencyName}{pyajin} no es reembolsable',
    submit: 'Entregar',
    apply: 'Aplicar',
    confirm: 'Confirmar',
    loading: 'Cargando...',
    msg_fail_price: 'El monto del retiro es incorrecto',
    msg_fail_balance: 'El monto del retiro no puede ser mayor que el saldo actual.',
    msg_fail_submit: 'Por favor complete la información completa',
    msg_title: 'Error',
    msg_success_content: 'Aplicar reembolso exitoso',
    confirmtitle: 'Consejos',
    confirmcontent: 'La billetera actual no admite retiros de efectivo. Tenga en cuenta su información de contacto y reembolse manualmente',
    payee_name: 'Nombre del beneficiario',
    payee_bankcard: 'Número de tarjeta bancaria',
    payee_phone: 'Teléfono',
    cancelText: 'Cancelar',
    confirmText: 'Aplicar de nuevo',
    退款金额: 'Monto del reembolso'

  },
  map: {
    title: 'Cerca del equipo',
    result_msg: 'No se encontró ningún equipo cerca.',
    msg_title: 'Error',
    no_support: '¡Lo siento! Su navegador no admite ubicación',
    my_position: 'mi ubicación',
    distance: 'distancia:',
    shop_time: 'horas',
    free: 'Disponible',
    return: 'retornable',
    shop_title: 'Detalles del comerciante',
    address: 'Dirección',
    go_here: 'Ir aquí'
  },
  scanOrder: {
    viewflowdetail: 'Ver detalles de uso del tráfico',
    confirmpickup: 'Confirmación de recojo',
    QRpickup: 'QR code pickup',
    取机中: 'Levantar',
    scanQR: 'Escanee el código QR para vincularse',
    qrCodePickup: 'QR code pickup',
    orderNo: 'No. de orden',
    startingTime: 'Hora de inicio',
    endTime: 'Hora de finalización',
    packageStatus: 'Estado de alquiler',
    无限流量: 'Ilimitado',
    天: 'Día',
    days: 'días',
    type_0: 'No usado',
    type_1: 'Para ser activado',
    type_2: 'activado',
    type_3: 'Revocado',
    type_4: 'reintegrado',
    type_9: 'over',
    type_33: 'no pagado',
    type_88: 'Cancelado',
    inputserialNumber: 'Por favor ingrese el número de serie del dispositivo',
    pickupsuccess: 'Se recuperó exitosamente la máquina',
    continuepay: 'Continuar pago'
  },
  borrowSuccess: {
    success: 'Alquiler exitoso',
    renewalTraffic: 'Tráfico de renovación',
    viewOrder: 'Ver pedido',
    HowUseDevice: 'Cómo utilizar el dispositivo',
    download_tips: 'Puedes dejar tu email y te enviaremos los detalles de este pedido a tu correo electrónico',
    download_tips2: 'Vea otros lugares de devolución y sus funciones, descargue la aplicación.',
    download: 'descargar',
    loading_title: 'Desbloquear el power bank',
    loading_tips: 'por favor espera pacientemente...',
    send: 'Enviar'
  },
  packageOrder: {
    viewdetail: 'Ver detalles',
    buynow: 'Comprar ahora',
    isrent: '¿Se alquila el equipo?',
    timelimited: 'Oferta por tiempo limitado'
  },
  packageDetail: {
    WiFiName: ' Nombre de WiFi ',
    WiFiPassword: 'Contraseña de WiFi ',
    batteryLife: 'Nivel de la batería',
    Signal: 'Señal',
    orderNo: 'No. orden',
    startingTime: 'Hora de inicio',
    endTime: 'Hora de finalización',
    packageStatus: 'Estado de alquiler',
    usedTraffic: 'Tráfico usado',
    Renewals: 'Compra de tráfico',
    inuse: 'En uso'
  },
  orderDetail: {
    price1: '{pCurrencyName}{pJifei} por {pJifeiDanwei}{priceUnit}',
    price2: 'Arriba a{pCurrencyName}{pFengding} por 24 horas',
    price3: 'Tarifa por no devolución de{pCurrencyName}{overtimeAmount}',
    venuerented: 'Lugar de alquiler',
    venuereturned: 'Lugar de devolución',
    timerented: 'tiempo alquilado',
    timereturned: 'Hora de devolución',
    powerbankid: 'Power Bank ID',
    getreceipt: 'OBTENER UN RECIBO',
    buypowerbank: 'Comprar el powrer bank',
    usetime: 'Tiempo de uso',
    estimatedamount: 'Monto estimado',
    feerule: 'Regla de tarifa',
    payment: 'Pago',
    orderNo: ' No. de orden',
    trafficDetails: 'Ver detalles del tráfico de paquetes',
    Renewals: 'Renovaciones',
    FeeSchedule: 'Lista de tarifas',
    HowToUse: 'Como usar',
    LeaseMerchant: 'Comerciante de alquiler',
    startTime: 'Hora de inicio',
    ReturningMerchants: 'Comerciantes que regresan',
    EndTime: 'Hora de finalización',
    Deposit: 'Depósito',
    fee1: 'Depósito de equipo PEN....',
    fee2: 'Devuelto dentro de los 7 días posteriores a la fecha de vencimiento del alquiler, menos PEN.../día/unidad.',
    fee3: 'Si el equipo se devuelve dentro de los 7 días posteriores a la finalización del período de alquiler, se deducirán PEN ... por unidad..',
    fee4: 'El depósito se descontará automáticamente si el equipo no se devuelve transcurridos más de 15 días desde la finalización de los días de alquiler..',
    rentagain: 'Alquilar de nuevo',
    onrental: 'En alquiler',
    revoked: 'Revocado',
    returned: 'Returned',
    issue: 'Asunto',
    overtimereturn: 'Se acabó el tiempo'
  },
  pickUp: {
    orderNo: 'No. de orden',
    startingTime: 'Hora de inicio',
    endTime: 'Hora de finalización',
    packageStatus: 'Estado de alquiler',
    confirmationOfPickup: 'confirmación de recogida'
  },
  aboutus: {
    title: 'Sobre nosotros',
    desc1: 'Somos FULL-POWER la marca de tecnología e innovación, representantes en el Perú de uno de los fabricantes de estaciones de carga de baterías portátiles para celulares más importantes de mundo. El servicio de alquiler de baterías portátiles ha tenido un crecimiento exponencial los últimos años tanto en Europa, Asia y América del norte.',
    desc2: 'Estar conectado a través del celular es una necesidad en la actualidad y nosotros les ofrecemos a nuestros usuarios la oportunidad de usar una batería portátil para seguir con sus actividades en cualquier lugar sin necesidad de estar atado a un enchufe. Nuestra misión es hacer que tu vida continúe normalmente con tu celular cargado al 100% Libertad, simplicidad y confiabilidad.',
    formtitle: '¿Tiene alguna pregunta? ¡Contáctenos!',
    submit: 'Registrarse',
    name: 'Nombre',
    email: 'Correo electrónico',
    tel: 'Teléfono',
    nation: 'País',
    message: 'Mensaje'
  },
  contactus: {
    title: 'Contacta con nosotros',
    desc1: 'Chargenow es una marca de solución para compartir baterías externas de la empresa de tecnología Shenzhen Bajie Charging. Chargenow opera un servicio de intercambio de baterías externas, con franquiciados ubicados en todo el mundo.',
    desc2: 'Si está interesado en unirse a nuestro negocio de franquicias, comuníquese con nuestra sede en China.',
    contactustitle: 'China Headquarters',
    contactussubtitle: '7f, Building 3, Jinxing High-Tech Park, No. 25 Jian\'an Road, Fuyong Town, Bao\'an District, Shenzhen Guangdong, China.',
    contactusinfotitle: 'Para los usuarios finales que tengan problemas al alquilar una energía compartida, comuníquese con su franquiciado local. Aquí están sus respectivos datos de contacto y direcciones:',
    method1: 'GRANSOL BRASIL',
    methoddesc1: 'Av. Ana Costa, 61 EV 506 Santos/SP CEP 11060-001',
    method2: 'Kazakhstan Region',
    methoddesc2: 'Aktau City'
  },
  rentcost: {
    rentcosttitle: '¿Cuánto cuesta el alquiler?',
    price1: '{currency} {jifei} por {jifeiDanwei} {priceUnit}',
    price2: '{currency} {fengding} por día',
    tip1: "Si no devuelve el power bank dentro de{overtime} , you se le cobrará{overtimeAmount} {currency} y no es necesario devolver el power bank.",
    tip2: "El alquiler de un día es de 24 horas desde el momento en que se contrata el power bank.",
    tip3: "Nosotros cobraremos{yajin} {currency} como depósito de seguridad que será devuelto una vez devuelta la batería a la estación.",
    tip4: "Algunos lugares pueden ofrecer algunos horarios gratuitos o descuentos. ¡Disfrutar!",
    Days: 'Días',
    Hours: 'Horas',
    Minutes: 'Minutos',
    free: '{mianfei} minutos gratis',
    willcharged: 'Se le cobrará{currency} {money}'
  }
}
