<template>
  <!-- <page :title="i18n.title"> -->
  <div class="home"></div>
  <!-- </page> -->
</template>

<script>
import { mapState } from "vuex"
import { QR_CODE } from "../../apis/cabinet"
import { TOURIST_LOGIN, USER_INFO } from "../../apis/user"
export default {
  components: {},
  data() {
    return {
      isQrcodeLink: false
    }
  },
  computed: {
    ...mapState(["user", "token", "browser", "qrcode", "mificode"]),
    i18n() {
      return this.$t("map")
    },
    i18nHome() {
      return this.$t("home")
    },
    i18nMsg() {
      return this.$t("msg")
    },
    code () {
      return this.$route.query.qr || this.$route.query.qrcode || this.$route.query.zhcode
    }
  },
  watch: {},
  methods: {
    onScan(urlcode) {
      this.$loading(true)
      this.$post(
        `${QR_CODE}?url=${encodeURIComponent(urlcode)}`,
        {},
        (resp) => {
          this.$loading(false)
          if (resp.zujieNum && resp.zujieNum > 0 && !resp.haveMifiBattery) {
            this.$confirm({
              title: this.i18nHome.msg_fail_title,
              content: `${this.i18nHome.msg_rentcount}`.format(resp),
              confirmText: this.i18nMsg.vieworder,
              cancelText: this.i18nMsg.cancel
            })
              .then(() => {
                this.$router.replace({ name: "pastOrder" })
              })
              .catch(() => {
                this.$router.replace({ name: "homeMap" })
              })
          } else if (resp.shopid) {
            // 机柜码
            this.$store.commit("cabinet", {
              qrcode: resp.cabinetID,
              ...resp
            })
            this.$store.commit("mificode", resp.cabinetID)
            this.$store.commit("mifiPackage", "")
            this.$router.replace({ name: "selectPakage" })
          } else if (resp.data) {
            // mifi设备码
            this.$store.commit("mificode", urlcode)
            this.$router.replace({ name: "packageDetail" })
          } else {
            this.$store.commit("cabinet", {
              qrcode: resp.cabinetID,
              ...resp
            })
            this.$store.commit("mificode", resp.cabinetID)
            this.$router.replace({
              name: "selectPakage",
              query: {
                cabinetshow: "true"
              }
            })
            // if (!resp.haveMifiBattery && !this.token) {
            //   this.loginTourist(resp.cabinetID)
            // } else {
            //   this.$router.replace({
            //     name: "selectPakage",
            //     query: {
            //       cabinetshow: "true"
            //     }
            //   })
            //   this.$store.commit("homePathName", "homeMap")
            // }
          }
        },
        (error) => {
          this.$loading(false)
          this.$toast(error)
          this.$router.replace({ name: "homeMap" })
          this.$store.commit("homePathName", "homeMap")
        }
      )
    },
    loginTourist() {
      this.$post(
        `${TOURIST_LOGIN}?code=${this.code}`,
        {},
        (resp) => {
          if (resp.token) {
            this.$store.commit("token", resp.token)
            this.$store.commit("userType", "tourist_login")
            this.loadUser()
            this.$store.commit("homePathName", "selectPakage")
          } else {
            this.$store.commit("homePathName", "homeMap")
            this.onScan(window.location.href)
            // this.onScan('https://saas.dtoolsz.com/#/?qrcode=1728372188')
          }
        },
        (error) => {
          console.log(error)
        }
      )
    },
    loadUser() {
      this.$loading(true)
      this.$get(
        USER_INFO,
        {},
        (res) => {
          this.$loading(false)
          if (res.user) {
            this.$store.commit("user", res.user)
            this.onScan(window.location.href)
            // this.onScan('https://saas.dtoolsz.com/#/?qrcode=1728372188')
            // this.$router.replace({
            //   name: "selectPakage",
            //   query: {
            //     cabinetshow: "true"
            //   }
            // })
            // this.$store.commit("homePathName", "selectPakage")
          } else {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: this.i18n.msg_fail_user
            })
              .then(() => {
                this.loadUser()
              })
              .catch(() => {
                this.$store.commit("token", null)
              })
          }
        },
        (err) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: err,
            showCancel: false
          }).then(() => {
            this.$store.commit("token", null)
            this.$router.replace({ name: "homeMap" })
          })
        }
      )
    }
  },
  created() {
    if (this.browser === "wechat") {
      console.log(
        "window.location.href",
        window.location.href,
        window.location.href.indexOf("qrcode=") !== -1
      )
      if (this.qrcode) {
        window.location.replace(
          `https://chargenow.gzz8j.com/#/?qrcode=${this.qrcode}`
        )
      } else if (window.location.href.indexOf("qrcode=") !== -1) {
        let qrArr = window.location.href.split("qrcode=")
        let qr = qrArr[qrArr.length - 1]
        window.location.replace(`https://chargenow.gzz8j.com/#/?qrcode=${qr}`)
      } else {
        window.location.replace(`https://chargenow.gzz8j.com/#/`)
      }
      return
    }
    if (
      this.$route.query.qr ||
      this.$route.query.qrcode ||
      this.$route.query.zhcode
    ) {
      if (!this.token) {
        this.loginTourist()
      } else {
        this.onScan(window.location.href)
      }
    } else {
      this.$router.replace({ name: "homeMap" })
    }
  },
  mounted() {}
}
</script>

<style lang="less" scoped>
</style>
