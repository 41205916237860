<template>
  <page :title="i18n.title">
    <div class="balance">
      <div class="balance-title">
        {{ i18n.balance }}
      </div>
      <div class="balance-num">{{ tradingCurrency() }}{{ userBalance() }}</div>
    </div>
    <div class="wallet-out-title">{{ i18n.选择充值金额 }}</div>
    <div class="flex-c price-box">
      <div
        v-for="(item, i) in prices"
        :key="i"
        @click="onItem(item)"
        class="price-item flex-c"
        :class="{ active: `${item}` === `${price}` }"
      >
        {{ tradingCurrency() }} {{ item }}
      </div>
    </div>
    <div class="wallet-out-title">{{ i18n.输入充值金额 }}</div>
    <div style="padding: 20px">
      <input class="text-area" v-model="price" readonly />
    </div>
    <div class="btn">
      <van-button class="bottom-pakage-btn" round @click="onSubmit">{{
        i18n.确认充值
      }}</van-button>
    </div>
  </page>
</template>

<script>
import { WECHAT_MP_RECHARGE } from "../../apis/payment.js"
import { mapState } from "vuex"
import wxMixin from "../../mixins/wx.js"
export default {
  computed: {
    ...mapState(["user", "browser", "wallet"]),
    i18n() {
      return this.$t("wallet_in")
    },
    i18nMsg() {
      return this.$t("msg")
    }
  },
  mixins: [wxMixin],
  data() {
    return {
      price: 10,
      prices: [10, 30, 50, 100, 300, 500],
      query: null,
      pbalance: 0,
      amount: 10
    }
  },
  methods: {
    handleAmount(price) {},
    onItem(price) {
      this.price = price
    },
    onSubmit(event) {
      const vm = this
      if (this.browser === "wechat") {
        this.$loading(true)
        console.log("event", event)
        this.$post(
          WECHAT_MP_RECHARGE,
          {
            amount: this.price,
            formId: event.detail.formId || ""
          },
          (resp) => {
            this.$loading(false)
            console.log("wechat recharge", resp)
            const data = resp.data
            wx.chooseWXPay({
              timestamp: data.timeStamp,
              nonceStr: data.nonceStr,
              package: data.package,
              signType: data.signType,
              paySign: data.paySign,
              success: function (res) {
                vm.$confirm({
                  title: vm.i18nMsg.title,
                  content: vm.i18nMsg.success_payment,
                  showCancel: false,
                  confirmText: vm.i18nMsg.confirm
                })
                vm.$store.commit("user", {
                  ...vm.user,
                  pbalance: vm.user.pbalance + vm.price
                })
              },
              fail: function (res) {
                console.log("wxpay fail", res)
                vm.$confirm({
                  title: vm.i18nMsg.title,
                  content: JSON.stringify(res),
                  showCancel: false,
                  confirmText: vm.i18nMsg.confirm
                })
                vm.$toast(res.errMsg)
              }
            })
          },
          (error) => {
            console.log("wechat pre pay error", error)
            vm.$loading(false)
            vm.$toast(error)
          }
        )
      } else {
        this.$router.push(`/confirm?price=${this.price}&payType=CZ`)
      }
    },
    userBalance() {
      return this.wallet
        ? this.wallet.pBalance
        : (this.user && this.user.pbalance) || 0
    },
    tradingCurrency() {
      return this.wallet
        ? this.wallet.pCurrencySymbol
        : this.user && this.user.pcurrencyName
    }
  },
  created() {
    this.pbalance = this.$route.query.pbalance
    this.query = this.$route.query
    if (this.query && this.query.price > 0) {
      this.price = this.query.price
    }
    if (this.browser === "wechat") {
      this.loadWXScript(["chooseWXPay"])
    }
  },
  destroyed() {
    // this.$store.commit('cabinet', null)
  }
}
</script>

<style lang="less">
.text-area {
  width: 100%;
  height: 80px;
  background: rgb(255, 255, 255);
  border-radius: 18px;
  opacity: 1;
  border: 2px solid rgb(209, 209, 209);
  font-size: 60px;
  line-height: 1;
  text-align: center;
}
.btn {
  margin: 40px;

  .bottom-pakage-btn {
    background: linear-gradient(132deg, #1a2229 0%, #00ab46 100%);
    box-shadow: 0px 6px 10px 1px rgba(0, 171, 70, 0.2);
    border-radius: 60px 60px 60px 60px;
    padding: 23px 73px;
    color: #fff;
    width: 100%;
    font-size: 36px;
    font-family: Work Sans-Bold, Work Sans;
    font-weight: bold;
  }
}
.price-box {
  padding: 0 10px;
  flex-wrap: wrap;
  .active {
    background: linear-gradient(131deg, #1a2229 0%, #00ab46 100%);
    box-shadow: 0px 6px 10px 1px rgba(0, 0, 0, 0.05);
    color: #fff;
  }
}
.price-item {
  width: 2.8rem;
  background: #ffffff;
  box-shadow: 0px 6px 10px 1px rgba(0, 0, 0, 0.05);
  border-radius: 18px 18px 18px 18px;
  opacity: 1;
  margin: 10px;
  padding: 45px 0;
  font-size: 30px;
  font-family: Cabin-Regular, Cabin;
  color: #00ab46;
}
.wallet-out-title {
  font-size: 32px;
  font-family: Montserrat-Bold, Montserrat;
  font-weight: bold;
  color: #1a2229;
  padding: 20px 40px;
}
.balance {
  background: url("../../assets/imgs/img_dollar_2@2x.png") no-repeat center;
  background-size: cover;
  width: 96%;
  height: 3.1rem;
  margin: 0 auto;
  color: #ffffff;
  font-family: Work Sans-Medium, Work Sans;

  &-title {
    font-size: 32px;
    padding: 20px 30px;
  }

  &-num {
    font-size: 60px;
    font-weight: bold;
    text-align: center;
    padding: 10px 0 35px 0;
  }
}
</style>
