export const STRIPE_PRE_PAY = 'cdb/payment/stripe/prepay' // 原先的预授权租借
export const STRIPE_RECHARGE = 'cdb/payment/stripe/recharge' // 原先的订单付款
export const STRIPE_PREPAY_SPTOKEN = 'cdb/payment/stripe/multicurrency/preAuth/stripToken' // 目前使用的预授权租借
export const STRIPE_PAYORDER_SPTOKEN = 'cdb/payment/stripe/multicurrency/payOrder/stripToken' // 目前使用的订单付款
export const STRIPE_PAYORDER = 'cdb/payment/stripe/multicurrency/payOrder' // 信用卡订单付款
export const STRIPE_RECHARGE_WALLET = 'cdb/payment/stripe/multicurrency/rechargeWallet' // 创建钱包余额充值订单
export const STRIPE_PAY_OF_CONFIRM = 'cdb/payment/stripe/prepayOfConfirm'
export const STRIPE_RECHARGE_OF_CONFIRM = 'cdb/payment/stripe/rechargeOfConfirm'
export const STRIPE_PAY_CONFIRM = 'cdb/payment/stripe/payConfirm'
// export const STRIPE_PREAUTH_OF_CONFIRM1 = 'cdb/payment/stripe/preauthOfConfirm'
export const STRIPE_PREAUTH_OF_CONFIRM = 'cdb/payment/stripe/multicurrency/preauthOfIntent/payment' // 信用卡预授权租借
export const STRIPE_CLIENTSECRET = 'cdb/payment/stripe/multicurrency/clientSecret' // 获取客户秘钥
export const STRIPE_PREAUTH_AUTO_CONFIRM1 = 'cdb/payment/stripe/authConfirm'
export const STRIPE_PREAUTH_AUTO_CONFIRM = 'cdb/payment/stripe/multicurrency/confirm' // 信用卡确认订单
export const STRIPE_PAYCONFIRM = 'cdb/payment/stripe/multicurrency/payConfirm/payment' // 确认订单-旧
export const STRIPE_SAVE_CARD = 'cdb/payment/stripe/multicurrency/saveCardOfIntent' // stripe保存卡
export const STRIPE_SAVE_CARD_CONFIRM = 'cdb/payment/stripe/multicurrency/saveCardConfirm' // stripe保存卡确认
export const REMOVE_CARD = 'cdb/user/card/remove' // 删除卡
export const STRIPE_CARD_PAY = 'cdb/payment/stripe/multicurrency/preauthByCard/payment' // 已保存卡支付
export const SAVED_CARD_TO_RENT = 'cdb/cabinet/savedCardToRent'

export const PAYPAL_PRE_PAY = 'cdb/payment/paypal/prepay'
export const PAYPAL_RECHARGE = 'cdb/payment/paypal/recharge'
export const PAYPAL_EXC_PAY = 'cdb/payment/paypal/excpay'

// export const WECHAT_MP_PRE_PAY = 'cdb/payment/wechat_mp/prepay'
export const WECHAT_MP_PRE_PAY = 'cdb/payment/wechat_mpMultiCurrency/recharge'
export const ALIPAY_PRE_PAY = 'cdb/payment/alipay/prepay'
export const WECHAT_PAYCONFIRM = 'cdb/payment/wechat_mpMultiCurrency/payment/confirm' // 确认订单

export const WECHAT_MP_RECHARGE = 'cdb/payment/wechat_mp/recharge'

export const WECHAT_MP_WXF = 'cdb/payment/wechat_mp/wxfv3'
export const WECHAT_MP_WXF_CONFIRM = 'cdb/payment/wechat_mp/wxfv3_confirm'
export const PAYMODE = 'cdb/cabinet/check/payment' // 获取支付方式列表
// 肯尼亚支付
export const MPESA_DEPOSIT_RENT = 'cdb/cabinet/useDepositToRent' // 押金租借
export const MPESA_RECHARGE = 'cdb/payment/m-pesa/multiCurrency/recharge' // 钱包充值
export const MPESA_PAYORDER = 'cdb/payment/m-pesa/multiCurrency/payOrder' // 订单付款
export const MPESA_VIPORDER = 'cdb/payment/m-pesa/multiCurrency/vipOrder' // 购买vip
export const MPESA_PAYCONFIRM = 'cdb/payment/m-pesa/multiCurrency/payment/confirm' // 确认订单
// mifi相关
export const MIFI_RENT = 'chargenow/mifi/rent' // 押金下单
export const MIFI_PREAUTHPAY = 'chargenow/mifi/preauthOfIntent/payment' // 预授权获取支付意图
export const MIFI_PREAUTHCONFIRM = 'chargenow/mifi/confirm' // 预授权成功接口
export const MIFI_WAKEUPPAY = 'chargenow/mifi/wakeUp/payment' // 未支付订单继续支付

// 阿根廷mercadopago
export const MERCADO_SAVECARDCONFIRM = 'cdb/payment/mercadoPago/multiCurrency/saveCardConfirm' // 保存卡
export const MERCADO_PREAUTH = 'cdb/payment/mercadoPago/multiCurrency/preAuthOrder' // 预授权租借
export const MERCADO_RECHARGE = 'cdb/payment/mercadoPago/multiCurrency/recharge' // 钱包充值
export const MERCADO_PAYORDER = 'cdb/payment/mercadoPago/multiCurrency/payOrder' // 订单付款
export const MERCADO_VIPORDER = 'cdb/payment/mercadoPago/multiCurrency/vipOrder' // 购买vip
export const MERCADO_PAYCONFIRM = 'cdb/payment/mercadoPago/multiCurrency/payment/confirm' // 确认订单
export const MERCADO_PAYMENTINFO = 'cdb/payment/mercadoPago/multiCurrency/paymentInfo'

// 喀麦隆-Flutterwave
export const FLUTTERWAVE_PREAUTH = "cdb/payment/flutterwave/preAuthOrder" // 预授权租借
export const FLUTTERWAVE_RECHARGE = "cdb/payment/flutterwave/recharge" // 钱包充值
export const FLUTTERWAVE_VIPORDER = "cdb/payment/flutterwave/payOrder" // 订单付款
export const FLUTTERWAVE_PAYCONFIRM = "cdb/payment/flutterwave/payment/confirm" // 确认订单
export const FLUTTERWAVE_VERIFYOPT = "cdb/payment/flutterwave/verifyOtp" // 进行Otp短信验证

// sumupOnline
export const SUMUP_RECHARGE = 'cdb/payment/sumupOnlinePayment/recharge' // 押金充值
export const SUMUP_PAYORDER = 'cdb/payment/sumupOnlinePayment/payOrder' // 订单付款
export const SUMUP_PAYCONFIRM = 'cdb/payment/sumupOnlinePayment/payment/confirm' // 确认订单
export const SUMUP_SAVECARDOFINTENT = 'cdb/payment/sumupOnlinePayment/saveCardOfIntent' // 确认订单
export const SUMUP_SAVEPAYMENTMETHORD = 'cdb/payment/sumupOnlinePayment/savePaymentMethord' // 确认订单

// 尼日利亚Espees支付
export const ESPEES_RECHARGE = 'cdb/payment/espees/multicurrency/recharge' // 钱包充值
export const ESPEES_PAYORDER = 'cdb/payment/espees/multicurrency/payOrder' // 订单付款
export const ESPEES_VIPORDER = 'cdb/payment/espees/multicurrency/vipOrder' // 购买vip
export const ESPEES_PAYCONFIRM = 'cdb/payment/espees/multicurrency/payment/confirm' // 确认订单

// 免登录买宝
export const INVOICE_BUYORDER = 'cdb/order/buyOrder'
