// 葡萄牙语
export default {
  home: {
    minute: 'minuto',
    price_info: 'Gratuito nos primeiros {mianfei} minutos. Cobrança Máxima Diária de {currencyName}{fengding}. O depósito será deduzido automaticamente quando o aluguel atingir {currencyName}{yajin}',
    feature_1: 'Adaptado a todos os tipos de telefones celulares',
    feature_2: 'Pode ser retornado para qualquer outro site',
    feature_3: 'Especialmente projetado - carregamento sem fio (somente para módulos suportados)',
    tips_1: 'Seu depósito de {currencyName}{yajin} será reembolsado após você devolver o banco de energia.',
    tips_2: 'Depósito de recarga {currencyName}{yajin} pode ser alugado, usado pode ser reembolsado',
    tips_3: 'O saldo atual é {currencyName}{myyue}, que pode ser alugado diretamente',
    yajin_info: 'Cobrança Máxima Diária de {currencyName}{fengding}',
    submit: 'Recarregar',
    borrow: 'ALUGAR',
    msg_fail_title: 'Erro',
    msg_fail_content: 'Falha no aluguel, tente novamente',
    msg_rentcount: 'Atualmente você tem {zujieNum} pedidos que não estão fechados',
    goborrow: 'Vá carregar!!!',
    nearby: 'Ver pontos de venda próximos',
    preauth: 'Pré-autorização',
    shopTime: 'Horário de funcionamento',
    分钟: 'minuto',
    小时: 'hora',
    天: 'dia'
  },
  button: {
    添加新卡: 'Adicione um novo cartão',
    delete_card: "Delete Card",
    Delete: 'excluir'
  },
  selectpackage: {
    powerbank: 'POWERBANK',
    powerstation: 'Central eléctrica',
    wifibox: 'WiFi langelis',
    choosepackage: 'Escolha um pacote de tráfego',
    minute: 'minutos.',
    首次免费: 'Uso gratuito nos primeiros',
    最大每日收费: 'Cobrança Máxima Diária de ',
    devicedeposit: 'Depósito de equipamento',
    return_will_back: 'Devolver o depósito depois de devolver o equipamento',
    lengthofuse: 'Duração da utilização',
    amountcharged: 'Montante da cobrança',
    release_powerbank: 'Liberte o seu banco de potência',
    see_pricing: 'Ver preços',
    deposit: 'depósito',
    agreen: 'Ao continuar, concorda com a nossa ',
    privacy_policy: 'Política de Privacidade',
    and: ' e ',
    terms_and_conditions: 'Termos e Condições.',
    per: 'per'
  },
  wifimall: {
    Select: 'Seleccionar',
    subtitle: 'Pacotes recomendados',
    dayuse: 'Days of use',
    Verify: 'Verify',
    无限流量: 'Tráfego infinito',
    天: 'dia',
    days: 'dias',
    useDay: 'Dias de utilização',
    mypackages: 'Máquina de recolha de pacotes existente',
    没有更多了: 'Não mais',
    加载中: 'carregando',
    depositof: 'Inclui um depósito de ',
    usagetime: 'Tempo de utilização'
  },
  paymethod: {
    payamount: 'Total a Pagar',
    choosepaymethod: 'Escolher as Opções de Pagamento',
    addcard: 'Adicionar um Novo Cartão',
    confrimpay: 'Confirmar o pagamento',
    借记卡: 'cartão de débito',
    银行卡列表: 'Lista de cartões bancários'
  },
  login: {
    title: 'Entrar ou inscrever-se',
    code_title: 'Verificação por SMS',
    phone_number: 'Número de telefone',
    verification_code: 'Por favor, digite o código de verificação SMS,Se não houver código de verificação, tente outro método de login.',
    captcha_code: 'Código',
    send_code: 'Enviar código',
    login: 'Entrar',
    agree: 'Ao clicar em Login, você concorda com',
    readme: 'Acordo do usuário',
    msg_fail_login: 'Falha no login',
    msg_success_login: 'Sucesso no Login',
    msg_fail_user: 'Erro ao obter informações do usuário. Você quer tentar novamente?',
    sign_in_with: 'Entrar com',
    no_sms: 'Não recebi mensagem de texto',
    retry: 'Reenviar',
    applelogin: 'Iniciar sessão com a Apple',
    otherlogin: 'Outras opções',
    quicklogin: 'Login Rápido',
    mobilelogin: 'Ligação Móvel',
    emaillogin: 'Login de E- mail',
    facelogin: 'Iniciar sessão com o FaceBook'
  },

  confirm: {
    'Credit Card': 'Cartão de Pagamento',
    WeChat: 'WeChat',
    'Apple Pay': 'Apple Pay',
    'Google Pay': 'Google Pay',
    'SMS Payment': 'Pagamento por SMS',
    'Idael Pay': 'Idael Pay',
    title: 'Confirmação de Pagamento',
    savenow: 'Gravar Agora',
    confirmpay: 'Prosseguir com o Pagamento',
    pay_label: 'Valor',
    auth_label: 'Pré-autorização',
    price_tips: 'Seu saldo pode ser retirado se solicitado. Pode levar de 1 a 5 dias úteis para aparecer em sua conta de pagamento.',
    auth_tips: 'Um valor de pré-autorização de {0}{1} é obrigatório. O valor do aluguel real ocorrido será deduzido quando o powerbank for devolvido.',
    pay_type: 'Por favor, selecione o método de pagamento de sua preferência',
    submit: 'Confirmar pagamento',
    msg_loading: 'Carregando...',
    msg_fail_title: 'Aviso',
    msg_fail_payment: 'Falha ao iniciar o pagamento do depósito',
    msg_fail_order: 'Falha ao criar pedido de locação',
    msg_fail_support: 'O sistema não é suportado',
    msg_fail_confirm: 'Confirmar',
    msg_fail_retry: 'Tentar Novamente',
    msg_fail_cancel: 'Cancelar'
  },

  mine: {
    title: 'Perfil do usuário',
    wallet: 'Minha Carteira',
    balance: 'Meu Saldo',
    deposit: 'Depósito',
    wallet_in: 'Carregar',
    wallet_out: 'Retirar',
    wallet_log: 'Registro da Carteira',
    order: 'Pedido',
    coupon: 'Cupom',
    login_tips: 'Por favor, faça login',
    msg_fail_title: 'Erro',
    msg_fail_update: 'Falha na modificação, tente novamente',
    msg_loading: 'Carregando...',
    msg_fail_user: 'Erro ao obter informações do usuário. Você quer tentar novamente?',
    help: 'ajuda',
    setting: 'Configuração',
    logout: 'Sair',
    buy: 'Comprar equipamento',
    aboutus: 'Sobre nós',
    contactus: 'entre em contato conosco'
  },

  msg: {
    loading: 'Carregando...',
    title: 'Dica',
    success: 'Operação bem-sucedida',
    sendsuccess: 'Enviada com sucesso',
    error: 'Erro desconhecido, tente novamente mais tarde.',
    deletecard: 'Tem a certeza de apagar este cartão?',
    confirm: 'Confirmar',
    retry: 'Repetir',
    cancel: 'Cancelar',
    title_fail: 'Aviso',
    vieworder: 'Visualizar pedido',
    success_payment: 'Pagamento concluído',
    success_buy: 'Compra concluída',
    fail_payment: 'Falha no pagamento',
    keyword: 'palavra-chave',
    nodata: 'Sem Dados',
    copysuccess: 'Copiado com sucesso',
    notopen: 'Ainda não aberto',
    devicenotactivated: 'Dispositivo não activado',
    pickup_fail: 'Não foi possível recuperar o dispositivo. Tente novamente mais tarde',
    msg_none_name: 'Indique o seu primeiro nome',
    msg_none_email: 'Introduza o seu endereço de e-mail',
    msg_none_mobile: 'Introduza o seu número de telefone',
    msg_none_nation: 'Por favor, indique o seu país',
    msg_none_msg: 'Insira outras informações que sejam convenientes para identificá-lo',
    msg_fail_choosepackage: 'Selecione uma máquina de recolha de pacotes',
    geolocationfailed: 'Serviço de Geolocalização falhou.'
  },
  result: {
    title: 'Confirmação de resultado',
    price_plan: 'Padrão de cobrança',
    msg_success: 'Alugado com sucesso',
    msg_tips_loading: 'Bateria portátil irá aparecer, por favor aguarde pacientemente...',
    msg_result_loading: 'O resultado do pagamento está sendo confirmado...',
    msg_fail_confirm: 'Confirmar',
    btn_usage: 'Nota de uso',
    btn_return: 'Nota de devolução',
    deposit: 'Depósito',
    msg_fail_content: 'A solicitação falhou, por favor tente novamente',
    price_label: 'por hora',
    price_info: 'Grátis para os primeiros {mianfei} minutos，Uma cobrança diária máxima de {currency}{fengding}，O depósito será deduzido automaticamente quando o aluguel atingir {currency}{yajin}',
    tips_1: 'Por favor, colete o PowerBank <span class="number">Não.{number}</span> conforme a imagem mostrada',
    tips_2: 'Arrume os cabos e insira o PowerBank na parte inferior com a <span class="highlight">[seta para baixo]</span> ao retornar',
    msg_note_usage: 'O PowerBank está equipado com 3 tipos de cabos e troca sem fio, use conforme necessário.',
    msg_note_return: 'Por favor arrume estes cabos e insira o power bank na cabine com a [seta para baixo] ao retornar.',
    return_info: 'Ao retornar, ajuste bem os cabos e insira o lado com lascas de metal em um espaço vazio até atingir o fundo e estabilizar.'
  },

  result_msg: {
    loading: 'Carregando...',
    refresh: 'Atualizar'
  },
  order: {
    title: 'Pedido de Arrendamento',
    in_use: 'Em uso',
    ended: 'Terminou',
    msg_error: 'Solicitação falhou'
  },
  order_item: {
    order_number: 'Não.',
    venue_rented: 'Local alugado',
    time_rented: 'Tempo alugado',
    venue_returned: 'Local retornado',
    time_returned: 'Hora retornada',
    charge_standard: 'Carga padrão',
    charge_standard_info: '{pJifei} {pCurrency} por hora (primeiros {pMian} minutos grátis)',
    rent_occurred: 'Aluguel ocorrido',
    returned: 'Retornou',
    unreturned: 'Não devolvido',
    type_0: 'Não Completo',
    type_1: 'Alugar',
    type_2: 'Cancelado',
    type_3: 'Devolvido',
    type_4: 'Ocorreu uma falha',
    type_5: 'Horas extras',
    min: 'Minuto',
    payment: 'Pagamento'
  },

  setting: {
    title: 'Configuração',
    lang: 'Idioma'
  },
  help: {
    title: 'FAQ'
  },
  wallet_in: {
    title: 'Recarga de Saldo',
    price_label: 'Valor da recarga',
    price_placeholder: 'Por favor, insira o valor da recarga',
    balance: 'Saldo atual',
    price_auto: 'Escolha o valor do pagamento',
    price_tips: 'Seu saldo pode ser retirado se solicitado. Pode levar de 1 a 5 dias úteis para aparecer em sua conta de pagamento.',
    submit: 'Enviar',
    loading: 'Carregando...',
    paying: 'Ir para a página de pagamento...',
    msg_title: 'Dicas',
    msg_fail_order: 'Falha ao criar pedido de recarga',
    msg_fail_payment: 'Falha ao iniciar o pagamento da recarga',
    msg_fail_retry: 'Tentar Novamente',
    选择充值金额: 'Selecione a quantidade de recarga',
    输入充值金额: 'Digite a quantidade de recarga',
    确认充值: 'Confirme a recarga'
  },
  wallet_out: {
    title: 'Retirada',
    price_label: 'Valor da retirada',
    price_placeholder: 'Por favor, insira o valor da retirada',
    balance: 'Saldo atual',
    balance_all: 'Retirar tudo',
    deposit: 'Depósito',
    deposit_tips: 'Existe uma ordem de arrendamento. O depósito de {pcurrencyName}{pyajin} não é reembolsável',
    submit: 'Enviar',
    apply: 'Aplicar',
    confirm: 'Confirmar',
    loading: 'Carregando...',
    msg_fail_price: 'O valor da retirada está incorreto',
    msg_fail_balance: 'O valor do saque não pode ser maior que o saldo atual',
    msg_fail_submit: 'Por favor, preencha as informações completas',
    msg_title: 'Erro',
    msg_success_content: 'Reembolso aplicado com sucesso',
    confirmtitle: 'Dicas',
    confirmcontent: 'A carteira atual não suporta retirada de dinheiro. Anote suas informações de contato e reembolse manualmente',
    payee_name: 'Nome do beneficiário',
    payee_bankcard: 'Número do cartão bancário',
    payee_phone: 'Telefone',
    cancelText: 'cancelar',
    confirmText: 'Reaplicar',
    退款金额: 'Quantia de reembolso'

  },
  map: {
    title: 'Perto do equipamento',
    result_msg: 'Nenhum equipamento foi encontrado nas proximidades',
    msg_title: 'ERRO',
    no_support: 'desculpe! Seu navegador não suporta localização',
    my_position: 'minha localização',
    distance: 'distância:',
    shop_time: 'horas',
    free: 'disponível',
    return: 'retornável',
    shop_title: 'Detalhes do comerciante',
    address: 'endereço',
    go_here: 'Vá aqui'
  },
  scanOrder: {
    viewflowdetail: 'Ver os detalhes da utilização do tráfego',
    confirmpickup: 'Confirmação da recolha',
    QRpickup: 'Captação de código QR',
    取机中: 'Escolher',
    scanQR: 'Digitalize o código para pegar a máquina',
    qrCodePickup: 'Recolha do código QR',
    orderNo: 'Número da encomenda',
    startingTime: 'Hora de início',
    endTime: 'Hora de fim',
    packageStatus: 'Status do pacote',
    无限流量: 'Tráfego infinito',
    天: 'dia',
    days: 'dias',
    type_0: 'Não utilizado',
    type_1: 'Para ser ativado',
    type_2: 'ativado',
    type_3: 'Revogado',
    type_4: 'devolveu',
    type_9: 'sobre',
    type_33: 'não remunerado',
    type_88: 'Cancelado',
    inputserialNumber: 'Introduza por favor a série Number of the device',
    pickupsuccess: 'Recuperado com sucesso a máquina',
    continuepay: 'Continuar o pagamento'
  },
  borrowSuccess: {
    success: 'Aluguel Realizado com Sucesso.',
    renewalTraffic: 'Renove o seu plano',
    viewOrder: 'Histórico',
    HowUseDevice: 'Como usar o dispositivo',
    download_tips: 'Você pode deixar seu e-mail e nós lhe enviaremos as informações detalhadas deste pedido via e-mail',
    download_tips2: 'Para ver outros locais de retorno e suas funções, baixe o aplicativo.',
    download: 'transferir',
    loading_title: 'Desbloquear banco de potência',
    loading_tips: 'por favor aguarde pacientemente...',
    send: 'enviar'
  },
  packageOrder: {
    viewdetail: 'Ver Detalhes',
    buynow: 'Comprar Agora',
    isrent: 'O equipamento está alugado?',
    timelimited: 'Oferta limitada por tempo'
  },
  packageDetail: {
    WiFiName: 'Nome WiFi',
    WiFiPassword: 'Palavra-passe WiFi',
    batteryLife: 'Potência da Bateria',
    Signal: 'Sinal',
    orderNo: 'Número da encomenda',
    startingTime: 'Hora de início',
    endTime: 'Hora de fim',
    packageStatus: 'Status do pacote',
    usedTraffic: 'Tráfego usado',
    Renewals: 'Tráfego de compra',
    inuse: 'em uso'
  },
  orderDetail: {
    price1: '{pCurrencyName}{pJifei} por {pJifeiDanwei}{priceUnit}',
    price2: '{pCurrencyName}{pFengding} no máximo por 24 horas',
    price3: 'Taxa de {pCurrencyName}{overtimeAmount} por não devolução Se você tiver alguma dúvida, sinta-se à vontade para entrar em contato!',
    venuerented: 'Aluguer de lojas',
    venuereturned: 'Return to store',
    timerented: 'Tempo de aluguer',
    timereturned: 'Return time',
    powerbankid: 'ID do banco de potência',
    getreceipt: 'Obter recibos',
    buypowerbank: 'Comprar um banco de energia',
    usetime: 'Tempo de utilização',
    estimatedamount: 'Montante estimado',
    feerule: 'Regras relativas às taxas',
    payment: 'Pagamento',
    orderNo: 'Número da encomenda',
    trafficDetails: 'Ver detalhes do trânsito',
    Renewals: 'Renovação',
    FeeSchedule: 'Tabela de taxas',
    HowToUse: 'Modo de utilização',
    LeaseMerchant: 'Comerciantes de aluguer',
    startTime: 'Hora de início',
    ReturningMerchants: 'Devolver o comerciante',
    EndTime: 'Hora de fim',
    Deposit: 'arrendamento',
    fee1: 'Depósito de equipamento $500',
    fee2: 'Reembolsado dentro de 7 dias da data de vencimento do aluguel, menos US $ 10 / dia / unidade.',
    fee3: 'Se o equipamento for devolvido no prazo de 7 dias após a expiração do período de aluguer, serão deduzidos $200 por unidade.',
    fee4: 'Se o dispositivo não for devolvido mais de 15 dias após os dias de aluguer terem expirado, o depósito será deduzido automaticamente.',
    rentagain: 'Alugar novamente',
    onrental: 'Aluguer em curso',
    revoked: 'Rescindido',
    returned: 'Devolvido',
    issue: 'Tem dúvidas',
    overtimereturn: 'Horas extraordinárias'
  },
  pickUp: {
    orderNo: 'Número da encomenda',
    startingTime: 'Hora de início',
    endTime: 'Hora de fim',
    packageStatus: 'Status do pacote',
    confirmationOfPickup: 'Confirmar levantamento'
  },
  aboutus: {
    title: 'Sobre nós',
    desc1: 'Power Bank é uma marca de solução compartilhada de Power Bank sob Shenzhen Bajie Charging Technology Co., Ltd. Desde a sua criação em 2018, Shenzhen Bajie Charging Technology Co., Ltd. tem se concentrado na pesquisa e operação de dispositivos terminais inteligentes no campo da economia de compartilhamento. Através de inovação tecnológica contínua e expansão de aplicativos, desenvolvemos vários dispositivos terminais inteligentes, como bancos de energia compartilhados, armários de armazenamento inteligentes compartilhados, WiFi global compartilhado e armazenamento de energia portátil compartilhado para atender às necessidades de diferentes usuários e cenários. Lideramos o mercado com inovação e promovemos o modelo de negócios de economia compartilhada para várias partes do mundo, compreendendo profundamente e atendendo às necessidades dos usuários.',
    desc2: 'BaJie Charging desenvolveu um modelo de cooperação OEM maduro para nossos parceiros de negócios. Podemos fornecer nossos equipamentos e suporte técnico, e você pode operar sua própria marca de serviço compartilhado do banco de energia.',
    contactustitle: 'Interessado em se tornar um parceiro Chargenow? Contate-nos!',
    formtitle: 'Tem uma pergunta? Contacte-nos!',
    submit: 'ENVIAR',
    name: 'NOME',
    email: 'E-MAIL',
    tel: 'TEL',
    nation: 'PAÍS',
    message: 'MENSAGEM'
  },
  contactus: {
    title: 'entre em contato conosco',
    desc1: 'Chargenow é uma marca de solução de compartilhamento de banco de energia sob Shenzhen Bajie empresa de tecnologia de carregamento Chargenow opera um serviço de compartilhamento de banco de energia, com franqueados localizados em todo o mundo',
    desc2: 'Se você estiver interessado em ingressar no nosso negócio de franquia, entre em contato com nossa sede na China.',
    contactustitle: 'Sede da China',
    contactussubtitle: 'Endereço de Companhia: No. 25 Jian\'an Road, Fuyong Town, Bao\'an District, Shenzhen Guangdong, China.',
    contactusinfotitle: 'Para usuários finais que encontrem problemas ao alugar um poder compartilhado, entre em contato com seu franqueado local. Aqui estão suas respectivas informações de contato e endereços:',
    method1: 'GRANSOL BRASIL',
    methoddesc1: 'Av. Ana Costa, 61 EV 506 Santos/SP CEP 11060-001',
    method2: 'Região do Cazaquistão',
    methoddesc2: 'AktauCity in Aktau'
  },
  rentcost: {
    rentcosttitle: 'Quanto custa o aluguel?',
    price1: '{currency} {jifei} per {jifeiDanwei} {priceUnit}',
    price2: '{currency} {fengding} per day',
    tip1: "Se não devolver o power bank no prazo de {overtime} dias, serão cobrados {overtimeAmount} {currency} e não será necessário devolvê-lo.",
    tip2: "O aluguer de um dia é de 24 horas a partir do momento da contratação do power bank.",
    tip3: "We will charge {yajin} {currency} as a security deposit that will be returned once the battery is returned to the station.",
    tip4: "Some venues might offer some free hours or discounts. Enjoy!",
    Days: 'Dias',
    Hours: 'Horas',
    Minutes: 'Minutos',
    free: '{mianfei} minutes free',
    willcharged: 'You will be charged {currency} {money}'
  }
}
