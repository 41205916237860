<template>
  <div class="mask" @click="onClose()">
    <div class="mask-main" @click.stop>
      <div class="appleline"></div>
      <h2>{{ i18n.rentcosttitle }}</h2>
      <div v-if="type !== 2" class="feedetail">
        <!-- <p>{{ i18n.price1.format({ currency, jifei: priceFormat(jifei), jifeiDanwei, priceUnit: $t(`home.${priceUnitMap[cabinet.priceStrategy.priceUnit]}`) }) }}</p>
        <p>{{ i18n.price2.format({ currency, fengding: priceFormat(fengding) }) }}</p> -->
        <p>{{ $t('orderDetail.price1', { pCurrencyName: currency, pJifei: priceFormat(jifei), pJifeiDanwei: jifeiDanwei, priceUnit: $t(`home.${priceUnitMap[cabinet.priceStrategy.priceUnit]}`) }) }}</p>
        <p>{{ $t('orderDetail.price2', { pCurrencyName: currency, pFengding: priceFormat(fengding) }) }}</p>
        <p>{{ $t('orderDetail.price3', { pCurrencyName: currency, overtimeAmount: priceFormat(overtimeAmount) }) }}</p>
      </div>
      <div v-else>
        <table class="rent-timetable">
          <tr>
            <th>
              <span class="rent-timetable-th-title">{{ $t('selectpackage.lengthofuse') }}</span>
              <span class="rent-timetable-th-subtitle">({{ $t('selectpackage.minute') }})</span>
            </th>
            <th>
              <span class="rent-timetable-th-title">{{
                $t('selectpackage.amountcharged')
              }}</span>
              <span class="rent-timetable-th-subtitle"
                >({{ currencyName }})</span
              >
            </th>
          </tr>
          <tr
            v-for="item in priceStrategyDetail"
            :key="item.pId"
          >
            <td>{{ item.pStartMinute }}~{{ item.pEndMinute }}</td>
            <td class="text-success" style="font-weight: 600">
              {{ priceFormat(item.pTotalFee) }}
            </td>
          </tr>
        </table>
      </div>
      <div class="renttip-item">
        <img src="../../../assets/imgs/icon_warn.png" alt="" />
        <span>{{
          i18n.tip1.format({ overtimeAmount: priceFormat(overtimeAmount), currency, overtime: cabinet.priceStrategy.pOvertimeDay })
        }}</span>
      </div>
      <div class="renttip-item">
        <img src="../../../assets/imgs/icon_timing.png" alt="" />
        <span>{{ i18n.tip2 }}</span>
      </div>
      <div class="renttip-item">
        <img src="../../../assets/imgs/icon_countdown.png" alt="" />
        <span>{{ i18n.tip3.format({ yajin: priceFormat(yajin), currency }) }}</span>
      </div>
      <div class="times">
        <div class="times-item">
          <span class="num">{{ days }}</span>
          <span class="label">{{ i18n.Days }}</span>
        </div>
        <div class="times-item">
          <span class="num">{{ hours }}</span>
          <span class="label">{{ i18n.Hours }}</span>
        </div>
        <div class="times-item">
          <span class="num">{{ minutes }}</span>
          <span class="label">{{ i18n.Minutes }}</span>
        </div>
      </div>
      <div class="myslider">
        <VanSlider
          v-model="value"
          :min="0"
          :step="1"
          :max="maxMinutes"
          active-color="#00B578"
          inactive-color="#EBEBEB"
          bar-height="12px"
          @input="sliderInput"
        >
          <template #button>
            <div class="custom-button"></div>
          </template>
        </VanSlider>
      </div>
      <div class="totalmoney">
        {{ i18n.willcharged.format({ currency, money }) }}
      </div>
    </div>
  </div>
</template>

<script>
import { Slider } from "vant"
import { mapState } from "vuex"
export default {
  components: {
    VanSlider: Slider
  },
  props: {
    jifei: {
      type: Number,
      default: 1
    },
    jifeiDanwei: {
      type: Number,
      default: 30
    },
    fengding: {
      type: Number,
      default: 6
    },
    yajin: {
      type: Number,
      default: 25
    },
    currency: {
      type: String,
      default: "CHF"
    },
    currencyName: {
      type: String,
      default: "₣"
    },
    type: {
      type: Number,
      default: 1
    },
    overtimeAmount: {
      type: Number,
      default: 25
    },
    priceStrategyDetail: {
      type: Array,
      default: () => {
        return []
      }
    },
    priceUnit: {
      type: Number,
      default: 0
    }
  },
  watch: {
    value(val) {
      console.log(val)
      let base = Math.floor(val / 60)
      let days = Math.floor(base / 24)
      let hours = Math.floor(base % 24)
      let minutes = Math.floor(val % 60)
      this.days = days
      this.hours = hours
      this.minutes = minutes
      if (this.type === 2) {
        let money = this.overtimeAmount
        for (let i = 0; i < this.priceStrategyDetail.length; i++) {
          if (this.priceStrategyDetail[i].pStartMinute < val && this.priceStrategyDetail[i].pEndMinute >= val) {
            money = this.priceStrategyDetail[i].pTotalFee
          }
        }
        this.money = money.toFixed(2)
        return
      }
      // 单日价格
      const priceDay = (dw, fd) => {
        let dT
        if (this.priceUnit === 2) {
          dT = this.days + ((this.hours > 0 || this.minutes > 0) ? 1 : 0)
        } else if (this.priceUnit === 1) {
          dT = this.hours + (this.minutes > 0 ? 1 : 0)
        } else {
          dT = this.hours * 60 + this.minutes
        }
        let money = Math.ceil(dT / dw) * this.jifei
        if (money >= fd) money = fd
        return money
      }
      let money = priceDay(this.jifeiDanwei, this.fengding)
      const timers = (this.days * 24 + this.hours) * 60 + this.minutes
      // 日封顶
      if (timers >= this.days * 24 * 60) {
        money =
          this.fengding * this.days + priceDay(this.jifeiDanwei, this.fengding)
      }
      this.money = money.toFixed(2)
    }
  },
  computed: {
    ...mapState(["user", "token", "cabinet"]),
    i18n() {
      return this.$t("rentcost")
    },
    i18nMsg() {
      return this.$t("msg")
    },
    overtimeDay() {
      let strategy = this.cabinet.priceStrategy || {}
      return (strategy.pOvertimeDay || 2) * 24
    },
    maxMinutes() {
      return this.overtimeDay * 2 * 30
    },
    priceUnitMap () {
      return {
        0: '分钟',
        1: '小时',
        2: '天'
      }
    }
  },
  data() {
    return {
      value: 0,
      minutes: 0,
      hours: 0,
      days: 0,
      money: 0
    }
  },
  methods: {
    priceFormat (val) {
      if (val) {
        return val % 1 === 0 ? val : val.toFixed(2)
      }
      return val
    },
    sliderInput(value) {
      // console.log(value)
      this.value = value
    },
    onClose() {
      this.$emit("close", false)
    }
  },
  created() {},
  mounted() {}
}
</script>

<style lang="less" scoped>
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(53, 53, 53, 0.5);
  z-index: 100;
  .mask-main {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 95%;
    overflow-y: scroll;
    box-sizing: border-box;
    background: #fff;
    border-top-right-radius: 100px;
    border-top-left-radius: 100px;
    padding: 40px 40px 75px;
  }
  .appleline {
    background-color: #ebebeb;
    width: 30%;
    height: 8px;
    border-radius: 100px;
    margin: 0 auto;
  }
  h2 {
    text-align: center;
    font-size: 40px;
    font-family: "Montserrat-SemiBold";
    font-weight: bold;
    color: #171917;
    margin: 40px 0;
  }
  .feedetail {
    width: 60%;
    margin: 0 auto;
    border: dashed 6px #171917;
    border-radius: 20px;
    color: #00B578;
    font-size: 32px;
    font-family: "Montserrat-SemiBold";
    font-weight: bold;
    background: rgba(0,252,0,0.08);
    padding: 10px 20px;
    margin-bottom: 20px;
    p {
      margin: 10px 0;
      text-align: center;
    }
  }
  .renttip-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: dashed 1px #ebebeb;
    padding: 20px 0;
    font-family: "Montserrat-Medium";
    img {
      width: 48px;
      height: 48px;
      margin-right: 20px;
    }
    span {
      color: #666666;
      font-size: 24px;
    }
  }
  .renttip-item:nth-child(7) {
    border: none;
  }
  .times {
    margin: 40px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: "Montserrat-SemiBold";
    &-item {
      width: 32%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .num {
        color: #00B578;
        font-size: 44px;
        font-weight: bold;
      }
      .label {
        font-weight: bold;
        font-size: 28px;
        color: #171917;
        margin-top: 10px;
      }
    }
  }
  .myslider {
    width: 80%;
    margin: 0 auto;
    .custom-button {
      width: 32px;
      height: 32px;
      background-color: #00B578;
      border: solid 8px #000;
      border-radius: 100px;
    }
  }
  .totalmoney {
    text-align: center;
    font-size: 32px;
    font-family: "Montserrat-SemiBold";
    color: #00B578;
    font-weight: bold;
    margin: 60px 0 0;
  }
}
.rent-timetable {
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 20px;
  border: solid 1px #d1d1d1;
  margin: 10px auto;
  td {
    border-top: 1px solid #d1d1d1;
    text-align: center;
  }
  th,
  td {
    padding: 10px 15px;
    color: #666666;
    border-right: 1px solid #d1d1d1;
  }
  th:last-child,
  td:last-child {
    border-right-color: transparent;
  }
  &-th-title {
    font-weight: normal;
  }
  &-th-subtitle {
    font-weight: normal;
    display: block;
    font-size: 20px;
  }
  .text-success {
    color: #00ab46;
  }
}
</style>
