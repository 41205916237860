export default {
  home: {
    minute: '分钟',
    price_info: '首{mianfei}分钟内免费, 日封顶{currencyName}{fengding}, 计费满{currencyName}{yajin}自动扣取押金。',
    feature_1: '四种充电方式，适配所有手机',
    feature_2: '全国通借通还',
    feature_3: '特别为无线充电设计',
    tips_1: '退还充电宝后，您的{currencyName}{yajin}押金将退还给您。',
    yajin_info: '每日最高收费{currencyName}{fengding}',
    tips_2: '充值押金{currencyName}{yajin}即可租用，用完即可退',
    tips_3: '当前余额{currencyName}{myyue}，可以直接租借',
    submit: '立即充值',
    borrow: '立即租借',
    msg_fail_title: '提示',
    msg_fail_content: '租借失败，请重新尝试',
    msg_rentcount: '你当前有{zujieNum}个订单未结束',
    goborrow: '去租借!!!',
    nearby: '查看附近网点',
    preauth: '预授权金额',
    shopTime: '营业时间',
    分钟: '分钟',
    小时: '小时',
    天: '天'
  },
  button: {
    添加新卡: '添加新卡',
    delete_card: "删除卡",
    Delete: '删除'
  },
  selectpackage: {
    powerbank: '充电宝',
    powerstation: '储能电池',
    wifibox: 'wifi 宝',
    choosepackage: '选择流量包',
    minute: '分钟',
    首次免费: '首次免费',
    最大每日收费: '最大每日收费',
    devicedeposit: '设备押金',
    return_will_back: '归还设备后退还押金',
    lengthofuse: '使用时长',
    amountcharged: '累计收费金额',
    release_powerbank: '租借充电宝',
    see_pricing: '查看价格',
    deposit: '押金',
    agreen: ' 继续，即表示您同意我们的',
    privacy_policy: '隐私政策',
    and: ' 和 ',
    terms_and_conditions: '用户条款',
    per: '每'
  },
  wifimall: {
    Select: '选择',
    subtitle: '推荐的流量包',
    dayuse: '使用天数',
    Verify: '验证',
    无限流量: '无限流量',
    天: '天',
    days: '天',
    useDay: '使用天数',
    mypackages: '已有套餐取机',
    没有更多了: '没有更多了',
    加载中: '加载中',
    depositof: '包含押金 ',
    usagetime: '使用时间'
  },
  paymethod: {
    payamount: '支付金额',
    choosepaymethod: '选择支付方式',
    addcard: '添加卡',
    confrimpay: '确认支付',
    借记卡: '借记卡',
    银行卡列表: '银行卡列表'
  },
  login: {
    title: '登录或注册',
    code_title: '短信验证',
    phone_number: '手机号码',
    verification_code: '请输入短信验证码，如果没有验证码，请尝试其他登录方法',
    captcha_code: '验证码',
    send_code: '获取验证码',
    login: '登录',
    agree: '点击登录，表示已阅读并同意',
    readme: '使用条款',
    msg_fail_login: '登录失败',
    msg_success_login: '登录成功',
    msg_fail_user: '获取用户信息出错，是否重试？',
    sign_in_with: '第三方登录',
    no_sms: '没收到短信',
    retry: '重新发送',
    applelogin: '使用苹果登录',
    otherlogin: '其他登录方式',
    quicklogin: '快速登录',
    mobilelogin: '手机号',
    emaillogin: '邮箱',
    facelogin: '使用Facebook登录'
  },
  confirm: {
    'Credit Card': 'Credit Card',
    WeChat: 'WeChat',
    'Apple Pay': 'Apple Pay',
    'Google Pay': 'Google Pay',
    'SMS Payment': 'SMS Payment',
    'Idael Pay': 'Idael Pay',
    title: '确认付款',
    savenow: '立即保存',
    confirmpay: '立即付款',
    SMSConfirm: '请根据手机收到的短信提示付款后点击确认按钮继续。',
    pay_label: '需支付',
    auth_label: '预授权',
    price_tips: '充值金额申请提现后1~5天到账',
    // auth_tips: '预授权金额{currencyName}{yajin},归还后按实际租金扣取',
    auth_tips: '预授权金额{0}{1},归还后按实际租金扣取',
    pay_type: '请选择支付方式',
    submit: '确认支付',
    msg_loading: '加载中...',
    msg_fail_title: '提示',
    msg_fail_payment: '发起押金支付失败',
    msg_fail_order: '创建租借订单失败',
    msg_fail_support: '当前环境不支持',
    msg_fail_confirm: '确定',
    msg_fail_retry: '重试',
    msg_fail_cancel: '取消'
  },
  mine: {
    title: '个人中心',
    wallet: '我的钱包',
    balance: '我的余额',
    deposit: '押金',
    wallet_in: '充值',
    wallet_out: '提现',
    wallet_log: '充值提现',
    order: '租借订单',
    coupon: '优惠券',
    login_tips: '请先登录',
    msg_fail_title: '提示',
    msg_fail_update: '修改失败，请重新尝试',
    msg_loading: '加载中...',
    msg_fail_user: '获取用户信息出错，是否重试？',
    help: '帮助',
    setting: '设置',
    logout: '退出',
    buy: '购买设备',
    aboutus: '关于我们',
    contactus: '联系我们'
  },
  msg: {
    no_phone: '手机号必填',
    loading: '加载中...',
    title: '提示',
    success: '操作成功',
    sendsuccess: '发送成功',
    error: '未知异常，请联系管理员',
    deletecard: '确认删除该卡吗？',
    confirm: '确认',
    retry: '重试',
    cancel: '取消',
    title_fail: '错误',
    vieworder: '查看订单',
    success_payment: '充值成功',
    success_buy: '购买成功',
    fail_payment: '付款失败',
    keyword: '关键词',
    nodata: '未找到数据',
    copysuccess: '复制成功',
    notopen: '暂未开放',
    devicenotactivated: '设备未激活',
    pickup_fail: '取机失败，请稍后重试',
    msg_none_name: '请输入您的名字',
    msg_none_email: '请输入您的邮箱',
    msg_none_mobile: '请输入您的手机号',
    msg_none_nation: '请输入您的国家',
    msg_none_msg: '请输入其他方便辨识您身份的信息',
    msg_fail_choosepackage: '请选择一个套餐取机',
    geolocationfailed: '错误：地理定位服务失败。'
  },
  result: {
    title: '确认结果',
    price_plan: '计费规则',
    msg_success: '租借成功',
    msg_tips_loading: '正在弹出充电宝，请耐心等待...',
    msg_result_loading: '正在确认支付结果...',
    msg_fail_confirm: '确定',
    btn_usage: '使用说明',
    btn_return: '归还说明',
    deposit: '押金',
    msg_fail_content: '请求失败，请重新尝试',
    price_label: '/小时',
    price_info: '{mianfei}分钟内免费，日封顶{fengding}{ccurrency}，计费满{yajin}{ccurrency}自动扣取押金',
    tips_1: '请按图取走<span class="number">{number}号</span>位置充电宝',
    tips_2: '归还请收好按箭头<span class="highlight">【线朝下方】</span>插入机柜并锁',
    msg_note_usage: '充电宝配有三种充电线，请按需使用',
    msg_note_return: '请把充电线插回相应位置后，把充电宝插入机柜的卡槽中',
    return_info: '归还时，整理好充电线，将充电宝芯片端插入机柜空缺槽口至底部被固定即可。'
  },
  result_msg: {
    loading: '加载中...',
    refresh: '点击刷新'
  },
  order: {
    title: '租借订单',
    in_use: '正在使用',
    ended: '已结束',
    enter_order_email: "请输入邮箱",
    msg_error: '加载失败'
  },
  order_item: {
    order_number: '订单号',
    venue_rented: '租借地点',
    time_rented: '租借时间',
    venue_returned: '归还地点',
    time_returned: '归还时间',
    charge_standard: '收费标准',
    charge_standard_info: '每小时{pJifei}{pCurrency}(前{pMian}分钟免费)',
    rent_occurred: '租借费用',
    returned: '已归还',
    unreturned: '未归还',
    type_0: '未租借',
    type_1: '租借中',
    type_2: '已撤销',
    type_3: '已归还',
    type_4: '有疑问',
    type_5: '超时归还',
    min: '分',
    payment: '付款'
  },
  setting: {
    title: '设置',
    lang: '选择语言'
  },
  help: {
    title: '帮助'
  },
  wallet_in: {
    title: '余额充值',
    price_label: '充值金额',
    price_placeholder: '请输入充值金额',
    balance: '当前余额',
    price_auto: '自动充值',
    price_tips: '充值金额申请提现后1~5天到账',
    submit: '确定充值',
    loading: '加载中...',
    paying: '跳转到付款页...',
    msg_title: '提示',
    msg_fail_order: '创建充值订单失败',
    msg_fail_payment: '发起充值支付失败',
    msg_fail_retry: '重试',
    选择充值金额: '选择充值金额',
    输入充值金额: '输入充值金额',
    确认充值: '确认充值'
  },
  wallet_out: {
    title: '余额提现',
    price_label: '提现金额',
    price_placeholder: '请输入提现金额',
    balance: '当前余额',
    balance_all: '全部提取',
    deposit: '押金',
    deposit_tips: '目前有租借中的订单，押金{pcurrencyName}{pyajin}暂不可退',
    submit: '立即提现',
    apply: '申请提现',
    confirm: '确认',
    loading: '加载中...',
    msg_fail_price: '提现金额不正确',
    msg_fail_balance: '提现金额不能大于当前余额',
    msg_fail_submit: '请填写完整信息',
    msg_title: '错误',
    msg_success_content: '申请提现成功',
    confirmtitle: '提示',
    confirmcontent: '当前钱包不支持提现，请备注您的联系方式后由人工退款',
    payee_name: '姓名',
    payee_bankcard: '银行卡号',
    payee_phone: '电话',
    cancelText: '取消',
    confirmText: '再次申请',
    退款金额: '退款金额'
  },
  map: {
    title: '附近设备',
    result_msg: '附近未找到相关设备',
    msg_title: '错误',
    no_support: '抱歉！您的浏览器不支持定位',
    my_position: '我的位置',
    distance: '距你',
    shop_time: '营业时间',
    free: '可借',
    return: '可还',
    shop_title: '商家详情',
    address: '地址',
    go_here: '去这里'
  },
  scanOrder: {
    viewflowdetail: '查看流量使用详情',
    confirmpickup: '确认取机',
    QRpickup: '二维码取机',
    取机中: '取机中',
    scanQR: '扫码取机',
    qrCodePickup: '二维码取机',
    orderNo: '订单编号',
    startingTime: '开始时间',
    endTime: '结束时间',
    packageStatus: '订单状态',
    无限流量: '无限流量',
    天: '天',
    days: '天',
    type_0: '未使用',
    type_1: '待激活',
    type_2: '已激活',
    type_3: '已撤销',
    type_4: '已退款',
    type_9: '已结束',
    type_33: '未支付',
    type_88: '已取消',
    inputserialNumber: '请输入设备的sn',
    pickupsuccess: '取机成功',
    continuepay: '继续支付'
  },
  borrowSuccess: {
    success: 'Rental success',
    renewalTraffic: '续购套餐',
    viewOrder: '查看订单',
    HowUseDevice: '如何使用设备',
    download_tips: '您可以留下您的电子邮件，我们会将此订单的详细信息发送到您的电子邮件',
    download_tips2: '查看其他退货地点及其功能，请下载应用程序。',
    download: '下载',
    loading_title: '解锁充电宝',
    loading_tips: '请耐心等待...',
    send: '发送'
  },
  packageOrder: {
    viewdetail: '详情',
    buynow: '立即购买',
    isrent: 'Is the equipment rented?',
    timelimited: 'Time-limited offer'
  },
  packageDetail: {
    WiFiName: 'WiFi 名称',
    WiFiPassword: 'WiFi 密码',
    batteryLife: '电量',
    Signal: '信号',
    orderNo: '订单编号',
    startingTime: '开始时间',
    endTime: '结束时间',
    packageStatus: '订单状态',
    usedTraffic: '已用流量',
    Renewals: '购买流量',
    inuse: '使用中'
  },
  orderDetail: {
    price1: '每{pJifeiDanwei}{priceUnit} {pCurrencyName}{pJifei}',
    price2: '每24小时最高 {pCurrencyName}{pFengding}',
    price3: '不归还收费 {pCurrencyName}{overtimeAmount}',
    venuerented: '租借门店',
    venuereturned: '归还门店',
    timerented: '租借时间',
    timereturned: '归还时间',
    powerbankid: '充电宝ID',
    getreceipt: '获取收据',
    buypowerbank: '购买充电宝',
    usetime: '使用时间',
    estimatedamount: '预估金额',
    feerule: '收费规则',
    payment: '去支付',
    orderNo: '订单编号',
    trafficDetails: '查看流量详情',
    Renewals: '续购',
    FeeSchedule: '费用表',
    HowToUse: '如何使用',
    LeaseMerchant: '租赁商户',
    startTime: '开始时间',
    ReturningMerchants: '归还商家',
    EndTime: '结束时间',
    Deposit: '租金',
    fee1: '设备押金 $500',
    fee2: '在租赁到期日后 7 天内退还，减去 10 美元/天/单位。',
    fee3: '如果在租赁期满后7天内归还设备，将扣除$200/台。',
    fee4: '如果在租赁天数到期后超过15天仍未归还设备，押金将自动扣除。',
    rentagain: '再次租借',
    onrental: '租借中',
    revoked: '已撤销',
    returned: '已归还',
    issue: '有疑问',
    overtimereturn: '超时归还'
  },
  pickUp: {
    orderNo: '订单编号',
    startingTime: '开始时间',
    endTime: '结束时间',
    packageStatus: '订单状态',
    confirmationOfPickup: '确认取机'
  },
  aboutus: {
    title: '关于我们',
    desc1: '充电宝是深圳八借充电科技公司旗下的充电宝共享解决方案品牌。深圳市八借充电科技有限公司自2018年成立以来，一直专注于共享经济领域智能终端设备的研发和运营。通过不断的技术创新和应用拓展，我们开发了共享充电宝、共享智能储物柜、共享全球WiFi、共享便携式储能等多种智能终端设备，以满足不同用户和不同场景需求。我们以创新引领市场，通过深入理解和满足用户需求，将共享经济商业模式推广到世界各地。',
    desc2: '八借充电为我们的商业伙伴开发了一个成熟的OEM合作模式。我们可以为您提供我们的设备和技术支持，您可以运营自己的充电宝共享服务品牌。',
    contactustitle: '有兴趣成为Chargenow的合作伙伴吗？请联系我们——',
    formtitle: '有问题吗？联系我们！',
    submit: '提交',
    name: '名字',
    email: '邮箱',
    tel: '手机号',
    nation: '国家',
    message: '其他想说的'
  },
  contactus: {
    title: '联系我们',
    desc1: '充电宝是深圳八借充电科技有限公司旗下的充电宝共享解决方案品牌。Chargenow运营着一项充电宝共享服务，特许经营商遍布世界各地。',
    desc2: '如果您有兴趣加入我们的特许经营业务，请联系我们在中国的总部。',
    contactustitle: '中国总部',
    contactussubtitle: '中国广东省深圳市宝安区福永镇建安路25号金星工业园区3号楼7层。',
    contactusinfotitle: '对于在租用共享电源时遇到问题的最终用户，请联系您当地的特许经营商。以下是他们各自的联系信息和地址：',
    method1: '格兰索尔巴西',
    methoddesc1: 'Av. Ana Costa, 61 EV 506 Santos/SP CEP 11060-001',
    method2: '哈萨克斯坦地区',
    methoddesc2: '阿克套市'
  },
  rentcost: {
    rentcosttitle: '租金是多少？',
    price1: '{currency} {jifei} per {jifeiDanwei} {priceUnit}',
    price2: '{currency} {fengding} per day',
    tip1: "如果您未在 {overtime} 天内归还移动电源，我们将向您收取 {overtimeAmount} {currency} 的费用，并且您无需归还移动电源。",
    tip2: "1天租金从租赁开始算起24小时。",
    tip3: "我们将收取{yajin}{currency}作为押金，押金将在电池返回车站后退还。",
    tip4: "一些场馆可能会提供一些免费时间或折扣",
    Days: '天',
    Hours: '小时',
    Minutes: '分钟',
    free: '{mianfei} 分钟免费',
    willcharged: 'You will be charged {currency} {money}'
  }
}
