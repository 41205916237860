// 立陶宛语言
export default {
  home: {
    minute: 'minutę',
    price_info: 'Nemokamai pirmas {mianfei} min. Maksimalus dienos mokestis {currencyName}{fengding}. Užstatas bus automatiškai nuskaičiuotas, kai nuoma pasieks {currencyName}{yajin}',
    feature_1: 'Pritaikyta visų rūšių mobiliesiems telefonams',
    feature_2: 'Galima grąžinti į bet kurią kitą vietą',
    feature_3: 'Specialus dizainas – belaidis įkrovimas(tik palaikomiems moduliams)',
    tips_1: 'Jūsų {currencyName}{yajin} užstatas bus grąžintas Jums, kai grąžinsite nešiojamą įkroviklį.',
    tips_2: 'Papildymo mokestis {currencyName}{yajin} galima išnuomoti, sunaudotas gali būti grąžintas',
    tips_3: 'Dabartinis likutis yra {currencyName}{myyue}, kurį galima tiesiogiai išnuomoti',
    yajin_info: 'Maksimalus dienos mokestis {currencyName}{fengding}',
    submit: 'Įkrauti',
    borrow: 'Pasiskolinti',
    msg_fail_title: 'Klaida',
    msg_fail_content: 'Nuoma nepavyko, bandykite dar kartą',
    msg_rentcount: 'Šiuo metu turite {zujieNum} neuždarytų užsakymų',
    goborrow: 'Eikite įkrauti!!!',
    nearby: 'Peržiūrėti netoliese esančias prekybos vietas',
    preauth: 'Išankstinis autorizavimas',
    shopTime: 'Darbo valandos',
    分钟: 'minutė',
    小时: 'valanda',
    天: 'diena'
  },
  button: {
    添加新卡: 'Pridėkite naują kortelę',
    delete_card: "Delete Card",
    Delete: 'Ištrinti'
  },
  selectpackage: {
    powerbank: 'Power Bank',
    powerstation: 'Elektros stotis',
    wifibox: 'WiFi Box',
    choosepackage: 'Pasirinkite eismo paketą',
    minute: 'minutė',
    首次免费: 'Nemokamai už pirmąjį',
    最大每日收费: 'Maksimalus dienos mokestis',
    devicedeposit: 'Įrangos indėlis',
    return_will_back: 'Grąžinti depozitą grąžinus įrangą',
    lengthofuse: 'Naudojimo trukmė',
    amountcharged: 'Rinkimo suma',
    release_powerbank: 'Atlaisvinkite savo energijos banką',
    see_pricing: 'Žiūrėti kainas',
    deposit: 'depozitas',
    agreen: 'Tęsdami sutinkate su mūsų p',
    privacy_policy: 'rivatumo politika',
    and: ' ir ',
    terms_and_conditions: 'taisyklėmis bei nuostatomis.',
    per: 'per'
  },
  wifimall: {
    Select: 'Pasirinkti',
    subtitle: 'Rekomenduojamos pakuotės',
    dayuse: 'Days of use',
    Verify: 'Verify',
    无限流量: 'Infinitinis',
    天: 'diena',
    days: 'dienos',
    useDay: 'Naudojimo dienos',
    mypackages: 'Esama paketų surinkimo mašina',
    没有更多了: 'Ne daugiau',
    加载中: 'pakrauti',
    depositof: 'Įskaitant ',
    usagetime: 'Naudojimo laikas'
  },
  paymethod: {
    payamount: 'Mokėjimo suma',
    choosepaymethod: 'Pasirinkite mokėjimo galimybes',
    addcard: 'Pridėti naują kortelę',
    confrimpay: 'Patvirtinti mokėjimą',
    借记卡: 'Debeto kortelė',
    银行卡列表: 'Banko kortelių sąrašas'
  },
  login: {
    title: 'Įrašyti arba pasirašyti',
    code_title: 'Patvirtinimas SMS žinute',
    phone_number: 'Telefono numeris',
    verification_code: 'Įveskite SMS patvirtinimo kodą,Jei tikrinimo kodo nėra, pabandykite kitą loginio metodą',
    captcha_code: 'Kodas',
    send_code: 'Siųsti kodą',
    login: 'Prisijungti',
    agree: 'Spustelėdami Prisijungti, sutinkate su ',
    readme: `Vartotojo Sutartis`,
    msg_fail_login: 'Prisijungimas nepavyko',
    msg_success_login: 'Sėkmingas prisijungimas',
    msg_fail_user: 'Klaida gaunant vartotojo informaciją. Ar norite bandyti dar kartą?',
    sign_in_with: 'Prisijungti su',
    no_sms: 'Negavau teksto žinutės',
    retry: 'Siųsti iš naujo',
    applelogin: 'Įrašyti su Apple',
    otherlogin: 'Kitos galimybės',
    quicklogin: 'Greitas prisijungimas',
    mobilelogin: 'Mobilus prisijungimas',
    emaillogin: 'El. pašto įjungimas',
    facelogin: 'Įrašyti su FaceBook'
  },
  confirm: {
    'Credit Card': 'Kredito kortelė',
    WeChat: 'WeChat',
    'Apple Pay': 'Apple Pay',
    'Google Pay': 'Google Pay',
    'SMS Payment': 'Mokėjimas SMS',
    'Idael Pay': 'Nemokamas mokestis',
    title: 'Mokėjimo patvirtinimas',
    savenow: 'Įrašyti dabar',
    confirmpay: 'Nedelstinis mokėjimas',
    SMSConfirm: 'Prašome mokėti pagal telefonu gautą teksto pranešimą ir paspauskite patvirtinimo mygtuką, kad tęstųsi.',
    pay_label: 'Suma',
    auth_label: 'Išankstinė autorizacija',
    price_tips: 'Jei pageidaujate, Jūsų likutis gali būti atšauktas. Per 1-5 darbo dienas bus grąžintas į mokėtojo sąskaitą.',
    auth_tips: 'Išansktinės autorizacijos suma {0}{1} yra privaloma. Faktinio nuomos mokesčio suma bus atskaičiuota grąžinus nešiojamą įkroviklį.',
    pay_type: 'Pasirinkite pageidaujamą mokėjimo būdą',
    submit: 'Patvirtinkite mokėjimą',
    msg_loading: 'Loading...',
    msg_fail_title: 'Įspėjimas',
    msg_fail_payment: 'Nepavyko inicijuoti įmokos mokėjimo',
    msg_fail_order: 'Nepavyko sukurti nuomos užsakymo',
    msg_fail_support: 'Sistema nepalaikoma',
    msg_fail_confirm: 'Patvirtinti',
    msg_fail_retry: 'Bandykite dar kartą',
    msg_fail_cancel: 'Atšaukti'
  },
  mine: {
    title: 'Vartotojo profilis',
    wallet: 'Mano piniginė',
    balance: 'Mano likutis',
    deposit: 'Įmoka',
    wallet_in: 'Krauti',
    wallet_out: 'Atšaukti',
    wallet_log: 'Piniginės ataskaita',
    order: 'Užsakymas',
    coupon: 'Kuponas',
    login_tips: 'Prašome prisijungti',
    msg_fail_title: 'Klaida',
    msg_fail_update: 'Modifikacija nepavyko, bandykite dar kartą',
    msg_loading: 'Loading...',
    msg_fail_user: 'Klaida gaunant vartotojo informaciją. Ar norite pabandyti dar kartą?',
    help: 'Pagalba',
    setting: 'Nustatymas',
    logout: 'Atsijungti',
    buy: 'Pirkti įrangą',
    aboutus: 'Apie mus',
    contactus: 'kreipkitės į mus'
  },
  msg: {
    no_phone: 'Telefono numeris reikalingas',
    loading: 'Loading...',
    title: 'Hint',
    success: 'Sėkminga operacija',
    sendsuccess: 'Siųstas sėkmingai',
    error: 'Nežinoma klaida, bandykite dar kartą vėliau.',
    deletecard: 'Ar tikrai ištrinsite šią kortelę?',
    confirm: 'Patvirtinti',
    retry: 'Bandykite dar kartą',
    cancel: 'Atšaukti',
    title_fail: 'Įspėjimas',
    vieworder: 'Peržiūrėti užsakymą',
    success_payment: 'Mokėjimas atliktas',
    success_buy: 'Pirkimas atliktas',
    fail_payment: 'Atsiskaitymas nepavyko',
    keyword: 'raktažodis',
    nodata: 'Duomenų nėra',
    copysuccess: 'Sėkmingai kopijuota',
    notopen: 'Dar neištirta',
    devicenotactivated: 'Įrenginys neaktyvuotas',
    pickup_fail: 'Nepavyko susigrąžinti įtaiso. Pabandykite vėl vėliau',
    msg_none_name: 'Įrašykite savo vardą',
    msg_none_email: 'Įveskite pašto adresą',
    msg_none_mobile: 'Įveskite savo telefono numerį',
    msg_none_nation: 'Prašome patekti į savo šalį',
    msg_none_msg: 'Įrašykite kitą informaciją, kuri jums patogu identifikuoti',
    msg_fail_choosepackage: 'Prašome pasirinkti paketų surinkimo mašiną',
    geolocationfailed: 'Klaida: Geolokacijos tarnyba nepavyko.'
  },
  result: {
    title: 'Rezultato patvirtinimas',
    price_plan: 'Įkrovimo standartas',
    msg_success: 'Sėkmingai išnuomota',
    msg_tips_loading: 'Nešiojamas įkrovėjas netrukus pasirodys, prašome palaukti...',
    msg_result_loading: 'Mokėjimo rezultatas tvirtinamas...',
    msg_fail_confirm: 'Patvirtinti',
    btn_usage: 'Usage note',
    btn_return: 'Grąžinimo raštelis',
    deposit: 'Įmoka',
    msg_fail_content: 'Užklausa nepavyko, bandykite dar kartą',
    price_label: 'per valandą',
    price_info: 'Pirmas {mianfei} min. nemokamaiļ¼Maksimalus dienos mokestis {ccurrency}{fengding}ļ¼Užstatas bus automatiškai atskaitytas, kai nuoma pasieks {ccurrency}{yajin}',
    tips_1: 'Prašome paimti nešiojamą įkroviklį <span class="number">No.{number}</span> kaip parodytas paveikslėlyje',
    tips_2: 'Sutvarkykite laidus ir norėdami grąžinti įkiškite nešiojamą įkroviklį į apačią  <span class="highlight">[rodyklė žemyn]</span> ',
    msg_note_usage: 'Nešiojamas įkroviklis turi 3 tipų laidus ir belaidį įkrovimą, naudokite pagal poreikį.',
    msg_note_return: 'Prašome sutvarkyti šiuos laidus ir norėdami grąžinti įkiškite nešiojamą įkroviklį į apačią [rodyklė žemyn].',
    return_info: 'Grąžindami, gerai sutvarkykite laidus ir įkiškite šoną su metalinėmis plokštelėmis į tuščią lizdą kol jis pasieks dugną ir stabilizuosis.'
  },
  result_msg: {
    loading: 'Kraunasi...',
    refresh: 'Atnaujinti'
  },
  order: {
    title: 'Nuomos užsakymas',
    in_use: 'Vartojamas',
    ended: 'Baigta',
    msg_error: 'Užklausa nepavyko'
  },
  order_item: {
    order_number: 'Nr.',
    venue_rented: 'Nuomos vieta',
    time_rented: 'Nuomos laikas',
    venue_returned: 'Grąžinimo vieta',
    time_returned: 'Grąžinimo laikas',
    charge_standard: 'Įkrovimo standartas',
    charge_standard_info: '{pJifei} {pCurrency} už valandą(first {pMian} mins for free)',
    rent_occurred: 'Nuoma įvyko',
    returned: 'Sugrąžinta',
    unreturned: 'Nesugrąžinta',
    type_0: 'Nebaigta',
    type_1: 'Išnuomota',
    type_2: 'Atšaukta',
    type_3: 'Grąžinta',
    type_4: 'Įvyko gedimas',
    type_5: 'Viršvalandžiai',
    min: 'Min.',
    payment: 'Mokėjimas'
  },
  setting: {
    title: 'Nustatymas',
    lang: 'Kalba'
  },
  help: {
    title: 'D.U.K.'
  },
  wallet_in: {
    title: 'Mokėjimo papildymas',
    price_label: 'Papildymo suma',
    price_placeholder: 'Įveskite papildymo sumą',
    balance: 'Einamasis likutis',
    price_auto: 'Pasirinkite mokėjimo sumą',
    price_tips: 'Jei pageidaujate, Jūsų likutis gali būti grąžintas. Per 1-5 darbo dienas bus grąžintas į mokėtojo sąskaitą.',
    submit: 'Pateikti',
    loading: 'Kraunasi...',
    paying: 'Pereiti į mokėjimo puslapį...',
    msg_title: 'Arbatpinigiai',
    msg_fail_order: 'Nepavyko sukurti papildymo užsakymo',
    msg_fail_payment: 'Nepavyko inicijuoti papildymo mokėjimo',
    msg_fail_retry: 'Bandykite dar kartą',
    选择充值金额: 'Pasirinkite įkrovos sumą',
    输入充值金额: 'Įveskite papildomą sumą',
    确认充值: 'Patvirtinkite įkrovą'
  },
  wallet_out: {
    title: 'Atšaukti',
    price_label: 'Išimti sumą',
    price_placeholder: 'Įveskite norimą išimti sumą',
    balance: 'Einamasis likutis',
    balance_all: 'Išimti visą',
    deposit: 'Įmoka',
    deposit_tips: 'Yra nuomos užsakymas. {pcurrencyName}{pyajin} užstatas negrąžinamas',
    submit: 'Pateikti',
    apply: 'Taikyti',
    confirm: 'Patvirtinti',
    loading: 'Kraunasi...',
    msg_fail_price: 'Neteisinga išėmimo suma',
    msg_fail_balance: 'Grąžinimo suma negali būti didesnė už esamą likutį',
    msg_fail_submit: 'Prašome užpildyti visą informaciją',
    msg_title: 'Klaida',
    msg_success_content: 'Grąžinimas sėkmingas',
    confirmtitle: 'Patarimai',
    confirmcontent: 'Dabartinė piniginė nepalaiko grynųjų pinigų išėmimo. Užpildykite savo asmeninę informaciją and refund manually',
    payee_name: 'Gavėjo vardas',
    payee_bankcard: 'Banko kortelės numeris',
    payee_phone: 'Telefono numeris',
    cancelText: 'atšaukti',
    confirmText: 'Kreiptis pakartotinai',
    退款金额: 'Grąžinti sumą'

  },
  map: {
    title: 'Šalia įrangos',
    result_msg: 'Netoliese įrangos nerasta',
    msg_title: 'klaida',
    no_support: 'atsiprašome! Jūsų naršyklė nepalaiko vietos nustatymo',
    my_position: 'mano vieta',
    distance: 'atstumas:',
    shop_time: 'valandos',
    free: 'paskolinama',
    return: 'grąžinama',
    shop_title: 'Prekybininko informacija',
    address: 'adresas',
    go_here: 'Eikite čia'
  },
  scanOrder: {
    viewflowdetail: 'Rodyti eismo naudojimo detales',
    confirmpickup: 'Pasiėmimo patvirtinimas',
    QRpickup: 'QR kodo pikapas',
    取机中: 'Paimti',
    scanQR: 'Nuskaitykite kodą, kad pasiimtumėte mašiną',
    qrCodePickup: 'QR kodo paėmimas',
    orderNo: 'Užsakymo numeris',
    startingTime: 'Pradžios laikas',
    endTime: 'Pabaigos laikas',
    packageStatus: 'Pakuotės būsena',
    无限流量: 'Infinitinis',
    天: 'diena',
    days: 'dienos',
    type_0: 'Nenaudotas',
    type_1: 'Kad būtų suaktyvinta',
    type_2: 'aktyvuota',
    type_3: 'Atšauktas',
    type_4: 'grąžinta',
    type_9: 'baigta',
    type_33: 'neapmokėtas',
    type_88: 'Atšauktas',
    inputserialNumber: 'Įveskite įrenginio serijinį numerį',
    pickupsuccess: 'Sėkmingai surinkta mašina',
    continuepay: 'Tęsti mokėjimą'
  },
  borrowSuccess: {
    success: 'pavykti',
    renewalTraffic: 'Atnaujinkite savo planą',
    viewOrder: 'Peržiūrėti užsakymus',
    HowUseDevice: 'Kaip naudotis įrenginiu',
    download_tips: 'Jūs galite palikti savo el. paštą ir mes išsiųsime jums išsamią informaciją apie šią užsakymą elektroniniu paštu',
    download_tips2: 'Norėdami pamatyti kitas grąžinimo vietas ir jų funkcijas, atsisiųskite programą.',
    download: 'parsisiųsti',
    loading_title: 'Maitinimo banko atrakinimas',
    loading_tips: 'prašau kantriai palaukti...',
    send: 'išsiųsti'
  },
  packageOrder: {
    viewdetail: 'Rodyti detales',
    buynow: 'Nupirkti dabar',
    isrent: 'Ar įranga nuomojama?',
    timelimited: 'Pasiūlymas ribotas laikas'
  },
  packageDetail: {
    WiFiName: 'WiFi vardas',
    WiFiPassword: '"WiFi" slaptažodis',
    batteryLife: 'Baterijos galia',
    Signal: 'Signalas',
    orderNo: 'Užsakymo numeris',
    startingTime: 'Pradžios laikas',
    endTime: 'Pabaigos laikas',
    packageStatus: 'Pakuotės būsena',
    usedTraffic: 'Naudotas srautas',
    Renewals: 'Pirkimo eismas',
    inuse: 'Naudojama'
  },
  orderDetail: {
    price1: '{pCurrencyName}{pJifei} už {pJifeiDanwei}{priceUnit}',
    price2: 'Maksimalus {pCurrencyName}{pFengding} per 24 valandas',
    price3: '{pCurrencyName}{overtimeAmount} mokestis už negrąžinimą Jei turite klausimų, drąsiai kreipkitės!',
    venuerented: 'Pardavimo parduotuvės',
    venuereturned: 'Grįžti į sandėliavimą',
    timerented: 'nuomos laikas',
    timereturned: 'Grįžimo laikas',
    powerbankid: 'Power bank ID',
    getreceipt: 'Gauti kvitus',
    buypowerbank: 'Energijos banko pirkimas',
    usetime: 'Naudojimo laikas',
    estimatedamount: 'Apskaičiuota suma',
    feerule: 'Mokesčių taisyklės',
    payment: 'Mokėjimas',
    orderNo: 'Užsakymo numeris',
    trafficDetails: 'Peržiūrėti išsamią eismo informaciją',
    Renewals: 'Atnaujinimo',
    FeeSchedule: 'Mokesčių grafikas',
    HowToUse: 'Kaip naudotis',
    LeaseMerchant: 'Nuomos prekeiviai',
    startTime: 'Pradžios laikas',
    ReturningMerchants: 'Grąžinkite prekybininką',
    EndTime: 'Pabaigos laikas',
    Deposit: 'skylė',
    fee1: 'Įrangos indėlis $500',
    fee2: 'Grąžinama per 7 dienas nuo nuomos galiojimo pabaigos, atėmus 10 USD už dieną už vienetą.',
    fee3: 'Jei įranga bus grąžinta per 7 dienas nuo nuomos laikotarpio pabaigos, bus išskaičiuota 200 USD už vienetą.',
    fee4: 'Jei įrenginys nebus grąžintas praėjus daugiau nei 15 dienų nuo nuomos dienų pabaigos, užstatas bus išskaičiuotas automatiškai.',
    rentagain: 'Išnuoma iš naujo',
    onrental: 'Naudojama nuoma',
    revoked: 'panaikintas',
    returned: 'Grąžintas',
    issue: 'Turite abejonių',
    overtimereturn: 'Grįžti po termino'
  },
  pickUp: {
    orderNo: 'Užsakymo numeris',
    startingTime: 'Pradžios laikas',
    endTime: 'Pabaigos laikas',
    packageStatus: 'Pakuotės būsena',
    confirmationOfPickup: 'Patvirtinkite paėmimą'
  },
  aboutus: {
    title: 'Apie mus',
    desc1: 'Power Bank yra bendras Power Bank sprendimas pagal Shenzhen Bajie Charging Technology Co., Ltd. Nuo savo įsteigimo 2018 m. Shenzhen Bajie Charging Technology Co., Ltd. daugiausia dėmesio skiria pažangiųjų terminal ų prietaisų moksliniams tyrimams ir eksploatavimui bendros ekonomikos srityje. Nuolatinėmis technologijų inovacijomis ir taikomųjų programų plėtra sukūrėme įvairius pažangiuosius galinius įrenginius, pavyzdžiui, bendrus elektros energijos bankus, bendrus pažangiuosius saugyklos, bendrus pasaulinius WiFi įrenginius ir bendrus nešiojamosios energijos saugojimus, kad patenkintume skirtingų naudotojų poreikius ir scenarijus. Mes vedame rinką inovacijomis ir skatiname pasidalijamosios ekonomikos verslo model į įvairiose pasaulio dalyse išsamiai suprasdami ir tenkindami vartotojų poreikius.',
    desc2: 'BaJie Charging sukūrė brandų OEM bendradarbiavimo model į mūsų verslo partneriams. Mes galime jums suteikti savo įrangą ir techninę paramą, ir jūs galite valdyti savo elektros energijos banko bendrus paslaugų ženklus.',
    contactustitle: 'Nori tapti Chargenow partneriu? Prašau susisiekti su mumis——',
    formtitle: 'Turi klausimą? Susisiekkite su mumis!',
    submit: 'Siųsti',
    name: 'Pavadinimas',
    email: 'E-mail',
    tel: 'Tel',
    nation: 'Šalis',
    message: 'Pranešimas'
  },
  contactus: {
    title: 'kreipkitės į mus',
    desc1: 'Chargenow – tai Shenzhen Bajie Charging technologijų bendrovės Elektros banko dalijimosi sprendimais prekės ženklas. Chargenow teikia elektros energijos banko dalijimosi paslaugą, o franšizijos gavėjai yra visame pasaulyje.',
    desc2: 'Jei norite prisijungti prie mūsų franšizės verslo, kreipkitės į mūsų būstinę Kinijoje.',
    contactustitle: 'Kinijos būstinė',
    contactussubtitle: '7f, Building 3, Jinxing High-Tech Park, No. 25 Jian\'an Road, Fuyong Town, Bao\'an District, Shenzhen Guangdong, Kinija.',
    contactusinfotitle: 'Galutiniams vartotojams, kurie susiduria su problemomis nuomojant bendrą elektros energiją, kreipkitės į savo vietinį franchisee. Štai jų atitinkama kontaktinė informacija ir adresai:',
    method1: 'GRANSOL BRASIL',
    methoddesc1: 'Av. Ana Costa, 61 EV 506 Santos/SP CEP 11060-001',
    method2: 'Kazachstano regionas',
    methoddesc2: 'Aktau miestas'
  },
  rentcost: {
    rentcosttitle: 'Kiek kainuoja nuoma?',
    price1: '{jifei} {currency} per {jifeiDanwei} {priceUnit}',
    price2: '{currency} {fengding} per dieną',
    tip1: 'Jei negrąžinsite maitinimo bloko per {overtime} , būsite apmokestinti {overtimeAmount} {currency} ir jums nereikės grąžinti maitinimo banko.',
    tip2: 'Trukmė suapvalinama iki artimiausio pusvalandžio (30 arba 60 minučių).',
    tip3: 'Vienos dienos nuoma yra 24 valandos nuo maitinimo banko nuomos momento.',
    tip4: 'Kai kurios vietos gali pasiūlyti nemokamų valandų arba nuolaidų. Mėgaukitės!',
    Days: 'Dienos',
    Hours: 'Valandos',
    Minutes: 'Minutės',
    free: '{mianfei} minučių nemokamai',
    willcharged: 'Jums reikės sumokėti {currency} {money}'
  }
}
