import zhCN from './zh-CN.js'
import enUS from './en-US.js'
import ltLT from './lt-LT.js'
import ptPT from './pt-PT.js'
import esES from './es-ES.js'

export default {
  locale: process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_LOCALE,
  messages: {
    'zh-CN': zhCN,
    'en-US': enUS,
    'lt-LT': ltLT,
    'es-ES': esES,
    'pt-PT': ptPT
  }
}
