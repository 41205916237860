// export const SHOP_LIST = 'cdb/shop/listnear'
export const SHOP_LIST = 'cdb/shop/listnear/trial'
export const SHOP_LIST_INFO = 'cdb/shop/listnear/dtl'
export const QR_CODE = 'cdb/cabinet/checkisok'
export const ZH_CODE = 'cdb/cabinet/zhorder'

export const ORDER_TEMP1 = 'cdb/cabinet/createorder'
export const ORDER_TEMP = 'cdb/cabinet/useBalanceToRent'
export const ORDER_CZ = 'cdb/cabinet/order'
export const ORDER_CHECK = 'cdb/cabinet/ordercheck'

// 检查mifi宝是否可租借
export const MIFI_CHECK = 'chargenow/mifi/checkisok'
export const MIFI_DEVICEDEPOSIT = 'chargenow/mifi/cabinetDisposit'
export const MIFI_PICKUP = 'chargenow/mifi/pickCabinet'
export const MIFI_FLOWUSE = 'chargenow/mifi/flowUsageList'
export const MIFI_CREATEORDER = 'cdb/payment/bajiemifi/createOrderByScan' // mifi弹宝
export const MIFI_BINDSN = 'chargenow/mifi/bindSN' // 设备续费SN直接绑定已经买的仅套餐

// 查询流量使用详情
export const MIFI_PACKAGEUSE = 'chargenow/mifi/flowUsageList'
