<template>
  <div id="app">
    <router-view />
    <popup-loading v-if="loading"></popup-loading>
  </div>
</template>

<script>
import { PopupLoading } from "./components/popup"
import { mapState } from "vuex"
import { vConsoleWidget } from "@/utils/index"

export default {
  components: {
    PopupLoading
  },
  computed: {
    ...mapState(["loading"])
  },
  created() {
    const { vc } = this.$route.query
    if (vc === "true") vConsoleWidget()
  }
}
</script>

<style lang="less">
@import "./assets/css/public.less";
@import "./assets/css/font.css";
body {
  background: #f9f9f9;
  padding: 0;
  margin: 0;
  height: 100vh;
  color: #333333;
  font-family: Montserrat-Regular, Work Sans-Regular, Work Sans;
}
.p-2 {
  padding: 20px;
}
#app {
  font-size: 28px;
}
.btn {
  height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background: linear-gradient(132deg, #1a2229 0%, #00ab46 100%);
  box-shadow: 0px 6px 10px 1px rgba(49, 200, 137, 0.2);
  border-radius: 60px 60px 60px 60px;
  font-size: 32px;
}
.text-success {
  color: #00ab46;
}
.fixed-bottom {
  position: fixed;
  bottom: 15%;
  right: 40px;
  width: 120px;
  height: 120px;
  background: #fff;
  border-radius: 100%;
  box-shadow: 0px 0px 10px #aaa;
  img {
    width: 100%;
  }
}
</style>
