<template>
  <page title="Wifi Mall">
    <template v-slot:headleft>
      <!-- <van-button round icon="orders-o"></van-button> -->
      <div class="vcard order-List flex-c" @click="goPackageOrder">
        <van-icon name="orders-o" class="flex-c" size='0.65rem' />
      </div>
    </template>
    <div class="page-top-box">
      <div class="search-box">
        <Search v-model="searchText" @handleValue="reload"></Search>
      </div>
      <div class="area padding-h">
        <div class="areaItem" v-for='(key, v) in areaList1' @click='goCuntry(v)' :key='key'>
          <div class="areabox">
            <img src="../../assets/imgs/AreaIcon/qq.png" class="areaImg" />
          </div>
          <div class="areaT" :class="country == v ? 'h-country' : ''">{{ key }}</div>
        </div>
      </div>
    </div>
    <div v-if="type === 'QR' || type === 'XG' || type === 'BXG' || type === 'DDXG'" class="choose-card flex-s" @click="goRouterRePlace(`/scanPickUpOrder?type=${type}`)" style="margin: 20px 20px 0;">
      <span>{{ i18n.mypackages }}</span>
      <van-icon :name="require('../../assets/imgs/icon_more.png')" size="0.53rem" />
    </div>

    <div class="flex-s orderhead-box">
      <div class="equipment-text">{{ i18n.subtitle }}</div>
    </div>
    <div class="scroll-box" ref="scroll" v-infinite-scroll="handleScroll" infinite-scroll-throttle-delay="500"
    infinite-scroll-disabled="busy" infinite-scroll-distance="100">
      <div class="vcard order-item" style="padding: 0;" v-for="item in packagePageList" :key="item.skuId">
        <OrderItem :src="item.flagUrl" :width="3.5" :country="item.country" :currencyName="item.currencyName"
          :text="item.text" :price="item.price" :useDay="Number(item.Day)">
          <van-button @click="onSelect(item.skuId, item.country)" class="select-btn" color='#FF1919' round size='mini'>
            {{ i18n.Select }}</van-button>
        </OrderItem>
      </div>
      <div style="margin: 20px 0;">
        <van-divider v-if="loadingShow">{{i18n.加载中}}</van-divider>
        <van-divider v-if="noMore">{{i18n.没有更多了}}</van-divider>
      </div>
    </div>
    <div class="fixed-bottom">
      <a :href="whatsappUrl"><img src="../../assets/imgs/contactus/icon_whatsapp.png" alt=""></a>
    </div>
    <van-dialog v-model="show" title="Package Type" :show-cancel-button="false" :show-confirm-button="false">
      <van-icon name="cross" class="cross-btn" @click="show = false" />
      <div style="max-height: 40vh;overflow: auto;">
        <div v-for="item in packageDtlList" class="pakage-box flex-s" :class="packageClass(item)"
        @click="selectPack(item)" :key="item.skuId">
        <div class="pakage-title">{{ item.text }}</div>
        <div class="pakage-price">{{ item.currencyName }}{{ item.price }}</div>
        <div class="package-selected flex-c" v-if="packageClass(item) === 'h-pakage'">
          <van-icon name="success" color="#fff" />
        </div>
      </div>
    </div>
      <div class="pakage-num flex-s pakage-title">
        <span>{{ i18n.usagetime }}:</span>
        <span class="checktime" @click="pickerShow = true">{{ myStartime }}</span>
      </div>
      <div class="pakage-num flex-s pakage-title">
        <span>{{ i18n.dayuse }}:</span>
        <span>
          <van-stepper v-model="dayNum" theme="round" button-size="22" disable-input
            :disabled="packageObj.isPackage ? true : false" />
        </span>
      </div>
      <div class="flex-s dialog-bottom">
        <span class="bottom-price">{{ packageObj && packageObj.currencyName || '$' }}{{ price }}</span>
        <van-button @click="onVerify" class="bottom-pakage-btn" round>{{ i18n.Verify }}</van-button>
      </div>
    </van-dialog>
    <van-action-sheet v-model="pickerShow" :title="i18n.usagetime">
      <van-datetime-picker
        v-model="pickerDate"
        type="datetime"
        :min-date="minDate"
        @confirm="datePickerConfirm"
        @cancel="pickerShow = false"
      />
    </van-action-sheet>
  </page>

</template>

<script>
import OrderItem from '../../components/orderItem/orderItem.vue'
import Search from 'components/search/search.vue'
import { areaList } from '@/utils/areaList.js'
import { Debounce } from '@/utils/public.js'
import dayjs from 'dayjs'

export default {
  components: {
    Search,
    OrderItem
  },
  computed: {
    i18n () {
      return this.$t('wifimall')
    },
    price () {
      if (this.packageObj && this.packageObj.packageName && this.packageObj.packageName.indexOf('Days') !== -1) {
        return this.packageObj.price
      } else {
        return (Number(this.packageObj.price) * Number(this.dayNum)).toFixed(2)
      }
    },
    whatsappUrl () {
      return process.env.VUE_APP_WHATSAPP_SCHEME
    }
  },
  watch: {
    packageObj (val) {
      this.dayNum = val.Day
    },
    searchText (val) {
      this.reload()
    }
  },
  data () {
    return {
      searchText: '',
      areaList1: [],
      show: false,
      pickerShow: false,
      pickerDate: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      dayNum: 0,
      packagePageList: [],
      packageDtlList: [],
      country: '',
      packageObj: {
        skuId: 0
      },
      minDate: new Date(dayjs().format('YYYY-MM-DD HH:mm:ss')),
      myStartime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      current: 1,
      total: 0,
      type: 'QR',
      loadingShow: false,
      noMore: false
    }
  },
  created () {
    if (this.$route.query.type) {
      this.type = this.$route.query.type
    }
    this.getAreaList()
    this.reload()
  },
  methods: {
    goPackageOrder () {
      this.$router.push('/scanOrder')
    },
    goRouterRePlace (path) {
      this.$router.replace(path)
    },
    datePickerConfirm (value) {
      this.myStartime = dayjs(value).format('YYYY-MM-DD HH:mm:ss')
      this.pickerShow = false
    },
    onVerify () {
      let obj = {
        ...this.packageObj,
        price: this.price,
        selectDays: this.dayNum,
        myStartime: this.myStartime
      }
      this.$store.commit('mifiPackage', obj)
      if (this.type === 'QR') {
        this.$router.replace('/selectPakage?deviceType=mifi')
      } else if (this.type === 'XG' || this.type === 'BXG' || this.type === 'DDXG') {
        this.$router.push('/packageOrder?type=XG')
      } else {
        this.$router.push('/packageOrder?type=GM')
      }
    },
    packageClass (item) {
      return item.skuId === this.packageObj.skuId ? 'h-pakage' : ''
    },
    selectPack (item) {
      this.packageObj = item
    },
    reload () {
      this.current = 1
      this.packagePageList = []
      this.noMore = false
      this.loadingShow = false

      this.onSearch()
    },
    onSearch: Debounce(function () {
      this.$post('chargenow/mifi/query', {
        'requestMethod': 'post',
        'url': '/mifi-open-api/wifiBase/packagePage',
        'data': {
          'size': 6,
          'current': this.current,
          'searchKeywords': this.searchText || this.country
        }
      }, (res) => {
        let data = JSON.parse(res.data).records
        this.total = JSON.parse(res.data).total
        this.current += 1
        data = data.map(item => {
          item.Day = item.packageName.split('-')[2].includes('Days') ? (item.packageName.split('-')[2]).split('Days')[0] : 1
          if (item.packageName.split('-')[2].includes('Days')) {
            item.text = `${item.packageName.split('-')[1]}/${(item.packageName.split('-')[2]).split('Days')[0]}${this.i18n.days}`
            item.isPackage = 1
          } else if (item.packageName.split('-')[2].includes('Unlimited')) {
            item.text = `${this.i18n.无限流量}/${item.Day}${this.i18n.天}`
            item.isPackage = 0
          } else {
            item.text = `${item.packageName.split('-')[2]}MB/${this.i18n.天}`
            item.isPackage = 0
          }
          return item
        })
        this.packagePageList.push(...data)
        this.loadingShow = false
        console.log(this.packagePageList)
      }, error => {
        console.log(error)
      }, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      })
    }, 250),
    // 获取地区列表
    getAreaList () {
      this.$post('chargenow/mifi/query', {
        'requestMethod': 'get',
        'url': '/mifi-open-api/wifiBase/regionList',
        'data': ''
      }, (res) => {
        this.areaList1 = res.data ? JSON.parse(res.data) : []
        // let alist = JSON.parse(res.data)
        // alist.forEach(item => {
        //   let list = areaList.filter((it, index) => {
        //     if (index === 0) {
        //       return false
        //     }
        //     return item === it.label
        //   })
        //   this.areaList1.push(...list)
        // })
        // this.areaList1.push(areaList[0])
      }, error => {
        console.log(error)
      }, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      })
    },
    goCuntry (label) {
      this.country = label
      this.searchText = ''
      this.reload()
    },
    handleScroll (e, val) {
      if (this.packagePageList) {
        if (this.packagePageList.length < this.total) {
          this.loadingShow = true
          this.noMore = false
          this.onSearch()
        } else {
          this.noMore = true
        }
      }
    },
    onSelect (skuId, country) {
      this.show = true
      this.$post('chargenow/mifi/query', {
        'requestMethod': 'post',
        'url': '/mifi-open-api/wifiBase/packageDtlListBySkuId',
        'data': {
          'skuId': skuId,
          'country': country
        }
      }, (res) => {
        this.packageDtlList = JSON.parse(res.data)
        this.packageDtlList = this.packageDtlList.map(item => {
          item.Day = item.packageName.split('-')[2].includes('Days') ? (item.packageName.split('-')[2]).split('Days')[0] : 1
          if (item.packageName.split('-')[2].includes('Days')) {
            item.text = `${item.packageName.split('-')[1]}/${(item.packageName.split('-')[2]).split('Days')[0]}天`
            item.isPackage = 1
          } else if (item.packageName.split('-')[2].includes('Unlimited')) {
            item.text = `${this.i18n.无限流量}/${item.Day}${this.i18n.天}`
            item.isPackage = 0
          } else {
            item.text = `${item.packageName.split('-')[2]}MB/${this.i18n.天}`
            item.isPackage = 0
          }
          return item
        })
        this.packageObj = { ...this.packageDtlList[0] }
        console.log('流量套餐列表===', this.packageDtlList)
      }, error => {
        console.log(error)
      }, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      })
    }

  }

}
</script>

<style lang="less" scoped>
.page-top-box {
  height: auto;
  flex-shrink: 0;
}

.scroll-box {
  flex: 1; // flex 1的意思就是 flex-grow 1,这里是缩写 //
  // height: 600px;
  overflow-y: scroll;
}

.areaImg {
  width: 76px;
  height: 76px;
}

.area {
  display: flex;
  overflow-x: scroll;
  margin: 0 auto;
  width: 680px;

  .areaItem {

    // margin: 0 32rpx;
    .areaT {
      width: 140px;
      text-align: center;
    }
  }

  .areabox {
    text-align: center;
    width: 140px;
  }
}

.area::-webkit-scrollbar {
  display: none
}

.page-top {
  width: 100%;
  height: 442px;
  background: #FFFFFF;
  border-radius: 4px 4px 100px 100px;
  background-size: cover;
  background-image: url('../../assets/imgs/timg.png');
}

.line-wrap {
  line-height: 3;
}

.page-title {
  text-align: center;
  font-size: 44px;
  font-weight: bold;
  color: #1A2229;
  line-height: 3;
}

.pakage-info {
  font-size: 26px;
  font-weight: 400;
  color: #909090;
  margin-left: 20px;
}

.device-title {
  font-size: 28px;
  font-weight: bold;
  color: #1A2229;
}

.device-info {
  font-size: 24px;
  font-weight: 400;
  color: #666666;
}

.device-img {
  margin-right: 30px;
}

.choose-card {
  background: #CBEDD9;
  border-radius: 18px 18px 18px 18px;
  padding: 20px;
}

.choose-title {
  font-size: 28px;
  font-weight: 600;
  color: #00AB46;
}

.btn {
  width: 100%;
  background: linear-gradient(132deg, #1A2229 0%, #00AB46 100%);
  box-shadow: 0px 6px 10px 1px rgba(49, 200, 137, 0.2);
  border-radius: 60px 60px 60px 60px;
  opacity: 1;
}

.btn-box {
  margin: 76px 40px;
}

.search-box {
  margin: 40px;
}

.equipment {
  background: #DBEDE2;
  border-radius: 18px 18px 18px 18px;
  margin: 20px 40px;
  padding: 23px 20px;
}

.equipment-text {
  font-size: 32px;
  font-weight: 600;
  color: #333333;
}

.orderhead-box {
  margin: 40px 40px 20px 40px;
}

.select-btn {
  // position: absolute;
  // right: 20px;
  // bottom: 20px;
  padding: 0 15px;
}

.order-List {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0px;
  top: 10px;
}

.pakage-box {
  margin: 20px;
  position: relative;
  // width: 100%;
  background: #FFFFFF;
  border-radius: 18px 18px 18px 18px;
  border: 1px solid #D1D1D1;
  padding: 17px 20px;

  .pakage-price {
    font-size: 30px;
    font-weight: bold;
    color: #FF1919;
  }
}

.pakage-title {
  font-size: 24px;
  color: #666666;
}

.pakage-num {
  padding: 20px;
  .checktime{
    border: solid 1px #D1D1D1;
    padding: 15px 20px;
    border-radius: 20px;
  }
}

.dialog-bottom {
  padding: 40px 20px;

  .bottom-price {
    font-size: 40px;
    font-weight: bold;
    color: #FF1919;
  }

  .bottom-pakage-btn {
    background: linear-gradient(132deg, #1A2229 0%, #00AB46 100%);
    box-shadow: 0px 6px 10px 1px rgba(0, 171, 70, 0.2);
    border-radius: 60px 60px 60px 60px;
    padding: 23px 73px;
    color: #fff;
  }
}

/deep/.van-icon-orders-o {
  position: unset !important;
  left: unset !important;

}

.order-item {
  margin-bottom: 20px;
}

.h-country {
  color: #00AB46;
  font-weight: 600;
}

.h-pakage {
  border: 3px solid #00AB46;
}

.package-selected {
  width: 46px;
  height: 26px;
  background: #00AB46;
  border-radius: 18px 0px 18px 0px;
  opacity: 1;
  position: absolute;
  right: 0;
  bottom: 0;
}
</style>
