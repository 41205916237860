<template>
  <div class="menu-side">
    <div class="menu-side-title">
      <img src="../../assets/imgs/avatar.png" alt="">
      <span v-if="user && user.pname">{{user.pname}}</span>
      <span v-else @click="goRouter('/login')">{{i18n.login_tips}}</span>
    </div>
    <div class="menu-side-row" @click="goRouter('/wallet')">
      <img src="../../assets/imgs/wallet.png" alt="">
      <span>{{i18n.wallet}}</span>
    </div>
    <div class="menu-side-row" @click="goRouter('/pastOrder')">
      <img src="../../assets/imgs/history.png" alt="">
      <span>{{i18n.order}}</span>
    </div>
    <div class="menu-side-row" @click="goRouter('/aboutUs')">
      <img src="../../assets/imgs/help.png" alt="">
      <span>{{i18n.aboutus}}</span>
    </div>
    <div class="menu-side-row" @click="goRouter('/contactUs')">
      <img src="../../assets/imgs/shop.png" alt="">
      <span>{{i18n.contactus}}</span>
    </div>
    <div class="menu-side-row" @click="goRouter('/setting')">
        <img src="../../assets/imgs/setting.png" alt="">
        <span>{{i18n.setting}}</span>
      </div>
      <div class="menu-side-row" @click="goRouter('/help')">
        <img src="../../assets/imgs/help.png" alt="">
        <span>{{i18n.help}}</span>
      </div>
      <div class="menu-side-row" @click="onLogout">
        <img src="../../assets/imgs/logout.png" alt="">
        <span>{{i18n.logout}}</span>
      </div>
    <!-- <div class="menu-side-row">
      <img src="../../assets/imgs/coupon.png" alt="">
      <span>优惠券</span>
    </div> -->
    <div class="menu-side-foot">
      <div class="menu-side-box" @click="goRouter('/contactUs')">
        <div class="menu-side-foot-title">JOIN US</div>
        <div class="menu-side-foot-info">Become a partner and have a chargeNow staion at your venue.</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['user']),
    i18n () {
      return this.$t('mine')
    }
  },
  methods: {
    onLogout () {
      this.$store.commit('user', null)
      this.$store.commit('token', '')
      this.$store.commit('appleIdToken', '')
      this.$router.push('/login')
    },
    goRouter (path) {
      this.$router.push(path)
    }
  }
}
</script>

<style lang="less">
.menu-side {
  width: 550px;
  border-radius: 0px 100px 0px 0px;

  &-title {
    font-size: 48px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 80px 0 48px;
    background: linear-gradient(90deg, #1A2229 0%, #00AB46 100%);
    border-radius: 0px 100px 0px 0px;
    color:#FFFFFF;
    img {
      width: 96px;
      height: 96px;
      border-radius: 50%;
      margin: 0 24px 0 48px;
    }
  }
  &-row {
    font-size: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100px;
    img {
      width: 40px;
      height: 40px;
      margin: 0 24px 0 68px;
    }
  }
  &-foot {
    width: 100%;
    position: absolute;
    bottom: 48px;
    &-title{
      font-size: 36px;
      font-family: Montserrat-Bold, Montserrat;
      font-weight: bold;
      color: #FFFFFF;
      text-align: center;
    }
    &-info{
font-size: 22px;
font-family: Montserrat-Light, Montserrat;
font-weight: 300;
color: #FFFFFF;
    }
  }
  &-box{
background: linear-gradient(88deg, #1A2229 0%, #00AB46 100%);
border-radius: 20px 20px 20px 20px;
width: 450px;
margin: 0 auto;
padding: 10px;
  }
}
</style>
