<template>
  <page :title="!$route.meta.type ? i18n.title : ''">
    <div class="aboutus-top">
      <img class="aboutus-logo" src="../assets/imgs/contactus/logo_white_text.png" alt="">
      <img class="aboutus-devicetype" src="../assets/imgs/contactus/alldevicetype.png" alt="">
      <div class="desc-item">
        <p>{{ i18n.desc1 }}</p>
        <p>{{ i18n.desc2 }}</p>
      </div>
      <img v-if="lang === 'pt-PT'" class="aboutus-value" src="../assets/imgs/contactus/vision_values_pt.png" alt="">
      <img v-else class="aboutus-value" src="../assets/imgs/contactus/vision_values.png" alt="">
      <img class="aboutus-2018" src="../assets/imgs/contactus/2018.png" alt="">
      <img class="aboutus-add" src="../assets/imgs/contactus/60add.png" alt="">
    </div>
    <div class="contactus-form">
      <div class="form-title">{{ i18n.contactustitle }}</div>
      <div class="form-item">
        <input type="text" v-model="formData.Name" :placeholder="i18n.name">
      </div>
      <div class="form-item">
        <input type="text" v-model="formData.Email" :placeholder="i18n.email">
      </div>
      <div class="form-item">
        <input type="text" v-model="formData.Mobile" :placeholder="i18n.tel">
      </div>
      <div class="form-item">
        <input type="text" v-model="formData.nation" :placeholder="i18n.nation">
      </div>
      <textarea class="form-textarea" cols="20" rows="5" v-model="formData.Content" :placeholder="i18n.message"></textarea>
      <div class="btn submit" @click="onSubmit">{{ i18n.submit }}</div>
    </div>
    <div class="contactus">
      <div class="contactus-item">
        <img src="../assets/imgs/contactus/icon_web.png" alt="">
        <span>www.chargenow.top</span>
      </div>
      <div class="contactus-item">
        <img src="../assets/imgs/contactus/icon_whatsapp.png" alt="">
        <span>+86 139 2500 3135</span>
      </div>
      <div class="contactus-item">
        <img src="../assets/imgs/contactus/icon_wechat.png" alt="">
        <span>640653529</span>
      </div>
      <div class="contactus-item">
        <img src="../assets/imgs/contactus/icon_mobile.png" alt="">
        <span>+86 134 8460 4813</span>
      </div>
      <div class="contactus-item">
        <img src="../assets/imgs/contactus/icon_email.png" alt="">
        <span>info@chargenow.top</span>
      </div>
    </div>
    <div class="clear"></div>
  </page>
</template>

<script>
import { mapState } from 'vuex'
import myaxios, { domain } from '../utils/http'
export default {
  computed: {
    ...mapState(['lang']),
    i18n () {
      return this.$t('aboutus')
    },
    i18nMsg () {
      return this.$t('msg')
    }
  },
  data () {
    return {
      formData: {
        Content: '',
        Email: '',
        Mobile: '',
        Name: '',
        nation: '',
        source: 'chargenow h5'
      }
    }
  },
  methods: {
    async onSubmit () {
      console.log(this.formData)
      if (!this.formData.Name || !this.formData.Name.trim()) {
        this.$toast(this.i18nMsg.msg_none_name)
        return
      }
      if (!this.formData.Email || !this.formData.Email.trim()) {
        this.$toast(this.i18nMsg.msg_none_email)
        return
      }
      if (!this.formData.Mobile || !this.formData.Mobile.trim()) {
        this.$toast(this.i18nMsg.msg_none_mobile)
        return
      }
      if (!this.formData.nation || !this.formData.nation.trim()) {
        this.$toast(this.i18nMsg.msg_none_nation)
        return
      }
      // if (!this.formData.Content || !this.formData.Content.trim()) {
      //   this.$toast(this.i18nMsg.msg_none_msg)
      //   return
      // }
      let datas = await myaxios({
        url: `${domain}/crmLeads/add/public`,
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        data: { ...this.formData }
      })
      this.$toast(this.i18nMsg.success)
      console.log('提交的结果===', datas)
    }
  },
  mounted () {
    let obj = {
      'zh-CN': 'zh-CN',
      'en-US': 'en-US',
      'ru-RU': 'en-US',
      'es-ES': 'en-US',
      'fr-FR': 'en-US',
      'pt-BR': 'pt-PT',
      'ar-SA': 'en-US',
      'lt-LT': 'lt-LT',
      'et-EE': 'en-US',
      'it-IT': 'en-US'
    }
    if (this.$route.params && this.$route.params.lang) { // 适配app
      console.log(obj[this.$route.params.lang])
      this.$i18n.locale = obj[this.$route.params.lang]
      this.$store.commit('lang', obj[this.$route.params.lang])
    }
  }
}
</script>

<style lang="less" scoped>
.aboutus-top{
  background: #00AB46;
  .aboutus-logo{
    width: 30%;
    display: block;
    margin: 60px auto 20px;
  }
  .aboutus-devicetype{
    width: 100%;
  }
  .aboutus-value,.aboutus-2018,.aboutus-add{
    width: 90%;
    display: block;
    margin: 40px auto;
  }
  .desc-item{
    color: #fff;
    line-height: 1.5;
    p{
      margin: 10px 40px;
    }
  }
}
.contactus{
  background: #fff;
  border-radius: 20px;
  margin: 40px;
  padding: 0px 20px 20px;
  &-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    font-size: 30px;
    color: #666666;
    img{
      width: 50px;
      margin-right: 40px;
    }
  }
}
.contactus-form{
  background: #fff;
  border-radius: 20px;
  margin: 40px 40px 0;
  padding: 40px 30px;
  .form-title{
    text-align: start;
    font-weight: bold;
    margin-bottom: 40px;
  }
  .form-item{
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
    input{
      width: 90%;
      display: block;
      margin: 0 auto;
      padding: 20px;
      border: solid 1px #D1D1D1;
      outline: none;
      border-radius: 10px;
    }
  }
  textarea{
    width: 93%;
    display: block;
    margin: 30px auto 0;
    padding: 10px;
    border: solid 1px #D1D1D1;
    outline: none;
    border-radius: 10px;
  }
  .submit{
    width: 97%;
    margin: 40px auto;
  }
}
.clear{
  padding: 10px;
}
</style>
