import { render, staticRenderFns } from "./wifiMall.vue?vue&type=template&id=8e73e186&scoped=true"
import script from "./wifiMall.vue?vue&type=script&lang=js"
export * from "./wifiMall.vue?vue&type=script&lang=js"
import style0 from "./wifiMall.vue?vue&type=style&index=0&id=8e73e186&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e73e186",
  null
  
)

export default component.exports