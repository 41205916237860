import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import { Page } from './components/layouts'
import { get, post, Request } from './utils/http'
import 'lib-flexible/flexible.js'
import Toast from './components/toast'
import Confirm from './components/confirm'
import VueI18n from 'vue-i18n'
import langs from './langs'
import dayjs from 'dayjs'
import infiniteScroll from 'vue-infinite-scroll'
// import vConsole from './utils/vconsole'

import { Row, Col, Button, Icon, Image, Search, Dialog, Stepper, Cell, CellGroup, RadioGroup, Radio, Circle, Field, Divider, Loading, DatetimePicker, ActionSheet, Popup, Empty } from 'vant'
import "vant/lib/index.css"
import "./assets/css/font.css"
import "./assets/css/public.less"
Vue.use(Col)
Vue.use(Row)
Vue.use(Button)
Vue.use(Icon)
Vue.use(Image)
Vue.use(Search)
Vue.use(Dialog)
Vue.use(Stepper)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Circle)
Vue.use(Field)
Vue.use(Divider)
Vue.use(Loading)
Vue.use(DatetimePicker)
Vue.use(ActionSheet)
Vue.use(Popup)
Vue.use(Empty)

// Vue.prototype.vConsole = vConsole

const timeZoneConversion = (time, timeZone) => {
  let timezone = JSON.parse(timeZone)[0].timeDifference // 目标时区时间，东5.5区
  // var offset_GMT = new Date("Thu, 05 Sep 2019 02:04:34 GMT").getTimezoneOffset(); // 浏览器Date中时间和格林威治的时间差，单位为分钟
  let nowDate = new Date(time).getTime() // 浏览器Date中时间距 1970 年 1 月 1 日午夜（GMT 时间）之间的毫秒数
  console.log(timezone)
  let targetDate = new Date(nowDate - 8 * 60 * 60 * 1000 + timezone * 60 * 60 * 1000).format('yyyy-MM-dd hh:mm:ss')
  console.log(`目标时区现在是：${targetDate}`)
  return targetDate
}
Vue.config.productionTip = false

Vue.use(infiniteScroll)
Vue.use(VueI18n)
Vue.$get = Vue.prototype.$get = get
Vue.$post = Vue.prototype.$post = post
Vue.$Request = Vue.prototype.$Request = Request
Vue.$loading = Vue.prototype.$loading = (param) => {
  store.commit('loading', param)
}
Vue.$toast = Vue.prototype.$toast = Toast
Vue.$confirm = Vue.prototype.$confirm = Confirm

Vue.component('page', Page)

const i18n = new VueI18n(langs)

Vue.prototype._i18n = i18n
Vue.prototype.$timeZoneConversion = timeZoneConversion

i18n.locale = store.state.lang

Vue.filter('dateFmt', function (value) {
  const diff = 8 + new Date().getTimezoneOffset() / 60
  return dayjs(value).add(-diff, 'hour').format('YYYY-MM-DD HH:mm:ss')
})
Vue.filter('decode', function (value) {
  if (value.length >= 5 && value.length % 5 === 0 && value.indexOf('u') >= 0) {
    return unescape(value.replace(/u/g, '%u'))
  } else {
    return value
  }
})

String.prototype.format = function () {
  if (arguments.length === 0) return this
  const param = arguments[0]
  let str = this
  if (typeof (param) === 'object') {
    for (let key in param) {
      str = str.replace(new RegExp('\\{' + key + '\\}', 'g'), param[key])
    }
    return str
  } else {
    for (let i = 0; i < arguments.length; i++) {
      str = str.replace(new RegExp('\\{' + i + '\\}', 'g'), arguments[i])
    }
    return str
  }
}

const ua = navigator.userAgent.toLowerCase()
console.log('ua11', ua)
if (ua.indexOf('micromessenger') > -1) {
  store.commit('browser', 'wechat')
} else if (ua.indexOf('alipay') > -1) {
  store.commit('browser', 'alipay')
}
if (ua.indexOf('android') > -1) {
  store.commit('system', 'android')
} else if (ua.indexOf('iphone') > -1) {
  store.commit('system', 'ios')
}

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
