<template>
  <page title="Usage">
    <div class="Usage" ref="Usage">
      <template v-for="(item,index) in  usageList" >
        <div v-if="index === currentIndex" class="usage-box" :key="item.info" :ref="`step_${index}`">
          <div class="u-step flex-c">step {{index+1}}</div>
          <div class="u-img">
            <img :src="item.src">
          </div>
          <div class="u-info">{{item.info}}</div>
          <div class="u-subinfo">{{item.subinfo}}</div>
          <div class="flex-c dotted-box">
            <div :class="index==0?'dotted':'dotte'"></div>
            <div :class="index==1?'dotted':'dotte'"></div>
            <div :class="index==2?'dotted':'dotte'"></div>
            <div :class="index==3?'dotted':'dotte'"></div>
          </div>
        </div>
      </template>
      <div class="next flex-c">
        <van-image width="1.17rem" fit="contain" :src="require('assets/imgs/icon_next_white.png')" @click="nextImage"></van-image>
      </div>
    </div>
  </page>
</template>

<script>

export default {
  components: {
  },
  computed: {

  },
  data () {
    return {
      usageList: [{
        src: require('assets/imgs/cdb1.png'),
        info: 'Locate a ChargeNow Station',
        subinfo: 'Find your nearest ChargeNow station'
      },
      {
        src: require('assets/imgs/cdb2.png'),
        info: 'Scan the QR code',
        subinfo: 'Scan the QR code on the station using the Chargenow App on your phone to unlock your Chargenow powerbank.'
      },
      {
        src: require('assets/imgs/cdb3.png'),
        info: 'Charge on the go.',
        subinfo: 'Grab the unlocked powerbank fromthe station, and charge freely, on-the go!'
      },
      {
        src: require('assets/imgs/cdb4.png'),
        info: 'Return Anywhere.',
        subinfo: 'Pop the power bank into any of our stations nearest to your location and at your convenience.'
      }],
      currentIndex: 0
    }
  },
  methods: {
    nextImage () {
      if (this.currentIndex >= this.usageList.length - 1) {
        this.currentIndex = 0
      } else {
        this.currentIndex++
      }
    }
  },
  created () {
  }
}
</script>

<style lang="less" scoped>
.Usage{
  display: flex;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  background: linear-gradient(#00AB46, #1A2229);
}
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.usage-box{
  padding: 0 20px;
  position: relative;
  margin-right: 0;
  background: transparent;
}
.u-step{
  width: 191px;
  height: 91px;
  background: linear-gradient(126deg, #1A2229 0%, #00AB46 100%);
  border-radius: 18px 0px 50px 0px;
  color: #fff;
  font-size: 36px;
  font-family: Work Sans-Bold, Work Sans;
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
}

.u-img{
  img{
    display: block;
    max-width: 80%;
    margin: 0 auto;
  }
}
.dotted{
  width: 10px;
  height: 10px;
  margin: 0 10px;
  background: #fff;
}
.dotte{
  width: 10px;
  height: 10px;
  margin: 0 10px;
  background: #D1D1D1;
}
.dotted-box{
  margin: 20PX 0 10PX 0;
}
.next{
  position: fixed;
  bottom: 5%;
  right: 10%;
  border-radius: 100%;
  background: #fff;
}
.u-info{
  font-size: 60px;
  font-weight: bold;
  color: #fff;
}
.u-subinfo{
  font-size: 30px;
  font-weight: 500;
  color: #fff;
  margin-top: 10px;
}
</style>
