<template>
  <div class="google-map">
    <div id="g-map"></div>
    <img class="location-icon" src="../../assets/imgs/location_icon.png" alt="">
    <!-- <div class="location" @click="onLocation">
      <img src="../../assets/imgs/location.png" alt="">
    </div> -->
  </div>
</template>
<script>
import { mapState } from 'vuex'
let gMapContext
let gMapTimer
let marker
let myMarker
let infoWindow
export default {
  props: {
    apikey: {
      type: String,
      default: 'AIzaSyAUItu330AOWowp23-rk55UrYXBxIC6DHM'
    },
    latLng: {
      type: Array,
      default: () => {
        return []
      }
    },
    locationList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    ...mapState(['lang']),
    i18n () {
      return this.$t('map')
    },
    i18nMsg () {
      return this.$t('msg')
    }
  },
  data () {
    return {
      gMapContext: null,
      times: 0,
      latitude: 22.631123,
      longitude: 113.820622,
      centerChange: false
    }
  },
  watch: {
    latLng (val, oldVal) {
      if (val && val.length === 2) {
        this.latitude = val[0]
        this.longitude = val[1]
        if (gMapContext && !this.centerChange) {
          const center = { lat: this.latitude, lng: this.longitude }
          gMapContext.setZoom(16)
          gMapContext.setCenter(center)
        }
      }
    },
    locationList (val) {
      const list = []
      if (val.length) {
        val.map((item, index) => {
          list.push([{ lat: Number(item.latitude), lng: Number(item.longitude) }, item])
        })
        this.setMarker(list)
      } else {
        console.log(window.marker)
        if (window.marker) {
          marker.setMap(null)
        }
        this.$toast(this.i18n.result_msg)
      }
    }
  },
  methods: {
    initMap () {
      if (document.getElementById('g-map')) {
        this.times = 0
        console.log('gmapcontext')
        // The location of Uluru
        const center = { lat: this.latitude, lng: this.longitude }
        // The map, centered at Uluru
        gMapContext = new google.maps.Map(document.getElementById('g-map'), {
          zoom: 12,
          center: center,
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: true,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          clickableIcons: false
        })
        // console.log('center', center)
        gMapContext.setCenter(center)
        infoWindow = new google.maps.InfoWindow()
        gMapContext.addListener('click', (event) => {
          console.log('google map tap', event.latLng.lat(), event.latLng.lng())
          // this.onMap(event)
          this.$emit('onmap')
        })
        gMapContext.addListener('center_changed', () => {
          this.onCenterChange()
          // console.log('center_changed')
        })
        gMapContext.addListener('zoom_changed', () => {
          this.onRegionChange()
          // console.log('zoom_changed')
        })
        this.onLocation()
      } else {
        this.times++
        console.log('gmaptimeout')
        gMapTimer = setTimeout(this.initMap, 500 * this.times)
      }
    },
    onCenterChange (e) {
      if (!gMapContext) {
        return
      }
      this.centerChange = true
      // console.log([gMapContext.getCenter().lat(), gMapContext.getCenter().lng()])
      this.$emit('center', [gMapContext.getCenter().lat(), gMapContext.getCenter().lng()])
      this.$emit('setItem', null)
      setTimeout(() => {
        this.centerChange = false
      }, 100)
    },
    onRegionChange (e) {
      if (!gMapContext) {
        return
      }
      console.log(gMapContext.getZoom())
      const zoomLevel = (gMapContext.getZoom() / 2 - 2).toFixed(0)
      this.$emit('zoom', zoomLevel > 4 ? zoomLevel : 4)
    },
    onLocation () {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            }
            // console.log(pos)
            gMapContext.setCenter(pos)
            this.setMarker(pos)
            this.latitude = position.coords.latitude
            this.longitude = position.coords.longitude
            this.$emit('center', [position.coords.latitude, position.coords.longitude])
            // this.$emit('center', [22.631123, 113.820622])
          },
          (err) => {
            console.log(err)
            this.$emit('center', this.latLng)
            // this.$confirm({
            //   title: this.i18n.msg_title,
            //   content: this.i18nMsg.geolocationfailed,
            //   confirmText: this.i18nMsg.confirm,
            //   showCancel: false
            // }).then(() => {})
          }
        )
      } else {
        this.$toast(this.i18n.no_support)
      }
    },
    setMarker (pos) {
      if (!gMapContext) {
        return
      }
      const image = {
        url: 'https://app.chargenow.top/content/marker0.png',
        scaledSize: new google.maps.Size(50, 56),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(50, 56)
      }
      if (Array.isArray(pos)) {
        pos.forEach(([position, item], i) => {
          const markers = new google.maps.Marker({
            position,
            map: gMapContext,
            icon: image
            // zIndex: i + 1
          })
          // Add a click listener for each marker, and set up the info window.
          markers.addListener('click', () => {
            // console.log('点击标记了', markers.getTitle())
            this.$emit('setItem', item)
          })
        })
      } else {
        myMarker = new google.maps.Marker({
          position: pos,
          map: gMapContext,
          label: this.i18n.my_position
        })
      }
    },
    importMap () {
      if (!gMapContext && !window.google) {
        console.log('gmap import')
        let language = 'en'
        if (this.lang !== 'en-US') {
          language = 'zh-CN'
        }
        this.createScript(`https://maps.googleapis.com/maps/api/js?key=${this.apikey}&callback=initMap&language=${language}`)
      } else {
        console.log('gmap init')
        this.initMap()
      }
    },
    createScript (src) {
      let oscript = window.document.createElement('script')
      oscript.id = `g-map-script-${this.lang}`
      oscript.src = src
      oscript.onerror = error => {
        console.log('google map load fail', error)
      }
      let head = window.document.head || window.document.getElementsByTagName('head')[0] || window.document.documentElement
      head.insertBefore(oscript, head.firstChild)
    }
  },
  created () {
    window.initMap = this.initMap
    this.latitude = this.latLng[0]
    this.longitude = this.latLng[1]
  },
  mounted () {
    console.log('google map mounted')
    this.importMap()
  },
  beforeDestroy () {
    console.log('google map destroyed')
    if (gMapContext) {
      gMapContext = null
    }
    if (gMapTimer) {
      clearTimeout(gMapTimer)
    }
  }
}
</script>
<style lang="less" scoped>
#g-map{
  height: 100%;
  width: 100%;
}
.location {
  position: absolute;
  width: 50px;
  height: 50px;
  bottom: 120px;
  right: 80px;
  img {
    width: 100px;
    height: 100px;
  }
}
.location-icon {
  position: absolute;
  width: 36px;
  height: 58px;
  bottom: 50%;
  left: calc(50% - 18px);
}
</style>
