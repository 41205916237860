<template>
  <div class="login-warp">
    <div class="login-head">
      <img class="backicon" src="../../assets/imgs/back.png" @click="onBack">
    </div>
    <img class="banner" src="../../assets/imgs/loginbanner.png">
    <img class="logowhite" src="../../assets/imgs/logo_white.png">
    <div class="login-foot">
      <login-apple v-if="system === 'ios'" @success="loadUser()">
        <button class="btn appleloginbtn2" id="sign-in-with-apple-button">
          <img src="../../assets/imgs/icon_applepay.png" alt="">
          <span>{{ i18n.applelogin }}</span>
        </button>
      </login-apple>
      <login-google v-else @success="loadUser">
        <div id="buttonDiv"></div>
      </login-google>
      <div class="btn otherlogin" @click="goLogin">{{ i18n.otherlogin }}</div>
    </div>
  </div>
</template>

<script>
import { baseURL } from '../../utils/http'
import { USER_CAPTCHA, LOGIN_CODE, SEND_CODE, SEND_CODE2, USER_INFO } from '../../apis/user'
import { LoginApple, LoginGoogle } from '../../components/login'
import { mapState } from 'vuex'
import AreaCodeDate from '@/utils/area_code.js'
export default {
  components: {
    LoginGoogle,
    LoginApple
  },
  computed: {
    ...mapState(['user', 'browser', 'system']),
    i18n () {
      return this.$t('login')
    }
  },
  watch: {
    user (val, oldVal) {
      if (val && !oldVal) {
        this.$router.push({ name: 'homeMap' })
      }
    }
  },
  data () {
    return {
      phone: ''
    }
  },
  methods: {
    onBack () {
      this.$router.go(-1)
    },
    goLogin () {
      this.$router.push('/otherLogin')
    },
    loadUser () {
      this.$loading(true)
      this.$get(
        USER_INFO,
        {},
        res => {
          this.$loading(false)
          if (res.user) {
            this.$store.commit('user', res.user)
            this.$toast(this.i18n.msg_success_login)
          } else {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: this.i18n.msg_fail_user
            }).then(() => {
              this.loadUser()
            }).catch(() => {
              this.$store.commit('token', null)
            })
          }
        },
        err => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: err,
            showCancel: false
          }).then(() => {
            this.$store.commit('token', null)
          })
        }
      )
    }
  },
  created () {
  }
}
</script>

<style lang="less" scoped>
.login-warp {
  height: 100vh;
  background: linear-gradient(#00AB46, #1A2229);
  .login-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    .backicon{
      width: 50px;
    }
  }
  .banner{
    width: 90%;
    display: block;
    margin: -13% auto;
  }
  .logowhite{
    width: 15%;
    display: block;
    margin: 0 auto;
  }
  .login-foot {
    width: 90%;
    margin: 40px auto 0;
    .appleloginbtn2{
      width: 100%;
      border: none;
      background: #fff;
      color: #000;
      margin: 40px 0 0;
      img{
        width: 50px;
        margin-right: 20px;
      }
    }
    .appleloginbtn{
      width: 100%;
      border: none;
      background: #fff;
      color: #000;
      img{
        width: 60px;
        margin-right: 20px;
      }
    }
    .otherlogin{
      width: 100%;
      border: none;
      background: #00AB46;
      color: #fff;
      margin: 40px 0 0;
    }
    &-imgs {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
</style>
