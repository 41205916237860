<template>
  <page title="Package Order">
    <div class="vcard" style="padding: 0;">
      <OrderItem text="15 countries in Asia region - 500M/day "  price="35.00" >
      </OrderItem>
      <van-cell :title="i18n.orderNo" value="0020230805001" />
      <van-cell :title="i18n.startingTime" value="0020230805001" />
      <van-cell :title="i18n.endTime" value="0020230805001" />
      <van-cell :title="i18n.packageStatus" value="0020230805001" />
    </div>
    <div class="submit-bar">
      <van-button @click="onSelect" class="bottom-pakage-btn" round style="width: 100%;">{{i18n.confirmationOfPickup}}</van-button>
    </div>
  </page>
</template>

<script>
import OrderItem from '../../components/orderItem/orderItem.vue'

export default {
  components: {
    OrderItem
  },
  computed: {
    i18n () {
      return this.$t('pickUp')
    }
  },
  data () {
    return {

    }
  },
  methods: {

  },
  created () {
  }
}
</script>

<style lang="less" scoped>
.package-order-num{
  position: absolute;
    right: 40px;
    bottom: 40px;
}
.order-page{
margin-top: 20px;
  .device-title{
    font-size: 30px;
    font-weight: bold;
    color: #333333;
    margin: 10px 0;
  }
  .device-img{
    margin-right: 10px;
  }
  .device-name{
    font-size: 28px;
    font-weight: 500;
    color: #333333;
  }
  .device-price{
    font-size: 36px;
    font-weight: bold;
    color: #FF1919;
    line-height: 2;
    margin-right: 20px;
  }
  .device-info{
    font-size: 22px;
    font-weight: 400;
    color: #21B97C;
  }
}
  .bottom-pakage-btn{
background: linear-gradient(132deg, #1A2229 0%, #00AB46 100%);
box-shadow: 0px 6px 10px 1px rgba(0,171,70,0.2);
border-radius: 60px 60px 60px 60px;
color: #fff ;
  }
  .submit-bar{
    position: fixed;
    bottom: 68px;
    margin: 0 40px;
    width: 90%;
    .bar-price{
      font-size: 40px;
      font-weight: bold;
      color: #FF1919;
      padding-bottom: 10px;
    }
    .bar-detail{
background: #F5DDDD;
border-radius: 20px 20px 20px 20px;
font-size: 21px;
color: #FF1919;
line-height: 34px;
text-align: center;
    }
  }
    .p-20{
    padding: 0 20px;
  }
  .p-v-20{
    padding: 20px ;
  }
   .submit-bar{
    position: fixed;
    bottom: 68px;
    margin: 0 40px;
    width: 90%;
    .bar-price{
      font-size: 40px;
      font-weight: bold;
      color: #FF1919;
      padding-bottom: 10px;
    }
    .bar-detail{
background: #F5DDDD;
border-radius: 20px 20px 20px 20px;
font-size: 21px;
color: #FF1919;
line-height: 34px;
text-align: center;
    }
  }
</style>
