<template>
  <div class="page" :style="{ 'padding-top': 9 + 'px' }">
    <div class="page-nav">
      <slot name="nav">
      </slot>
    </div>
    <div v-if="hasHead" class="page-head" :style="{ 'padding-top': 9 + 'px' }">
      <div v-if="title" class="page-head-default">
        <van-icon v-if="showBack" name="arrow-left" @click="onBack" size="0.77rem" style="flex: 1;" />
        <div style="flex: 8;text-align: center;">{{ title }}</div>
        <div v-if="$slots.default" style="flex: 1;text-align: end;">
          <slot name="headleft"></slot>
        </div>
        <div v-else style="flex: 1;text-align: end;">
          <van-icon name="wap-home-o" class="flex-c" size='0.65rem' @click="gohome"/>
        </div>
      </div>
      <slot name="head">
      </slot>
    </div>
    <div class="page-body">
      <slot></slot>
    </div>
    <div class="page-foot" v-if="hasFoot">
      <slot name="foot"></slot>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    showBack: {
      type: Boolean,
      default: true
    },
    backHandel: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(['safeArea', 'homePathName', 'user']),
    hasHead () {
      return this.$slots.head || this.title
    },
    hasFoot () {
      return this.$slots.foot
    }
  },
  methods: {
    onBack () {
      console.log('backHandel', this.homePathName, this.user)
      if (this.$router.history.length > 1) {
        // 至少有一个页面可回退，执行路由回退
        this.$router.go(-1)
      } else if (!this.user || this.user.pname.includes('Tourist')) {
        this.$router.replace({ name: 'selectPakage' })
      } else {
        this.$router.replace({ name: this.homePathName || 'homeMap' })
      }
      // if (this.backHandel) {
      //   this.$emit('onBack')
      // } else {
      //   this.$router.go(-1)
      // }
    }
  },
  created () {
    console.log('slots', this.$slots.headleft)
  }
}
</script>

<style lang="less" scoped>
.page {
  &-head {
    // background: #3ca2fc;
    position: fixed;
    z-index: 99;
    top: 0;
    width: 100%;
    font-size: 36px;
    height: 88px;

    &-default {
      height: 88px;
      font-size: 36px;
      font-weight: bold;
      color: #1A2229;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 25px;
      img {
        height: 40px;
        width: 40px;
        position: absolute;
        left: 32px;
      }

      .van-icon {
        // position: absolute;
        // left: 40px;
        width: 58px;
        height: 58px;

      }
    }
  }

  &-head+&-body {
    padding-top: 88px;
  }

  &-foot {
    background: #1A2229;
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  &-nav {
    // background: #3ca2fc;
    font-size: 36px;
  }

}
.page-body{
   width: 100%;
    height: 91vh;
    display: flex;
    flex-direction: column;
}
</style>
