<template>
  <page title="Package Details">
    <template v-slot:headleft>
        <van-icon name="wap-home-o" class="flex-c" size='0.65rem' @click="gohome"/>
    </template>
    <div class="package-detail-warp">
      <div class="vcard flex-s">
        <div>
          <div class="flex-al-c">
            <div class="v-ling"></div>
            <span style="margin-left: 10px;">{{ mifiData && mifiData.data.sn }}</span>
          </div>
          <div class="device-box flex-al-c">
            <div class="box-item">
              <div class="box-key">{{i18n.WiFiName}}</div>
              <div class="box-value">{{ deviceInfo && deviceInfo.wifiSSID }}</div>

            </div>
            <div class="box-item">
              <div class="box-key">{{i18n.WiFiPassword}}</div>
              <div class="box-value">{{ deviceInfo && deviceInfo.wifiPassword }}</div>
            </div>

          </div>
          <div class="device-box flex-al-c">
            <div class="box-item">
              <div class="box-key">{{i18n.batteryLife}}</div>
              <div class="box-value">{{ mifiData && mifiData.data.dianliang || 0 }}%</div>

            </div>
            <div class="box-item">
              <div class="box-key">{{i18n.Signal}}</div>
              <div class="box-value">{{ deviceInfo && deviceInfo.lastKnownVsimSignalStrength || 0 }}%</div>
            </div>
          </div>
        </div>
        <van-image width="2.36rem" fit="contain" :src="require('assets/imgs/wifiDevice.png')" />
      </div>
      <div class="vcard order-item" v-for="(item, i) in (mifiData && mifiData.data.packageDtlList)" :key="i">
        <OrderItem :src="item.flagUrl" :text="item.country + '-' + item.packageName" :currencyName="item.currencyName" :price="item.packagePrice" ></OrderItem>
        <van-cell :title="i18n.orderNo" :value="item.orderId" />
        <van-cell :title="i18n.startingTime" :value="item.reserverStartTime" />
        <van-cell :title="i18n.endTime" :value="item.reserverEndTime" />
        <van-cell :title="i18n.packageStatus">
          <span v-if="item.packageStatus == 0" class="text-green">{{ i18nScanOrder.type_0 }}</span>
          <span v-else-if="item.packageStatus == 1" class="text-green">{{ i18nScanOrder.type_1 }}</span>
          <span v-else-if="item.packageStatus == 2" class="text-green">{{ i18nScanOrder.type_2 }}</span>
          <span v-else-if="item.packageStatus == 3" class="text-green">{{ i18nScanOrder.type_3 }}</span>
          <span v-else-if="item.packageStatus == 4" class="text-green">{{ i18nScanOrder.type_4 }}</span>
          <span v-else-if="item.packageStatus == 9" class="text-green">{{ i18nScanOrder.type_9 }}</span>
          <span v-else-if="item.packageStatus == 33" class="text-green">{{ i18nScanOrder.type_33 }}</span>
          <span v-else class="text-green">{{ i18nScanOrder.type_88 }}</span>
        </van-cell>
        <van-cell v-if="item.packageStatus == 2" :title="i18n.usedTraffic" :value="usedTraffic(item.reserverStartTime) + 'MB'" />
      </div>
    </div>
    <div class="detail-btn">
      <van-button @click="onSubmit" class="bottom-pakage-btn" round :disabled="!deviceInfo">{{i18n.Renewals}}</van-button>
    </div>
    <div class="fixed-bottom">
      <a :href="whatsappUrl"><img src="../../assets/imgs/contactus/icon_whatsapp.png" alt=""></a>
    </div>
  </page>
</template>

<script>
import { mapState } from 'vuex'
import OrderItem from '../../components/orderItem/orderItem.vue'
import { QR_CODE, MIFI_FLOWUSE } from '../../apis/cabinet'
import { MIFI_ORDERDETAIL } from '../../apis/user'
import dayjs from 'dayjs'
export default {
  components: {
    OrderItem
  },
  computed: {
    ...mapState(['qrcode', 'cabinet', 'user', 'mificode']),
    i18n () {
      return this.$t('packageDetail')
    },
    i18nScanOrder () {
      return this.$t('scanOrder')
    },
    i18nMsg () {
      return this.$t('msg')
    },
    whatsappUrl () {
      return process.env.VUE_APP_WHATSAPP_SCHEME
    }
  },
  data () {
    return {
      mifiData: null,
      deviceInfo: null
    }
  },
  methods: {
    gohome () {
      this.$router.push({ name: 'homeMap' })
    },
    onScan () {
      this.$loading(true)
      this.$post(
        `${QR_CODE}?url=${encodeURIComponent(this.mificode)}`,
        {},
        resp => {
          this.$loading(false)
          this.mifiData = resp
          this.deviceInfo = resp.data && resp.data.deviceJson ? JSON.parse(resp.data.deviceJson) : null
          console.log('数据===', this.mifiData, this.deviceInfo)
          if (!this.mifiData || !this.mifiData.data || !this.mifiData.data.sn) {
            this.$confirm({
              title: this.i18nMsg.title,
              content: this.i18nMsg.devicenotactivated,
              showCancel: false,
              confirmText: this.i18nMsg.confirm
            })
          }
        },
        error => {
          this.$loading(false)
          this.$toast(error)
        }
      )
    },
    getDetail () {
      this.$loading(true)
      this.$get(
        `${MIFI_ORDERDETAIL}?orderId=${this.$route.query.orderId}`,
        {},
        resp => {
          this.$loading(false)
          console.log('数据===', resp)
          this.mifiData = resp
          this.deviceInfo = resp.data && resp.data.deviceJson ? JSON.parse(resp.data.deviceJson) : null
          if (!this.mifiData || !this.mifiData.data || !this.mifiData.data.sn) {
            this.$confirm({
              title: this.i18nMsg.title,
              content: this.i18nMsg.devicenotactivated,
              showCancel: false,
              confirmText: this.i18nMsg.confirm
            })
          }
        },
        error => {
          this.$loading(false)
          this.$toast(error)
        }
      )
    },
    onSubmit () {
      console.log('续购按钮')
      this.$store.commit('mifisn', this.mifiData.data.sn)
      if (this.$route.query.orderId) {
        this.$router.push('/wifiMall?type=DDXG')
        return
      }
      this.$router.push('/wifiMall?type=BXG')
    },
    usedTraffic (startTime) {
      let stimes = (dayjs(startTime).valueOf() / 1000).toFixed(0)
      let etimes = (dayjs().valueOf() / 1000).toFixed(0)
      console.log('时间戳===', stimes)
      this.$loading(true)
      this.$post(
        `${MIFI_FLOWUSE}`,
        {
          sn: this.mifiData.data.sn,
          packageStartTime: Number(stimes),
          packageEndTime: Number(etimes)
        },
        resp => {
          this.$loading(false)
          console.log('数据===', resp)
          if (resp.data) {
            return resp.data.totalUsage
          } else {
            return 0
          }
        },
        error => {
          this.$loading(false)
          // this.$toast(error)
          console.log('数据===', error)
        },
        {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          }
        }
      )
    }
  },
  created () {
    if (this.$route.query.orderId) {
      this.getDetail()
    } else {
      this.onScan()
    }
  }
}
</script>

<style lang="less" scoped>
.v-ling {
  width: 0px;
  height: 40px;
  border: 4px solid #00AB46;
}
.text-green {
  color: #00AB46;
}
.package-detail-warp {
  padding-bottom: 150px;
}

.device-box {
  .box-item {
    flex: 1;
    padding: 10px;
    line-height: 2;

    .box-key {
      font-size: 24px;
      font-family: Work Sans-Light, Work Sans;
      font-weight: 300;
      color: #666666;
    }

    .box-value {
      font-size: 28px;
      font-family: Work Sans-Medium, Work Sans;
      font-weight: 500;
      color: #00AB46;
    }
  }
}
.order-item{
  margin-top: 20px;
  padding: 0;
  padding-bottom: 20px;
}
.bottom-pakage-btn{
  background: linear-gradient(132deg, #1A2229 0%, #00AB46 100%);
  box-shadow: 0px 6px 10px 1px rgba(0,171,70,0.2);
  border-radius: 60px 60px 60px 60px;
  padding: 23px 73px;
  color: #fff ;
  width: 100%;
  font-size: 36px;
  font-family: Work Sans-Bold, Work Sans;
  font-weight: bold;
}
.detail-btn{
  padding: 20px;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
}
</style>
