<template>
  <page title="My Packages">

    <template v-if="orderList && orderList.length > 0">
      <div class="vcard scan-item" v-for="item in orderList" :key="item.orderId">
        <div>
          <OrderItem :src="item.flagUrl" :text="item.text" :currencyName="item.currencyName" :price="item.totalAmount" :useDay="item.useDay">
            <van-icon v-if="item.id === pickData.id" :name="require('../../assets/imgs/icon_select.png')" size="0.6rem"/>
            <van-icon v-else :name="require('../../assets/imgs/icon_unselect.png')" size="0.6rem" @click="pickData = item"/>
          </OrderItem>
        </div>
      </div>
      <div class="btn" @click="onConfirm">{{ (myType === 'QR' || myType === 'XG' || myType === 'BXG') ? i18nMsg.confirm : i18n.confirmpickup }}</div>
    </template>
    <result-msg v-else @click="onReload"/>
    <div class="fixed-bottom">
      <a :href="whatsappUrl"><img src="../../assets/imgs/contactus/icon_whatsapp.png" alt=""></a>
    </div>
  </page>
</template>

<script>
import { mapState } from 'vuex'
// import { PopupWarp } from '../../components/popup'
import OrderItem from '../../components/orderItem/orderItem.vue'
import { MIFI_PICKUP, QR_CODE, MIFI_BINDSN } from '../../apis/cabinet'
// import ScanQr from '../scanQr/scanQr.vue'
import ResultMsg from '../../components/result/msg.vue'
import dayjs from 'dayjs'
// import vueQr from 'vue-qr'

export default {
  components: {
    OrderItem,
    ResultMsg
  },
  computed: {
    ...mapState(['mificode', 'mifisn', 'mifiPackage']),
    i18n () {
      return this.$t('scanOrder')
    },
    i18nPickUp () {
      return this.$t('pickUp')
    },
    i18nMsg () {
      return this.$t('msg')
    },
    whatsappUrl () {
      return process.env.VUE_APP_WHATSAPP_SCHEME
    }
  },
  data () {
    return {
      orderList: [],
      typeMap: {},
      sn: '',
      pickData: {},
      myType: ''
    }
  },
  methods: {
    onReload () {
      this.getOrderList()
    },
    onConfirm () {
      if (!this.pickData || !this.pickData.orderId) {
        this.$toast(this.i18nMsg.msg_fail_choosepackage)
        return
      }
      if (this.myType === 'QR' || this.myType === 'XG') {
        let obj = {
          ...this.pickData
        }
        this.$store.commit('mifiPackage', obj)
        this.$router.replace('/selectPakage?deviceType=mifi')
      } else if (this.myType === 'BXG') {
        this.onScan(this.mificode)
      } else if (this.myType === 'DDXG') {
        this.bindSN()
      } else {
        this.pickUpMifi()
      }
    },
    pickUpMifi () {
      console.log('取机', this.mificode, this.pickData)
      if (!this.mificode) return
      if (!this.pickData || !this.pickData.orderId) {
        this.$toast(this.i18nMsg.msg_fail_choosepackage)
        return
      }
      let code = this.mificode.split('=')[1]
      this.$loading(true)
      this.$post(
        `${MIFI_PICKUP}`,
        {
          qrcode: code,
          pickOrderId: this.pickData.orderId
        },
        resp => {
          this.$loading(false)
          console.log('数据===', resp)
          this.$router.push(`/borrowSuccess?tradeNo=${resp.data.orderId}&loading=true`)
          this.$toast(this.i18n.pickupsuccess)
        },
        error => {
          this.$loading(false)
          this.$toast(error)
        }
      )
    },
    bindSN () {
      if (!this.mifisn) return
      if (!this.pickData || !this.pickData.orderId) {
        this.$toast(this.i18nMsg.msg_fail_choosepackage)
        return
      }
      this.$loading(true)
      this.$post(
        `${MIFI_BINDSN}`,
        {
          sn: this.mifisn,
          mifiOrderId: this.pickData.orderId
        },
        resp => {
          this.$loading(false)
          console.log('数据===', resp)
          this.$router.replace(`/packageDetail?orderId=${this.pickData.orderId}`)
          this.$toast(this.i18n.pickupsuccess)
        },
        error => {
          this.$loading(false)
          this.$toast(error)
        },
        {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          }
        }
      )
    },
    getOrderList () {
      this.$loading(true)
      this.$post('/chargenow/mifi/orderList', {
        current: 1,
        size: 100,
        isPicked: 0
      }, res => {
        this.$loading(false)
        let data = res.data.records
        data = data ? data.map(item => {
          item.Day = item.packageName.split('-')[2].includes('Days') ? (item.packageName.split('-')[2]).split('Days')[0] : 1
          if (item.packageName.split('-')[2].includes('Days')) {
            item.text = `${item.country}${item.packageName.split('-')[1]}/${(item.packageName.split('-')[2]).split('Days')[0]}${this.i18n.days}`
            item.isPackage = 1
          } else if (item.packageName.split('-')[2].includes('Unlimited')) {
            item.text = `${item.country}${this.i18n.无限流量}/${item.Day}${this.i18n.天}`
            item.isPackage = 0
          } else {
            item.text = `${item.country}${item.packageName.split('-')[2]}MB/${this.i18n.天}`
            item.isPackage = 0
          }
          item.packageStatusTitle = this.typeMap[item.packageStatus]
          item.useDay = dayjs(item.reserverEndTime).diff(item.reserverStartTime, 'day')
          return item
        }) : []
        // this.orderList.push(...data)
        this.orderList = data
      },
      error => {
        this.$loading(false)
        console.log(error)
      }
      )
    },
    onScan (urlcode) {
      this.$loading(true)
      this.$post(
        `${QR_CODE}?url=${encodeURIComponent(urlcode)}&mifiOrderId=${this.pickData.orderId}`,
        {},
        resp => {
          this.$loading(false)
          console.log(resp)
          if (resp.data) { // 扫宝
            this.$toast(this.$t('scanOrder.pickupsuccess'))
            this.$router.go(-1)
          }
        },
        error => {
          this.$loading(false)
          this.$toast(error)
        }
      )
    }
  },
  created () {
    this.getOrderList()
    this.typeMap = {
      0: this.i18n.type_0,
      1: this.i18n.type_1,
      2: this.i18n.type_2,
      3: this.i18n.type_3,
      4: this.i18n.type_4,
      9: this.i18n.type_9,
      33: this.i18n.type_33,
      88: this.i18n.type_88
    }
    if (this.$route.query.type) {
      this.myType = this.$route.query.type
    }
  }
}
</script>

<style lang="less" scoped>
.fixed{
  position: fixed;
  bottom: 0;
  width: 100%;
  // background: rgba(45, 45, 45, 0.5);
  height: 100%;
}
.scan-item{
  margin-bottom: 20px;
  padding: 10px;
}
.p-20{
  padding: 0 20px;
}
.p-v-20{
  padding: 20px ;
}
.round{
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 40px 0 20px;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #fff;
  .confirmbtn{
    margin: 100px 20px 30px;
  }
}
.loading-c{
  position: absolute;
}
</style>
