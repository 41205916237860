<template>
  <page :title="!$route.meta.type ? i18n.title : ''">
    <div class="desc">
      <div class="desc-item">{{ i18n.desc1 }}</div>
      <div class="desc-item">{{ i18n.desc2 }}</div>
    </div>
    <div class="contactus">
      <div class="contactus-title">{{ i18n.contactustitle }}</div>
      <div class="contactus-subtitle">{{ i18n.contactussubtitle }}</div>
      <div class="contactus-item">
        <img src="../assets/imgs/contactus/icon_web.png" alt="">
        <span>www.chargenow.top</span>
      </div>
      <div class="contactus-item">
        <img src="../assets/imgs/contactus/icon_whatsapp.png" alt="">
        <span>+86 139 2500 3135</span>
      </div>
      <div class="contactus-item">
        <img src="../assets/imgs/contactus/icon_wechat.png" alt="">
        <span>640653529</span>
      </div>
      <div class="contactus-item">
        <img src="../assets/imgs/contactus/icon_mobile.png" alt="">
        <span>+86 134 8460 4813</span>
      </div>
      <div class="contactus-item">
        <img src="../assets/imgs/contactus/icon_email.png" alt="">
        <span>info@chargenow.top</span>
      </div>
    </div>
    <div class="contactus-info-msg">{{ i18n.contactusinfotitle }}</div>
    <div class="contactus-info">
      <div class="contactus-info-item">
        <div class="contactus-title">{{ i18n.method1 }}</div>
        <div class="contactus-subtitle">{{ i18n.methoddesc1 }}</div>
        <div class="contactus-phone">
          <span>+5513991830613</span>
        </div>
      </div>
      <div class="contactus-info-item">
        <div class="contactus-title">{{ i18n.method2 }}</div>
        <div class="contactus-subtitle">{{ i18n.methoddesc2 }}</div>
        <div class="contactus-phone">
          <span>+7 708 366 36 93</span>
        </div>
      </div>
    </div>
  </page>
</template>

<script>
export default {
  computed: {
    i18n () {
      return this.$t('contactus')
    }
  },
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {
    let obj = {
      'zh-CN': 'zh-CN',
      'en-US': 'en-US',
      'ru-RU': 'en-US',
      'es-ES': 'en-US',
      'fr-FR': 'en-US',
      'pt-BR': 'pt-PT',
      'ar-SA': 'en-US',
      'lt-LT': 'lt-LT',
      'et-EE': 'en-US',
      'it-IT': 'en-US'
    }
    if (this.$route.params && this.$route.params.lang) { // 适配app
      console.log(obj[this.$route.params.lang])
      this.$i18n.locale = obj[this.$route.params.lang]
      this.$store.commit('lang', obj[this.$route.params.lang])
    }
  }
}
</script>

<style lang="less" scoped>
.desc{
  padding: 0 20px 20px;
  margin: 20px;
  background: #fff;
  border-radius: 20px;
  &-item{
    margin-top: 20px;
    color: #666666;
    line-height: 1.5;
  }
}
.contactus-title{
  margin: 20px 0;
  font-size: 30px;
  font-weight: bold;
  color: #000;
}
.contactus-subtitle{
  margin: 20px 0;
  font-size: 26px;
  color: #666;
}
.contactus{
  background: #fff;
  border-radius: 20px;
  margin: 20px;
  padding: 0px 20px 20px;
  color: #666666;
  &-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    font-size: 30px;
    color: #666666;
    img{
      width: 50px;
      margin-right: 40px;
    }
  }
}
.contactus-info-msg {
  margin: 0 30px;
  color: #00AB46;
}
.contactus-info{
  background: #fff;
  border-radius: 20px;
  margin: 20px;
  padding: 0px 30px 40px;
  color: #666;
  &-item{
    margin-top: 40px;
  }
}
</style>
