<template>
  <page title="Package Order">

    <template v-if="orderList && orderList.length > 0">
      <div class="vcard scan-item" v-for="item in orderList" :key="item.orderId">
        <div>
          <OrderItem :src="item.flagUrl" :text="item.text" :currencyName="item.currencyName" :price="item.totalAmount" :deposit="Number(item.depositAmount)" >
          </OrderItem>
        </div>
        <van-cell :title="i18n.orderNo" :value="item.orderId" />
        <van-cell :title="i18n.startingTime" :value="item.reserverStartTime" />
        <van-cell :title="i18n.endTime" :value="item.reserverEndTime" />
        <van-cell :title="i18n.packageStatus" :value="item.packageStatusTitle" />
        <div class="p-20 flex-s" v-if="!item.productSendSn && (item.deviceOrderStatus == 5 || item.deviceOrderStatus == 0) && item.packageStatus !== 33">
          <van-button  type="primary" round style="width:100%;margin-right: 10px;" @click="onPickUp(item)">{{i18n.scanQR}}</van-button>
          <van-button v-if="item.deviceOrderStatus !== 0" plain type="primary" round style="width:100%;" @click="QRpickup(item)">{{i18n.QRpickup}}</van-button>
        </div>
        <div class="p-20 flex-s" v-if="item.packageStatus === 33">
          <van-button  type="primary" round style="width:100%;margin-right: 10px;" @click="goPay(item)">{{i18n.continuepay}}</van-button>
        </div>
        <div class="p-v-20" v-if="item.packageStatus === 2 || item.packageStatus === 9">
          <van-button plain type="primary" round style="width:100%;" @click="viewFlowDetail(item)">{{i18n.viewflowdetail}}</van-button>
        </div>
      </div>
    </template>
    <result-msg v-else @click="onReload"/>
    <div class="fixed" v-if="isPickUpShow" @click="isPickUpShow = false">
      <ScanQr @scanQr="onScanQr" />
      <!-- <div class="round" @click.stop="e => {}">
        <van-field v-model="sn" clearable input-align="left" label="SN" :placeholder="i18n.inputserialNumber" />
        <div class="btn confirmbtn" @click="pickUpMifi">{{ i18nPickUp.confirmationOfPickup }}</div>
      </div> -->
    </div>
  <van-dialog v-model="drawerVisible" :title="i18n.QRpickup"  lazy-render :show-confirm-button="false" :show-cancel-button="false">
      <van-icon name="cross" class="cross-btn" @click="drawerVisible = false"/>
      <div class="flex-c">
        <div class="loading-c" >
          <van-loading size="100px" v-if="loading" vertical>{{i18n.取机中}}...</van-loading>
        </div>
        <vue-qr :style="loading?'opacity: 0.1;':''"  :logoSrc="imageUrl" :text="text" :size="200"></vue-qr>
      </div>
  </van-dialog>
  <van-popup v-model="useAgeDataShow" position="bottom">
    <div>
      <div style="display: flex;justify-content: space-between;padding: 10px;">
        <div>{{ $t('packageDetail.usedTraffic') }}:{{ useAgeData.packageUsedTotal }} MB</div>
        <div>
          <span style="font-size: 16px;color:#000000;">sn: </span>
          <span style="font-size: 14px;color: #969799;">{{ useAgeData.sn }}</span>
        </div>
      </div>
      <van-empty v-if="!useAgeData.packageUsageDtlList" :description="$t('msg.nodata')" />
      <van-cell-group v-else class="scrollStyle">
        <van-cell v-for="(ele, index) in useAgeData.packageUsageDtlList" :key="index">
          <template #title>
            <div>
              <p>{{ $t('scanOrder.startingTime') }}：</p>
              <p>{{ ele.startTime | formatUnix }}</p>
              <p>{{ $t('scanOrder.endTime') }}：</p>
              <p>{{ ele.endTime | formatUnix }}</p>
            </div>
          </template>
          <template #default>
            <div>
              <p>{{ $t('packageDetail.usedTraffic') }}</p>
              <p>{{ ele.totalUsage }} MB</p>
              <p>{{ $t('selectpackage.lengthofuse') }}</p>
              <p>{{ ele.onlineTimeDuration }}</p>
            </div>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
  </van-popup>
  </page>
</template>

<script>
// import { PopupWarp } from '../../components/popup'
import OrderItem from '../../components/orderItem/orderItem.vue'
import { MIFI_PICKUP, QR_CODE, MIFI_PACKAGEUSE } from '../../apis/cabinet'
import dayjs from 'dayjs'
import ScanQr from '../scanQr/scanQr.vue'
import ResultMsg from '../../components/result/msg.vue'
import vueQr from 'vue-qr'

export default {
  components: {
    OrderItem,
    ResultMsg,
    ScanQr,
    vueQr
  },
  computed: {
    i18n () {
      return this.$t('scanOrder')
    },
    i18nMsg () {
      return this.$t('msg')
    },
    i18nPickUp () {
      return this.$t('pickUp')
    }
  },
  filters: {
    formatUnix (val) {
      if (val) {
        return dayjs(val * 1000).format('YYYY-MM-DD HH:mm:ss') // dayjs是按毫秒级解析，接口传的值是秒级，要乘1000转为毫秒级
      }
      return ''
    }
  },
  data () {
    return {
      orderList: [],
      typeMap: {},
      isPickUpShow: false,
      sn: '',
      pickData: null,
      text: '',
      drawerVisible: false,
      imageUrl: '', // icon路径
      loading: false,
      useAgeDataShow: false,
      useAgeData: {}
    }
  },
  watch: {
    drawerVisible (val) {
      if (!val) {
        clearInterval(this.timer)
      }
    }
  },
  methods: {
    onReload () {
      this.getOrderList()
    },
    onPickUp (item) {
      console.log('取机', item)
      this.pickData = item
      this.isPickUpShow = true
      // this.onScanQr('https://wifi.gzz8j.com/#/?qr=1695522536')
      // this.onScanQr('https://saas.dtoolsz.com/#/?qrcode=1697689168')
    },
    onScanQr (qr) {
      console.log('qr====', qr)
      this.isPickUpShow = false
      this.onScan(qr)
      // let arr = []
      // if (qr.indexOf('?') !== -1) {
      //   arr = qr.split('=')
      // } else {
      //   arr = qr.split('/')
      // }
      // this.pickUpMifi(arr[arr.length - 1])
    },
    onScan (urlcode) {
      this.$loading(true)
      this.$post(
        `${QR_CODE}?url=${encodeURIComponent(urlcode)}&mifiOrderId=${this.pickData.orderId}`,
        {},
        resp => {
          this.$loading(false)
          console.log(resp)
          if (resp.data) { // 扫宝
            this.getOrderList()
            this.$store.commit('mificode', urlcode)
            this.$router.push('/packageDetail')
            this.$toast(this.i18n.pickupsuccess)
          } else { // 机柜码
            this.$store.commit('cabinet', {
              qrcode: resp.qrcode,
              ...resp
            })
            if (this.pickData.deviceOrderStatus === 0) { // 未交押金
              // let order = {
              //   productId: resp.productId,
              //   depositAmount: resp.mifiDeposit,
              //   currency: resp.currency,
              //   pickUpOrderId: this.pickData.pickUpOrderId,
              //   mifiOrderId: this.pickData.orderId
              // }
              // order.xugou = false
              this.$store.commit('mificode', resp.cabinetID)
              this.$store.commit('mifiPackage', this.pickData)
              this.$router.push('/selectPakage?deviceType=mifi')
            } else { // 已交押金
              this.$store.commit('mifiOrder', this.pickData)
              this.pickUpMifi(resp.qrcode)
            }
          }
        },
        error => {
          this.$loading(false)
          this.$toast(error)
        }
      )
    },
    pickUpMifi (code) {
      console.log('取机', code, this.pickData)
      this.$loading(true)
      this.$post(
        `${MIFI_PICKUP}`,
        {
          qrcode: code,
          pickOrderId: this.pickData.orderId
        },
        resp => {
          this.$loading(false)
          console.log('数据===', resp)
          this.$router.push(`/borrowSuccess?tradeNo=${resp.data.orderId}&loading=true`)
          this.$toast(this.i18n.pickupsuccess)
        },
        error => {
          this.$loading(false)
          this.$toast(error)
        }
      )
    },
    // 二维码取机
    QRpickup (item) {
      this.text = `{"source":"chargeNowMifi","orderId":${item.orderId},"payment":"chargeNowMifi","deposit":${item.depositAmount}}`
      this.drawerVisible = true
      // let num
      this.timer = setInterval(() => {
        this.$get('/chargenow/mifi/checkPickUp', {
          mifiOrderId: item.orderId
        }, res => {
          if (res.pickUp_msg) {
            this.loading = false
            this.drawerVisible = false
            this.$confirm({
              title: this.i18nMsg.title,
              content: this.i18nMsg.pickup_fail,
              confirmText: this.i18nMsg.confirm,
              showCancel: false
            }).then(() => {
              this.getOrderList()
            })
          } else if (res.status === 2) {
            this.$toast(this.i18n.pickupsuccess)
            this.loading = false
            this.getOrderList()
            this.drawerVisible = false
          } else if (res.status === 0) {
            // this.$toast('未取机')
          } else if (res.status === 1) {
            this.loading = true
          } else {
            this.getOrderList()
            this.loading = false
            this.drawerVisible = false
          }
        })
      }, 2000)
      // if ((time + 10000) < new Date().getTime()) {
      //   clearInterval(this.timer)
      // }
    },
    getOrderList () {
      this.$loading(true)
      this.$post('/chargenow/mifi/orderList', {
        current: 1,
        size: 100
      }, res => {
        this.$loading(false)
        let data = res.data.records
        data = data ? data.map(item => {
          item.Day = item.packageName.split('-')[2].includes('Days') ? (item.packageName.split('-')[2]).split('Days')[0] : 1
          if (item.packageName.split('-')[2].includes('Days')) {
            item.text = `${item.country}${item.packageName.split('-')[1]}/${(item.packageName.split('-')[2]).split('Days')[0]}${this.i18n.days}`
            item.isPackage = 1
          } else if (item.packageName.split('-')[2].includes('Unlimited')) {
            item.text = `${item.country}${this.i18n.无限流量}/${item.Day}${this.i18n.天}`
            item.isPackage = 0
          } else {
            item.text = `${item.country}${item.packageName.split('-')[2]}MB/${this.i18n.天}`
            item.isPackage = 0
          }
          item.packageStatusTitle = this.typeMap[item.packageStatus]
          return item
        }) : []
        // this.orderList.push(...data)
        this.orderList = data
      },
      error => {
        this.$loading(false)
        console.log(error)
      }
      )
    },
    goPay (item) {
      console.log('继续支付')
      this.$router.push({ path: '/confirm', query: { price: item.totalAmount, payType: 'mifi', mifiOrderId: item.orderId } })
    },
    viewFlowDetail (item) {
      this.$loading(true)
      this.$post(
        `${MIFI_PACKAGEUSE}`,
        {
          sn: 'srq111440odsui87',
          packageStartTime: dayjs('2024-09-16 13:36:17').unix(),
          packageEndTime: dayjs('2024-09-17 13:36:17').unix()
        },
        resp => {
          this.$loading(false)
          console.log('数据===', resp)
          this.useAgeData = resp.data
          this.useAgeDataShow = true
        },
        error => {
          this.$loading(false)
          this.$toast(error)
        },
        {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          }
        }
      )
    }
  },
  created () {
    this.getOrderList()
    this.typeMap = {
      0: this.i18n.type_0,
      1: this.i18n.type_1,
      2: this.i18n.type_2,
      3: this.i18n.type_3,
      4: this.i18n.type_4,
      9: this.i18n.type_9,
      33: this.i18n.type_33,
      88: this.i18n.type_88
    }
  }
}
</script>

<style lang="less" scoped>
.fixed{
  position: fixed;
  bottom: 0;
  width: 100%;
  // background: rgba(45, 45, 45, 0.5);
  height: 100%;
}
.scan-item{
  margin-bottom: 20px;
  padding: 10px;
}
.p-20{
  padding: 0 20px;
}
.p-v-20{
  padding: 20px ;
}
.round{
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 40px 0 20px;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #fff;
  .confirmbtn{
    margin: 100px 20px 30px;
  }
}
.loading-c{
  position: absolute;
}
</style>
