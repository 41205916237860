<template>
  <page :title="i18n.title">
    <div class="confirm-warp">
      <div class="vcard flex-s pay-amount">
        <div class="pay-amount-title">{{ i18nPay.payamount }}</div>
        <div style="color: #00ab46" class="flex-c">
          <span class="doller">{{ currency }}</span>
          <span class="pay-price">{{ priceFormat(price) }}</span>
        </div>
      </div>
      <div class="pay-h1">{{ i18nPay.choosepaymethod }}</div>
      <div class="confirm-payment">
        <template
          v-if="payType === 'YJ' || payType === 'FK' || payType === 'mifi'"
        >
          <div
            v-for="(item, i) in cardList"
            :key="i"
            class="confirm-payment-item"
            @click="onPayMode(item, true)"
          >
            <div class="confirm-payment-item-info">
              <img :src="require('../../assets/imgs/icon_creditcard.png')" />
              <div class="content">
                <div class="title">{{ item.pCardnumber }}</div>
              </div>
            </div>
            <div class="confirm-payment-item-checked 1">
              <img
                v-if="
                  payMode.pId === item.pId &&
                  item.currencyName === payMode.currencyName
                "
                src="../../assets/imgs/checkbox_selected.png"
              />
              <img v-else src="../../assets/imgs/checkbox.png" />
            </div>
          </div>
        </template>
        <template v-for="item in payModeList">
          <div
            v-if="
              item.phone === 'all' ||
              item.phone === system ||
              item.phone === browser ||
              (item.phone === 'phone' && system !== '')
            "
            :key="item.pId"
            class="confirm-payment-item"
            @click="onPayMode(item)"
          >
            <div class="confirm-payment-item-info">
              <img
                :src="
                  item.pPaymentType === 'GOOGLE_PAY' ||
                  item.pPaymentType === 'STRIPE_GOOGLE'
                    ? require('../../assets/imgs/icon_googlepay.png')
                    : item.pPaymentType === 'APPLE_PAY' ||
                      item.pPaymentType === 'STRIPE_APPLE'
                    ? require('../../assets/imgs/icon_applepay.png')
                    : require('../../assets/imgs/icon_creditcard.png')
                "
              />
              <div class="content">
                <div class="title">
                  {{
                    $t("confirm." + item.label).includes("confirm.")
                      ? item.label
                      : $t("confirm." + item.label)
                  }}
                </div>
                <!-- <div v-if="item.pPaymentType === 'STRIPE_CARD'" class="sub_title">{{ i18n.auth_label }}</div> -->
                <div
                  v-if="item.pPaymentType === 'STRIPE_CARD'"
                  class="sub_title"
                >
                  {{ system == "ios" ? "Apple Pay" : "Google Pay" }}
                </div>
              </div>
            </div>
            <div class="confirm-payment-item-checked 2">
              <img
                v-if="
                  payMode === item.pPaymentType &&
                  item.currencySymbol === currencySymbol
                "
                src="../../assets/imgs/checkbox_selected.png"
              />
              <img v-else src="../../assets/imgs/checkbox.png" />
            </div>
          </div>
        </template>
        <!-- <div class="confirm-payment-item" @click="onPayMode({ pPaymentType: 'mercadopago' })">
          <div class="confirm-payment-item-info">
            <div class="content">
              <div class="title">mercadopago</div>
            </div>
          </div>
          <div class="confirm-payment-item-checked">
            <img v-if="payMode === 'mercadopago'" src="../../assets/imgs/checkbox_selected.png" />
            <img v-else src="../../assets/imgs/checkbox.png" />
          </div>
        </div> -->
        <!-- <div class="confirm-payment-item" v-if="mificode" @click="goWallet">
          <div class="confirm-payment-item-info">
            <div class="content">
              <div class="title">{{ i18nButton.添加新卡 }}</div>
            </div>
          </div>
          <div class="confirm-payment-item-checked">
            <img src="../../assets/imgs/icon_more.png" />
          </div>
        </div> -->
      </div>
      <div class="confirm-btns">
        <div class="btn" @click="onPay" :disabled="disabled">
          {{ i18n.submit }}
        </div>
      </div>
      <popup-warp v-model="isCreditcard">
        <div>
          <div class="creditcard-input">
            <div id="credit-card-element"></div>
          </div>
          <div class="btn creditcard-btn" @click="onStripeSetup">
            {{ i18n.confirmpay }}
          </div>
        </div>
      </popup-warp>
      <popup-warp v-model="isDebitcard">
        <div>
          <div class="creditcard-input">
            <div id="debit-card-element"></div>
          </div>
          <div class="btn creditcard-btn" @click="onStripePay">
            {{ i18n.confirmpay }}
          </div>
        </div>
      </popup-warp>
      <popup-warp v-model="isIdael">
        <div class="p-2">
          <div id="link-authentication-element"></div>
          <div id="payment-element"></div>
          <div class="btn creditcard-btn" @click="onIdaelPay">
            {{ i18n.confirmpay }}
          </div>
        </div>
      </popup-warp>
      <popup-warp v-model="mpesaPhoneShow">
        <div class="confirm-row">
          <div class="area" @click="isAreaView = true">
            <span>+{{ areaCode }}</span>
            <div class="area-down"></div>
          </div>
          <input
            type="number"
            v-model="phone"
            :placeholder="$t('login.phone_number')"
          />
        </div>
        <div class="btn creditcard-btn" @click="onSMSPay">
          {{ i18n.confirmpay }}
        </div>
      </popup-warp>
      <popup-warp v-model="isDopago">
        <div>
          <div class="creditcard-input">
            <form id="form-checkout">
              <div id="form-checkout__cardNumber" class="container"></div>
              <div id="form-checkout__expirationDate" class="container"></div>
              <div id="form-checkout__securityCode" class="container"></div>
              <input type="text" id="form-checkout__cardholderName" />
              <select id="form-checkout__issuer"></select>
              <select id="form-checkout__installments"></select>
              <select id="form-checkout__identificationType"></select>
              <input type="text" id="form-checkout__identificationNumber" />
              <input type="email" id="form-checkout__cardholderEmail" />

              <button type="submit" id="form-checkout__submit">Pay</button>
              <progress value="0" class="progress-bar">Loading...</progress>
            </form>
          </div>
          <!-- <div class="btn creditcard-btn" @click="onStripeSetup">{{ i18n.confirmpay }}</div> -->
        </div>
      </popup-warp>
    </div>
    <select-area v-model="isAreaView" @select="onSelct"></select-area>
    <flutterwave-pay
      @onPayment="onFlutterwavePay"
      :dialogPopup="flutterwaveDialog"
    ></flutterwave-pay>
  </page>
</template>

<script>
import { loadMercadoPago } from "@mercadopago/sdk-js"
import { mapState } from "vuex"
import { SelectArea } from "../../components/select"
import { getToken } from "../../utils/stripe_pay.js"
import {
  STRIPE_PREPAY_SPTOKEN,
  STRIPE_PAYORDER_SPTOKEN,
  PAYPAL_PRE_PAY,
  PAYPAL_RECHARGE,
  STRIPE_PAY_OF_CONFIRM,
  STRIPE_RECHARGE_OF_CONFIRM,
  STRIPE_PAY_CONFIRM,
  STRIPE_PREAUTH_OF_CONFIRM,
  STRIPE_PREAUTH_AUTO_CONFIRM,
  PAYMODE,
  WECHAT_MP_PRE_PAY,
  STRIPE_PAYORDER,
  STRIPE_RECHARGE_WALLET,
  MPESA_DEPOSIT_RENT,
  MPESA_RECHARGE,
  MPESA_PAYORDER,
  MPESA_PAYCONFIRM,
  MIFI_PREAUTHPAY,
  MIFI_PREAUTHCONFIRM,
  MIFI_RENT,
  MIFI_WAKEUPPAY,
  STRIPE_CARD_PAY,
  MERCADO_PREAUTH,
  MERCADO_RECHARGE,
  MERCADO_PAYORDER,
  MERCADO_PAYCONFIRM,
  STRIPE_SAVE_CARD,
  STRIPE_SAVE_CARD_CONFIRM,
  SAVED_CARD_TO_RENT,
  FLUTTERWAVE_RECHARGE,
  FLUTTERWAVE_PAYCONFIRM,
  SUMUP_PAYORDER,
  SUMUP_SAVECARDOFINTENT,
  ESPEES_RECHARGE,
  ESPEES_PAYORDER,
  INVOICE_BUYORDER
} from "../../apis/payment"
import { USER_CARD } from "../../apis/user.js"
import { ORDER_TEMP } from "../../apis/cabinet"
import stripeMixin from "../../mixins/stripe"
import { PopupWarp } from "../../components/popup"
import FlutterwavePay from "../../components/confirm/flutterwavePay"
let mp = null
let cardForm = null
// async function MercadoPagoFun () {
//   await loadMercadoPago()
// }
// MercadoPagoFun()
export default {
  mixins: [stripeMixin],
  components: {
    PopupWarp,
    SelectArea,
    FlutterwavePay
  },
  computed: {
    ...mapState([
      "system",
      "browser",
      "cabinet",
      "user",
      "qrcode",
      "wallet",
      "mifiOrder",
      "mificode",
      "mifisn",
      "agentOpenId"
    ]),
    i18n() {
      return this.$t("confirm")
    },
    i18nMsg() {
      return this.$t("msg")
    },
    i18nPay() {
      return this.$t("paymethod")
    },
    i18nButton() {
      return this.$t("button")
    },
    currency() {
      if (this.cabinet) {
        return this.cabinet.currencyName
      } else if (this.mifiOrder) {
        return this.mifiOrder.currencyName
      } else {
        return this.currencySymbol || this.user.pcurrencyName
      }
    },
    params() {
      let datas = {
        qrcode: this.mificode || this.qrcode,
        paymentId: this.pId,
        ejectOrNot: this.mifiOrder && this.mifiOrder.xugou ? 0 : 1,
        orderSubmit: {
          orderType: 0,
          currency: this.mifiOrder.currency,
          totalAmount: this.price,
          orderRemark: "设备+套餐",
          submitDtlDtoList: [
            {
              remark: ""
            }
          ]
        }
      }
      if (this.mifiOrder.productSendSn) {
        datas.orderSubmit.orderType = 1
        datas.orderSubmit.orderRemark = "续费套餐"
        datas.orderSubmit.submitDtlDtoList[0].productSendSn =
          this.mifiOrder.productSendSn
      } else if (this.mifiOrder.productId && !this.mifiOrder.skuId) {
        datas.orderSubmit.orderType = 3
        datas.orderSubmit.orderRemark = "仅设备"
      } else if (!this.mifiOrder.productId && this.mifiOrder.skuId) {
        datas.orderSubmit.orderType = 2
        datas.orderSubmit.orderRemark = "仅套餐"
      }
      if (this.mifiOrder && this.mifiOrder.productId) {
        datas.orderSubmit.submitDtlDtoList[0].productId =
          this.mifiOrder.productId
        datas.orderSubmit.submitDtlDtoList[0].productAmount = 0
        datas.orderSubmit.submitDtlDtoList[0].depositAmount =
          this.mifiOrder.depositAmount
      }
      if (this.mifiOrder && this.mifiOrder.skuId) {
        datas.orderSubmit.submitDtlDtoList[0].skuId = this.mifiOrder.skuId
        datas.orderSubmit.submitDtlDtoList[0].skuPriceId = this.mifiOrder.id
        datas.orderSubmit.submitDtlDtoList[0].packageName =
          this.mifiOrder.packageName
        datas.orderSubmit.submitDtlDtoList[0].packagePrice =
          this.mifiOrder.price
        datas.orderSubmit.submitDtlDtoList[0].reserverStartTime =
          this.mifiOrder.startTime
        datas.orderSubmit.submitDtlDtoList[0].reserverEndTime =
          this.mifiOrder.endTime
        datas.orderSubmit.submitDtlDtoList[0].timeZoneList = JSON.parse(
          this.mifiOrder.countryTimeZoneList[0].timeZone
        )
      }
      if (this.mifiOrder && this.mifiOrder.pickUpOrderId) {
        datas.orderSubmit.submitDtlDtoList[0].pickUpOrderId =
          this.mifiOrder.pickUpOrderId
      }
      if (this.mifiOrder && this.mifiOrder.mifiOrderId) {
        datas.orderSubmit.submitDtlDtoList[0].mifiOrderId =
          this.mifiOrder.mifiOrderId
      }
      return datas
    }
  },
  watch: {
    payMode(val, oldVal) {
      console.log("payMode", val, typeof val)
      if (
        val === "APPLE_PAY" ||
        val === "STRIPE_APPLE" ||
        val === "STRIPE_GOOGLE" ||
        val === "GOOGLE_PAY"
      ) {
        if (!this.stripePayRequest) {
          this.disabled = true
          this.$loading(true)
          this.getStripeToken()
        } else {
          this.disabled = false
        }
      } else {
        this.disabled = false
      }
    },
    stripePayRequest(val, oldVal) {
      this.disabled = !val
    }
  },
  data() {
    return {
      areaCode: "86",
      price: 0,
      payType: "YJ",
      formId: "",
      cabinetID: "",
      yaJinType: null,
      pdailiId: "",
      payMode: "STRIPE_CARD",
      pId: "", // 代理或平台支付配置id
      currencySymbol: "$",
      country: "US", // 国家
      currencyname: "usd", // 货币类型
      publicKey: "", // 支付通道的公钥
      disabled: false,
      stripePayRequest: null,
      isCreditcard: false,
      cardStyle: {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      },
      creditcard: null,
      clientSecret: null,
      debitOrder: null,
      isDebitcard: false,
      isIdael: false,
      mpesaPhoneShow: false,
      isAreaView: false,
      isDopago: false,
      debitcard: null,
      payModeList: [],
      cardList: [],
      orderId: "",
      mifiOrderId: "",
      phone: "",
      emailAddress: "",
      confirmType: "preAuth",
      round: 5,
      flutterwaveDialog: false
    }
  },
  methods: {
    priceFormat(val) {
      if (val) {
        return val % 1 === 0 ? val : val.toFixed(2)
      }
      return val
    },
    filterPay() {
      if (this.browser === "wechat") {
        return ["STRIPE_WECHAT", "WeChat_MP"]
      } else {
        return this.payModeList.map((item) => item.pPaymentType)
      }
    },
    goWallet() {
      let sn = this.mifisn || (this.mifiOrder && this.mifiOrder.productSendSn)
      if (this.payType === "mifi") {
        if (this.mificode.indexOf("http") !== -1) {
          this.$router.push(
            `/wallet?mificode=${encodeURIComponent(this.mificode)}&type=mifi`
          )
        } else {
          this.$router.push(`/wallet?sn=${sn}`)
        }
      } else {
        this.$router.push(`/wallet?qrcode=${this.mificode}&type=cdb`)
      }
    },
    onSelct(val) {
      this.areaCode = val
    },
    goRouter(path) {
      this.$router.push(path)
    },
    // 支付方式选中
    onPayMode(v, bool = false) {
      console.log("支付方式===", v)
      if (bool) {
        this.payMode = v
      } else {
        this.pId = v.pId
        this.payMode = v.pPaymentType
        this.currencySymbol = v.currencySymbol
        this.currencyname = v.pCurrencyType
        this.publicKey = v.publicKey
        this.country = v.configDetails.accountCountry
        if (this.payMode.includes("STRIPE_CARD")) {
          this.loadStripeScript(this.publicKey)
        }
      }
    },
    getStripeToken() {
      getToken(this.price, "MY", this.currencyname.toLowerCase(), this.onStripe)
        .then((data) => {
          console.log("stripe getToken", data)
          this.stripePayRequest = data
          this.$loading(false)
        })
        .catch((error) => {
          this.stripePayRequest = null
          console.log("stripe getTokenerr", error)
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18n.msg_fail_support,
            showCancel: false,
            confirmText: this.i18n.msg_fail_confirm
          })
        })
    },
    // apple和Google支付时调用
    onStripe(token, ev) {
      let url = ""
      let datas = {}
      let headers = {}
      if (this.cabinet && this.payType === "YJ") {
        url = STRIPE_PREPAY_SPTOKEN
        datas = {
          qrcode: this.cabinet.qrcode,
          stripeToken: token, // stripe官方支付的一次性token
          paymentId: this.pId // 代理或平台支付配置id
        }
      } else if (this.payType === "mifi") {
        url = MIFI_PREAUTHPAY
        datas = this.params
        headers = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8"
          }
        }
        this.confirmType = "preAuth"
      } else if (this.price > 0 && this.payType === "FK") {
        url = STRIPE_PAYORDER_SPTOKEN
        datas = {
          outTradeNo: this.orderId,
          couponNo: null,
          stripeToken: token,
          paymentId: this.pId
        }
      } else {
        this.$confirm({
          title: this.i18nMsg.title,
          content: this.i18nMsg.error,
          confirmText: this.i18nMsg.confirm,
          showCancel: false
        }).then(() => {
          this.$router.push("/")
        })
      }
      this.$post(
        url,
        datas,
        (resp) => {
          if (ev && ev.complete) {
            ev.complete("success")
          }
          this.$loading(false)
          console.log(resp)
          if (this.payType === "CZ") {
            this.$confirm({
              title: this.i18nMsg.title,
              content: this.i18nMsg.success_payment,
              confirmText: this.i18nMsg.confirm,
              showCancel: false
            }).then(() => {
              this.$router.replace("/mine")
            })
          } else if (this.payType === "overbuy") {
            this.$router.replace(
              `/invoiceOrderDtl?orderId=${
                resp.data.buyOrderNo || resp.data.orderId
              }`
            )
          } else {
            this.$toast(this.i18nMsg.success_payment)
            if (this.payType === "FK") {
              this.$router.replace(
                `/borrowSuccess?tradeNo=${resp.data.orderId}&payMode=stripe&payType=FK`
              )
              return
            }
            if (this.payType === "mifi") {
              this.clientSecret = resp.data
              this.confirmSetup(1, "")
              // this.$router.replace(`/borrowSuccess?tradeNo=${resp.data.orderId}&loading=${this.mifiOrder && !this.mifiOrder.xugou}`)
              return
            }
            this.$router.replace(
              `/borrowSuccess?tradeNo=${resp.data.orderId}&payMode=stripe`
            )
          }
        },
        (error) => {
          if (ev && ev.complete) {
            ev.complete("fail")
          }
          this.$loading(false)
          console.log(error)
          this.$confirm({
            title: this.i18nMsg.title,
            content: this.i18nMsg.error,
            confirmText: this.i18nMsg.confirm,
            showCancel: false
          })
        },
        headers
      )
    },
    onPay() {
      console.log("onPay ~ payMode:", this.payMode)
      if (this.payMode === "paypal") {
        this.onPaypal()
      } else if (this.payMode === "Flutterwave_Mobile") {
        this.onFlutterwavePay({ show: true })
      } else if (
        this.payMode === "APPLE_PAY" ||
        this.payMode === "STRIPE_APPLE" ||
        this.payMode === "STRIPE_GOOGLE" ||
        this.payMode === "GOOGLE_PAY"
      ) {
        this.stripePayRequest.show()
      } else if (this.payMode === "STRIPE_CARD") {
        if (this.$route.query.mifiOrderId) {
          this.wakeUpPayment()
        } else if (this.payType === "mifi") {
          this.onMifiCreditcard()
        } else {
          this.onCreditcard()
        }
      } else if (
        this.payMode === "STRIPE_IDAEL" ||
        this.payMode === "STRIPE_IDEAL"
      ) {
        this.stripeRechargeWallet()
      } else if (this.payMode === "debitcard") {
        this.onDebitcard()
      } else if (
        this.payMode === "STRIPE_WECHAT" ||
        this.payMode === "WeChat_MP"
      ) {
        this.onWechatPay()
      } else if (this.payMode === "M-PESA") {
        console.log(this.cabinet)
        this.mpesaPhoneShow = true
        if (this.user && this.user.pmobile) {
          this.phone = this.user.pmobile.split(this.user.alUserId)[1]
        }
      } else if (this.payMode === "MercadoPago") {
        console.log("mercadopago支付", this.payMode)
        this.onMercadopago()
      } else if (typeof this.payMode === "object") {
        console.log("卡支付", this.payMode)
        if (this.payType === "FK") {
          this.onCreditcard()
        } else if (this.payType === "mifi") {
          this.onMifiCreditcard()
        } else {
          this.stripeCardPay()
        }
      } else if (this.payMode === "SumUpOnlinePayment") {
        this.onSumUpPay()
      } else if (this.payMode === "Espees") {
        this.onEspeesPay()
      } else {
        this.$loading(true)
        setTimeout(() => {
          this.$loading(false)
          this.$router.replace("/borrowSuccess?loading=false")
        }, 3000)
      }
    },
    // Espees支付
    onEspeesPay() {
      const datas = {
        source: "H5",
        // amount: this.price,
        paymentId: this.pId,
        rechargeType: "balance", // 余额充值
        qrcode: this.qrcode || this.$route.query.cabinetID
      }
      let url = ESPEES_RECHARGE
      if (this.payType === "CZ") {
        datas.amount = this.price
        delete datas.qrcode
      } else if (this.payType === "FK") {
        url = ESPEES_PAYORDER
        datas.outTradeNo = this.orderId
      } else {
        datas.rentNow = true
      }
      const headers = {
        headers: { "Content-Type": "application/json;charset=UTF-8" }
      }
      this.$loading(true)
      this.$Request("post", url, datas, headers)
        .then(({ data = {} }) => {
          if (data.paymentLink) {
            // 跳转到支付确认页面
            window.location.href = data.paymentLink
          } else if (data.rentOrderId) {
            this.goEspeesConfirm(data.rentOrderId)
          }
        })
        .catch((error) => {
          this.$loading(false)
          console.log(error)
          this.$toast(error)
        })
    },
    // Espees支付确认
    goEspeesConfirm(tradeNo) {
      const redirectUri = `/borrowSuccess?tradeNo=${tradeNo}&payType=${this.payType}`
      this.$router.replace(redirectUri)
    },
    // 获取checkoutId跳转SumUp获取保存支付卡信息
    saveCardSumUpPay() {
      // 支付完成回调 codeUrl
      const redirectUri = `#/borrowSuccess?paymentId=${this.pId}&payMode=SumUpOnlinePayment&payType=${this.payType}`
      const datas = {
        paymentId: this.pId,
        amount: this.price,
        redirectUri: redirectUri,
        qrcode: this.qrcode || this.$route.query.cabinetID
      }
      const headers = {
        headers: { "Content-Type": "application/json;charset=UTF-8" }
      }
      this.$Request("post", SUMUP_SAVECARDOFINTENT, datas, headers)
        .then((resp) => {
          this.$loading(false)
          if (resp.data && resp.data.checkoutId) {
            // 跳转到支付拉起页面
            const _payUri = `${process.env.VUE_APP_Domain}/sumupPay/sumup.html`
            window.location.href = `${_payUri}?tradeNo=${resp.data.checkoutId}&redirectUri=/`
          }
        })
        .catch((error) => {
          this.$loading(false)
          console.log(error)
          this.$toast(error)
        })
    },
    // sumUp 支付
    async onSumUpPay() {
      this.$loading(true)
      let datas = {}
      if (this.paymentConfig && this.paymentConfig.pId) {
        let method = ""
        let url = ""
        if (this.payType === "YJ") {
          method = "get"
          url = SAVED_CARD_TO_RENT
          datas = {
            qrcode: this.qrcode || this.$route.query.cabinetID
          }
        } else {
          method = "post"
          url = SUMUP_PAYORDER
          datas = {
            paymentId: this.paymentConfig.pId,
            outTradeNo: this.orderId,
            userCardId: this.pId
          }
        }
        let headers = {}
        if (method === "post") {
          headers.headers = { "Content-Type": "application/json;charset=UTF-8" }
        }
        this.$Request(method, url, datas, headers)
          .then((resp) => {
            this.$loading(false)
            const res = resp.data
            const redirectUri = `/borrowSuccess?tradeNo=${res.out_trade_no}&payMode=${this.payMode}&payType=${this.payType}`
            this.$router.replace(redirectUri)
          })
          .catch((error) => {
            this.$loading(false)
            console.log(error)
            this.$toast(error)
          })
      } else {
        return this.saveCardSumUpPay()
      }
    },
    // 喀麦隆支付
    onFlutterwavePay(param = {}) {
      if (this.payType !== "FK") {
        this.flutterwaveDialog = param.show
        if (param.show) return false
      }
      let url = FLUTTERWAVE_RECHARGE
      let query = {
        saveCardFlag: true,
        paymentId: this.pId,
        rechargeType: "balance",
        // email: param.email,
        phoneNumber: param.areaCode + param.phone
      }
      if (this.payType === "CZ") {
        query.amount = this.price
      } else {
        query.qrcode = this.cabinetID
      }
      console.log(`flutterwave-pay-param:${param}-query:${query}`)
      // 成功
      const onsuccess = (resp) => {
        if (resp.data) {
          let auth = resp.data.meta.authorization
          if (auth.mode === "redirecturl") {
            window.location.href = auth.redirect_url
          } else if (auth.mode === "callback") {
            // this.onFlutterwavConfirm(resp.data)
            const _delayTimer = setTimeout(() => {
              clearTimeout(_delayTimer)
              const _cBtn = document.querySelectorAll("#modal-confirm-btns")
              _cBtn.length && _cBtn[0].click()
            }, 20000)
            this.$confirm({
              content: this.i18n.SMSConfirm,
              cancelText: this.i18n.msg_fail_cancel,
              confirmText: this.i18n.msg_fail_confirm
            })
              .then(() => {
                this.onFlutterwavConfirm(resp.data)
              })
              .catch(() => clearTimeout(_delayTimer))
          }
        }
      }
      // 失败
      const onfail = (error) => {
        console.warn(error)
        this.$loading(false)
        this.$confirm({
          title: this.i18n.msg_fail_title,
          content: this.i18n.msg_fail_order,
          confirmText: this.i18n.msg_fail_retry,
          cancelText: this.i18n.msg_fail_cancel
        }).then(() => {
          this.onFlutterwavePay()
        })
      }
      const headers = {
        headers: { "Content-Type": "application/json;charset=UTF-8" }
      }
      this.$loading(true)
      this.$post(
        url,
        query,
        (resp) => onsuccess(resp),
        (error) => onfail(error),
        headers
      )
    },
    /**
     * Flutterwave - confirm
     * @param param outTradeNo trade_no
     */
    onFlutterwavConfirm(param = {}) {
      let query = {
        tradeNo: param.trade_no,
        outTradeNo: param.outTradeNo
      }
      console.log(`flutterwave-confirm-param:`, param, "-query:", query)
      // 成功
      const onsuccess = (resp) => {
        if (resp.data && resp.data.orderStatus === 0) {
          if (!this.flutterwavPollCount) this.flutterwavPollCount = 0
          if (this.flutterwavPollCount < 9) {
            this.flutterwavPollCount++
            setTimeout(() => this.onFlutterwavConfirm(param), 1000)
          } else {
            onfail()
            this.flutterwavPollCount = 0
          }
        } else {
          this.$loading(false)
          let jumpPath = `/borrowSuccess?tradeNo=${resp.data.orderId}&payMode=flutterwave`
          if (resp.data && resp.data.type === "CZ") {
            jumpPath = `/wallet`
          }
          this.$router.replace(jumpPath)
        }
      }
      // 失败
      const onfail = (error) => {
        console.warn(error)
        this.$loading(false)
        this.$confirm({
          content: this.i18n.msg_fail_order,
          confirmText: this.i18n.msg_fail_retry,
          cancelText: this.i18n.msg_fail_cancel
        }).then(() => this.onFlutterwavConfirm(param))
      }
      this.$get(
        FLUTTERWAVE_PAYCONFIRM,
        query,
        (resp) => onsuccess(resp),
        (error) => onfail(error)
      )
    },
    onPaypal() {
      this.$loading(true)
      let url = ""
      let datas = {
        amount: this.price
      }
      let headers = {}
      if (this.payType === "CZ" || this.payType === "mifi") {
        url = PAYPAL_RECHARGE
      } else if (this.payType === "overbuy") {
        url = INVOICE_BUYORDER
        datas = {
          outTradeNo: this.$route.query.orderId,
          paymentId: this.pId
        }
        headers = {
          headers: {
            "content-type": "application/json;charset=UTF-8"
          }
        }
      } else if (this.cabinet) {
        url = `${PAYPAL_PRE_PAY}/${this.cabinet.qrcode}`
      }
      this.$post(
        url,
        datas,
        (resp) => {
          this.$loading(false)
          if (resp.data) {
            this.$loading(true)
            window.location.href = resp.data
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18n.msg_fail_order,
            confirmText: this.i18n.msg_fail_retry,
            cancelText: this.i18n.msg_fail_cancel
          }).then(() => {
            this.onPaypal()
          })
          console.log(error)
        },
        headers
      )
    },
    onSMSRent() {
      // 肯尼亚支付通道的
      console.log("肯尼亚支付")
      this.$loading(true)
      this.$get(
        `${MPESA_DEPOSIT_RENT}?qrcode=${this.cabinetID}`,
        {},
        (resp) => {
          this.$loading(false)
          if (this.payType === "mifi") {
            this.$router.replace(
              `/borrowSuccess?tradeNo=${resp.data.orderId}&loading=${
                this.mifiOrder && !this.mifiOrder.xugou
              }`
            )
          } else if (this.payType !== "YJ") {
            this.$router.replace(
              `/borrowSuccess?tradeNo=${resp.data.orderId}&payMode=mpesa&payType=${this.payType}`
            )
            return
          }
          this.$router.replace(
            `/borrowSuccess?tradeNo=${resp.data.orderId}&payMode=mpesa`
          )
          console.log(resp)
        },
        (error) => {
          this.$loading(false)
          console.log(error)
        }
      )
    },
    onSMSPay() {
      // 肯尼亚支付通道的
      console.log("肯尼亚支付")
      if (!this.phone) {
        this.$toast(this.i18nMsg.no_phone)
        return
      }
      let url = ""
      let datas = {}
      if (
        this.payType === "CZ" ||
        this.payType === "YJ" ||
        this.payType === "mifi"
      ) {
        url = `${MPESA_RECHARGE}`
        datas = {
          amount: this.price,
          rechargeType: this.cabinetID ? "deposit" : "balance",
          paymentId: this.pId,
          phoneNumber: this.areaCode + this.phone
        }
      } else {
        url = `${MPESA_PAYORDER}`
        datas = {
          outTradeNo: this.orderId,
          couponNo: "",
          paymentId: this.pId,
          phoneNumber: this.areaCode + this.phone
        }
      }
      this.$loading(true)
      this.$post(
        url,
        datas,
        (resp) => {
          this.$loading(false)
          this.mpesaPhoneShow = false
          console.log(resp)
          this.$confirm({
            title: this.i18nMsg.title,
            content: this.i18n.SMSConfirm,
            confirmText: this.i18nMsg.confirm,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.onSMSCheckOrder(resp.data.out_trade_no)
            // if (this.payType === 'CZ' && this.cabinetID) {
            //   this.$router.replace(`/result?tradeNo=${resp.data.out_trade_no}&payMode=mpesa&payType=${this.payType}`)
            //   return
            // }
            // this.$router.replace(`/result?tradeNo=${resp.data.out_trade_no}&payMode=mpesa`)
          })
        },
        (error) => {
          this.$loading(false)
          console.log(error)
          this.$toast(error)
        },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8"
          }
        }
      )
    },
    onSMSCheckOrder(tradeNo) {
      this.loading = true
      this.$get(
        `${MPESA_PAYCONFIRM}?outTradeNo=${tradeNo}`,
        {},
        (resp) => {
          if (resp.data && resp.data.orderStatus !== 0) {
            if (this.payType === "mifi") {
              this.onMifiDeposit()
            } else {
              this.onSMSRent()
            }
          } else {
            this.$confirm({
              title: this.i18nMsg.title,
              content: this.$t("result.msg_fail_content"),
              confirmText: this.i18nMsg.confirm,
              cancelText: this.i18nMsg.cancel
            }).then(() => {
              this.onSMSCheckOrder(tradeNo)
            })
          }
        },
        (error) => {
          this.$confirm({
            title: this.i18nMsg.title,
            content: this.$t("result.msg_fail_content"),
            confirmText: this.i18nMsg.confirm,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.onSMSCheckOrder(tradeNo)
          })
          // if (error === '正在请求中') {
          //   setTimeout(() => {
          //     this.onSMSCheckOrder(tradeNo)
          //   }, 1000)
          // } else {
          console.log(error)
          //   this.$toast(error)
          // }
        }
      )
    },
    mercaDoPaGoPre(params) {
      let url = ""
      if (this.payType === "YJ") {
        url = MERCADO_PREAUTH
      } else if (this.payType === "CZ") {
        url = MERCADO_RECHARGE
      } else if (this.payType === "FK") {
        url = MERCADO_PAYORDER
      } else {
        this.$confirm({
          title: this.i18nMsg.title,
          content: this.i18nMsg.error,
          confirmText: this.i18nMsg.confirm,
          showCancel: false
        }).then(() => {
          this.$router.push("/")
        })
      }
      this.$loading(true)
      this.$post(
        url,
        params,
        (resp) => {
          this.$loading(false)
          console.log("mercaDoPaGo数据===", resp)
          if (resp.data) {
            this.mercaDoPaGoConfirm(resp.data.out_trade_no)
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            // content: this.i18n.msg_fail_order,
            content: error,
            confirmText: this.i18n.msg_fail_retry,
            cancelText: this.i18n.msg_fail_cancel
          }).then(() => {
            this.mercaDoPaGoPre(params)
          })
          console.log(error)
        },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8"
          }
        }
      )
    },
    mercaDoPaGoConfirm(orderId) {
      let url = MERCADO_PAYCONFIRM
      this.$loading(true)
      this.$get(
        url,
        {
          outTradeNo: orderId
        },
        (resp) => {
          this.$loading(false)
          console.log("mercaDoPaGo确认数据===", resp)
          if (resp.data) {
            if (this.payType === "FK") {
              this.$toast(this.i18nMsg.success)
              this.$router.replace(`/pastOrder`)
              return
            }
            this.$router.replace(
              `/borrowSuccess?tradeNo=${resp.data.orderId}&payMode=mercaDoPaGo&loading=true`
            )
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            // content: this.i18n.msg_fail_order,
            content: error,
            confirmText: this.i18n.msg_fail_retry,
            cancelText: this.i18n.msg_fail_cancel
          }).then(() => {
            this.mercaDoPaGoConfirm()
          })
          console.log(error)
        }
      )
    },
    async loadMercadopagoVo() {
      let that = this
      await loadMercadoPago()
      console.log("publicKey", this.publicKey)
      mp = new window.MercadoPago(this.publicKey)
      cardForm = mp.cardForm({
        amount: that.price + "",
        iframe: true,
        form: {
          id: "form-checkout",
          cardNumber: {
            id: "form-checkout__cardNumber",
            placeholder: "Card Number"
          },
          expirationDate: {
            id: "form-checkout__expirationDate",
            placeholder: "MM/YY"
          },
          securityCode: {
            id: "form-checkout__securityCode",
            placeholder: "Security Code"
          },
          cardholderName: {
            id: "form-checkout__cardholderName",
            placeholder: "Cardholder"
          },
          issuer: {
            id: "form-checkout__issuer",
            placeholder: "Issuing bank"
          },
          installments: {
            id: "form-checkout__installments",
            placeholder: "Installments"
          },
          identificationType: {
            id: "form-checkout__identificationType",
            placeholder: "Document type"
          },
          identificationNumber: {
            id: "form-checkout__identificationNumber",
            placeholder: "Document number"
          },
          cardholderEmail: {
            id: "form-checkout__cardholderEmail",
            placeholder: "Email"
          }
        },
        callbacks: {
          onFormMounted: (error) => {
            if (error) {
              return console.warn("Form Mounted handling error: ", error)
            }
            console.log("Form mounted")
          },
          onSubmit: (event) => {
            event.preventDefault()

            const {
              paymentMethodId,
              issuerId,
              cardholderEmail: email,
              amount,
              token,
              installments,
              identificationNumber,
              identificationType
            } = cardForm.getCardFormData()
            let param = {
              qrcode: this.cabinetID,
              paymentId: this.pId,
              pagoPaymentRequest: {
                transactionAmount: amount,
                token,
                installments,
                paymentMethodId,
                issuerId,
                description: "Product Description"
                // paymentPayerRequest: {
                //   email
                // }
              }
            }
            if (this.payType === "FK") {
              param.outTradeNo = this.orderId
            }
            this.mercaDoPaGoPre(param)
            console.log("MercadoPagotoken====", token)
          },
          onFetching: (resource) => {
            console.log("Fetching resource: ", resource)

            // Animate progress bar
            const progressBar = document.querySelector(".progress-bar")
            progressBar.removeAttribute("value")

            return () => {
              progressBar.setAttribute("value", "0")
            }
          }
        }
      })
    },
    onMercadopago() {
      this.isDopago = true
      mp = null
      cardForm = null
      this.loadMercadopagoVo()
    },
    // mifi继续支付
    wakeUpPayment() {
      this.$loading(true)
      let datas = {
        paymentId: this.pId,
        mifiOrderId: this.mifiOrderId
      }
      if (typeof this.payMode === "object") {
        datas = {
          cardId: this.payMode.pId,
          mifiOrderId: this.mifiOrderId
        }
      }
      this.$get(
        `${MIFI_WAKEUPPAY}`,
        datas,
        (resp) => {
          this.$loading(false)
          console.log("数据===", resp)
          if (resp.data) {
            if (window.stripeElements) {
              this.clientSecret = resp.data
              this.loadStripeScript(this.publicKey)
              this.creditcard = window.stripeElements.create("card", {
                style: this.cardStyle
              })
              this.creditcard.mount("#credit-card-element")
              this.$nextTick(() => {
                this.isCreditcard = true
              })
            } else {
              this.loadStripeScript(this.publicKey)
              this.$toast("loading...")
            }
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            // content: this.i18n.msg_fail_order,
            content: error,
            confirmText: this.i18n.msg_fail_retry,
            cancelText: this.i18n.msg_fail_cancel
          }).then(() => {
            this.wakeUpPayment()
          })
          console.log(error)
        }
      )
    },
    stripeCardPay() {
      this.$loading(true)
      this.$post(
        `${STRIPE_CARD_PAY}/${this.mificode}`,
        {
          cardId: this.payMode.pId
        },
        (resp) => {
          this.$loading(false)
          console.log("数据===", resp)
          if (resp.data && resp.data.clientSecret) {
            // 该卡需要3ds认证
            this.clientSecret = resp.data
            this.onStripeSetup(false)
          } else if (resp.data && resp.data.outTradeNo) {
            if (this.payType === "mifi") {
              this.$router.replace(
                `/borrowSuccess?tradeNo=${
                  resp.data.outTradeNo
                }&payMode=stripe&loading=${
                  this.mifiOrder && !this.mifiOrder.xugou
                }`
              )
            } else {
              this.$router.replace(
                `/borrowSuccess?tradeNo=${resp.data.outTradeNo}&payMode=stripe&loading=true`
              )
            }
          } else {
            this.$toast(resp.msg)
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            // content: this.i18n.msg_fail_order,
            content: error,
            confirmText: this.i18n.msg_fail_retry,
            cancelText: this.i18n.msg_fail_cancel
          }).then(() => {
            this.stripeCardPay()
          })
          console.log(error)
        }
      )
    },
    onMifiCreditcard() {
      let url = ""
      let datas = {}
      url = `${MIFI_PREAUTHPAY}`
      // if (this.mificode && this.mificode.indexOf('http') !== -1) {
      // } else {
      //   url = `${MIFI_PREAUTHPAY}/${this.mificode}`
      // }
      datas = {
        paymentId: this.pId,
        ...this.params
      }
      if (typeof this.payMode === "object") {
        datas = {
          cardId: this.payMode.pId,
          ...this.params
        }
        delete datas.paymentId
      }
      this.$loading(true)
      this.$post(
        url,
        datas,
        (resp) => {
          this.$loading(false)
          if (resp.data) {
            this.clientSecret = resp.data
            if (datas.cardId) {
              this.confirmSetup(resp.data.outTradeNo)
              return
            }
            if (window.stripe) {
              this.clientSecret = resp.data
              const layout = {
                layout: "tabs"
              }
              const options = {
                clientSecret: resp.data.clientSecret,
                appearance: {
                  theme: "stripe"
                }
              }
              this.creditcard = window.stripe.elements(options)
              const paymentElement = this.creditcard.create("payment", layout)
              this.isCreditcard = true
              paymentElement.mount("#credit-card-element")
            } else {
              console.log(" stripe:", window.stripe)
              this.$toast("loading...")
            }
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18n.msg_fail_order,
            confirmText: this.i18n.msg_fail_retry,
            cancelText: this.i18n.msg_fail_cancel
          }).then(() => {
            this.onCreditcard()
          })
          console.log(error)
        },
        {
          headers: {
            "content-type": "application/json;charset=UTF-8"
          }
        }
      )
    },
    onCreditcard() {
      const { paymentWay } = this.$route.query
      let paymentId = this.pId
      let headers = {}
      if (this.paymentConfig && this.paymentConfig.pId) {
        paymentId = this.paymentConfig.pId
      }

      if (paymentWay === "PAYMENT_CARD") {
        return this.saveCard()
      }
      let url = ""
      let datas = {}
      if (this.cabinet && this.payType === "YJ") {
        url = `${STRIPE_PREAUTH_OF_CONFIRM}/${this.cabinet.cabinetID}`
        datas = {
          paymentId: paymentId,
          amount: this.price
        }
        if (this.paymentConfig) {
          return this.cardToRent(this.paymentConfig)
        }
      } else if (this.price > 0 && this.payType === "FK") {
        url = STRIPE_PAYORDER
        datas = {
          outTradeNo: this.orderId,
          couponNo: null,
          paymentId: paymentId
        }
        if (this.paymentConfig) {
          datas.cardId = this.pId
        }
      } else if (this.payType === "overbuy") {
        url = INVOICE_BUYORDER
        datas = {
          outTradeNo: this.$route.query.orderId,
          paymentId: paymentId
        }
        headers = {
          headers: {
            "content-type": "application/json;charset=UTF-8"
          }
        }
      } else {
        url = STRIPE_RECHARGE_WALLET
        datas = {
          amount: this.price,
          paymentId: paymentId
        }
      }
      this.$loading(true)
      this.$post(
        url,
        datas,
        (resp) => {
          this.$loading(false)
          console.log(url, resp)
          if (resp.data) {
            if (datas.cardId && this.payType === "FK") {
              this.checkOrder(datas.outTradeNo, this.payType)
              return
            }
            if (window.stripe) {
              this.clientSecret = resp.data
              const layout = {
                layout: "tabs"
              }
              const options = {
                clientSecret: resp.data.clientSecret,
                appearance: {
                  theme: "stripe"
                }
              }
              this.creditcard = window.stripe.elements(options)
              const paymentElement = this.creditcard.create("payment", layout)
              this.isCreditcard = true
              paymentElement.mount("#credit-card-element")
            } else {
              console.log(" stripe:", window.stripe)
              this.$toast("loading...")
            }
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18n.msg_fail_order,
            confirmText: this.i18n.msg_fail_retry,
            cancelText: this.i18n.msg_fail_cancel
          }).then(() => {
            this.onCreditcard()
          })
          console.log(error)
        },
        headers
      )
    },
    onStripeSetup() {
      const vm = this
      vm.$loading(true)
      const { paymentWay } = this.$route.query
      // 失败事件回调
      const failCall = (result) => {
        console.log(result)
        vm.$loading(false)
        if (result.error) {
          vm.$toast(result.error.message)
        }
      }
      if (paymentWay === "PAYMENT_CARD") {
        let url = `${window.location.origin}#/confirm?qrcode=${this.cabinet.qrcode}&paymentId=${this.pId}&pmid=${this.creditOrder.paymentId}`
        window.stripe
          .confirmSetup({
            elements: vm.creditcard,
            confirmParams: {
              // Make sure to change this to your payment completion page
              return_url: url
            }
          })
          .then((result) => failCall(result))
      } else {
        window.stripe
          .confirmPayment({
            elements: vm.creditcard,
            confirmParams: {
              // Make sure to change this to your payment completion page
              return_url: `${window.location.href}&paymentId=${this.pId}`
            }
          })
          .then((result) => failCall(result))
      }
    },
    confirmSetup(id, success) {
      const { paymentWay } = this.$route.query
      this.$loading(true)
      let url = `${STRIPE_PREAUTH_AUTO_CONFIRM}?tradeNo=${id}`
      let datas = {}
      if (this.payType === "mifi") {
        url = MIFI_PREAUTHCONFIRM
        const clientSecret = this.clientSecret || {}
        datas = {
          tradeNo: id || "",
          outTradeNo: clientSecret.outTradeNo || "",
          isPopUp: this.mifiOrder && this.mifiOrder.xugou ? 0 : 1
        }
      }
      this.$get(
        url,
        datas,
        (resp) => {
          this.$loading(false)
          this.isCreditcard = false
          if (paymentWay === "PAYMENT_SAVED_PROPAY") {
            this.saveCard()
          }
          let jumpUrl = `/borrowSuccess?tradeNo=${resp.data.orderId}&payMode=stripe`
          if (this.payType === "mifi") {
            jumpUrl = `/borrowSuccess?tradeNo=${resp.data.orderId}&loading=${
              this.mifiOrder && !this.mifiOrder.xugou
            }`
          } else if (this.payType === "overbuy") {
            jumpUrl = `/invoiceOrderDtl?orderId=${
              resp.data.buyOrderNo || resp.data.orderId
            }`
          } else if (this.payType !== "YJ") {
            jumpUrl = `/borrowSuccess?tradeNo=${resp.data.orderId}&payMode=stripe&payType=${this.payType}`
          }
          this.$router.replace(jumpUrl)
          // // 3ds认证
          // if (resp.data && resp.data.clientSecret) {
          //   this.clientSecret = resp.data
          //   this.onStripeSetup(false)
          //   return
          // }
          // // 3ds相关结束
          // if (success && success === 'true') {
          //   this.useBalanceToRent()
          //   return
          // }
          // if (this.payType === 'mifi') {
          //   this.$router.replace(`/borrowSuccess?tradeNo=${resp.data.orderId}&loading=${this.mifiOrder && !this.mifiOrder.xugou}`)
          //   return
          // } else if (this.payType === 'CZ') {
          //   if (resp.data.orderStatus === 0 && this.round >= 0) {
          //     this.confirmSetup(id, success)
          //     this.round--
          //   } else {
          //     this.round = 5
          //     this.$router.replace(`/wallet`)
          //   }
          //   return
          // } else if (this.payType === 'FK') {
          //   setTimeout(() => {
          //     this.$toast(this.i18nMsg.success)
          //     this.$router.replace(`/pastOrder`)
          //   }, 2000)
          //   return
          //   // this.$router.replace(`/borrowSuccess?tradeNo=${resp.data.orderId}&payMode=stripe&payType=${this.payType}`)
          // }
          // this.$router.replace(`/borrowSuccess?tradeNo=${resp.data.orderId}&payMode=stripe&loading=true`)
          // console.log(resp)
        },
        (error) => {
          this.$loading(false)
          console.log(error)
        }
      )
    },
    // stripe充值余额租借
    stripeRechargeWallet() {
      this.$loading(true)
      this.$post(
        STRIPE_RECHARGE_WALLET,
        {
          amount: this.price,
          paymentId: this.pId
        },
        (resp) => {
          this.$loading(false)
          this.clientSecret = resp.data
          this.confirmType = "czOrder"
          if (resp.data) {
            if (window.stripeElements) {
              this.loadStripeScript(this.publicKey)
              this.creditcard = window.stripe.elements({
                appearance: { theme: "stripe" },
                clientSecret: resp.data.clientSecret
              })
              const linkAuthenticationElement =
                this.creditcard.create("linkAuthentication")
              linkAuthenticationElement.mount("#link-authentication-element")
              linkAuthenticationElement.on("change", (event) => {
                this.emailAddress = event.value.email
              })
              const paymentElement = this.creditcard.create("payment", {
                layout: "tabs"
              })
              paymentElement.mount("#payment-element")
              this.$nextTick(() => {
                this.isIdael = true
              })
            } else {
              this.loadStripeScript(this.publicKey)
              this.$toast("loading...")
            }
          }
          // this.confirmSetup(resp.data.tradeNo, '', 'czOrder')
          // this.$router.replace(`/borrowSuccess?tradeNo=${resp.data.orderId}&payMode=stripe`)
          console.log(resp)
        },
        (error) => {
          this.$loading(false)
          console.log(error)
        }
      )
    },
    onIdaelPay() {
      this.$loading(true)
      let vm = this
      let isparam = window.location.href.indexOf("?") !== -1
      window.stripe
        .confirmPayment({
          elements: vm.creditcard,
          confirmParams: {
            // Make sure to change this to your payment completion page
            return_url: `${window.location.href}${isparam ? "&" : "?"}orderNo=${
              this.clientSecret.outTradeNo
            }`,
            receipt_email: vm.emailAddress
          }
        })
        .then((result) => {
          console.log(result)
          vm.$loading(false)
          if (result.error) {
            vm.$toast(result.error.message)
          }
          // else {
          //   vm.confirmSetup(result.paymentIntent.id, result.paymentIntent.payment_method)
          // }
        })
    },
    onStripePay() {
      const vm = this
      vm.$loading(true)
      window.stripe
        .handleCardPayment(vm.debitOrder.clientSecret, vm.debitcard)
        .then((result) => {
          console.log("result", result)
          if (result.error) {
            vm.$loading(false)
            vm.$toast(result.error.message)
          } else {
            window.stripe
              .retrievePaymentIntent(vm.debitOrder.clientSecret)
              .then((result) => {
                console.log("init result", result)
                vm.$loading(false)
                if (result.paymentIntent) {
                  vm.confirmPay(result.paymentIntent.id)
                }
              })
          }
        })
    },
    confirmPay(id) {
      this.$loading(true)
      this.$post(
        STRIPE_PAY_CONFIRM,
        {
          paymentId: id,
          outTradeNo: this.debitOrder.outTradeNo
        },
        (resp) => {
          this.$loading(false)
          this.isCreditcard = false
          if (this.payType === "CZ") {
            this.$router.go(-2)
          } else if (this.payType === "overbuy") {
            this.$router.replace(`/invoiceOrderDtl?orderId=${resp.data}`)
          } else if (this.cabinet) {
            this.$router.replace(
              `/borrowSuccess?tradeNo=${resp.data}&payMode=stripe`
            )
          }
          console.log(resp)
        },
        (error) => {
          this.$loading(false)
          console.log(error)
        }
      )
    },
    onDebitcard() {
      this.$loading(true)
      let url = ""
      if (this.payType === "CZ") {
        url = STRIPE_RECHARGE_OF_CONFIRM
      } else if (this.cabinet) {
        url = `${STRIPE_PAY_OF_CONFIRM}/${this.cabinet.qrcode}`
      }
      this.$post(
        url,
        {
          amount: this.price
        },
        (resp) => {
          this.$loading(false)
          console.log(resp)
          if (resp.data) {
            if (window.stripeElements) {
              this.debitOrder = resp.data
              this.loadStripeScript(this.publicKey)
              this.debitcard = window.stripeElements.create("card", {
                style: this.cardStyle
              })
              this.debitcard.mount("#debit-card-element")
              this.$nextTick(() => {
                this.isDebitcard = true
              })
            } else {
              this.$toast("loading...")
            }
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18n.msg_fail_order,
            confirmText: this.i18n.msg_fail_retry,
            cancelText: this.i18n.msg_fail_cancel
          }).then(() => {
            this.onDebitcard(this.publicKey)
          })
          console.log(error)
        }
      )
    },
    onWechatPay() {
      this.$loading(true)
      const vm = this
      this.$post(
        `${WECHAT_MP_PRE_PAY}`,
        {
          amount: this.price,
          paymentId: this.pId,
          qrcode: this.cabinet.qrcode
        },
        (resp) => {
          this.$loading(false)
          console.log("wechat pre pay", resp)
          const data = resp.data
          wx.chooseWXPay({
            timestamp: data.timeStamp,
            nonceStr: data.nonceStr,
            package: data.package,
            signType: data.signType,
            paySign: data.paySign,
            success: function (res) {
              if (vm.payType === "mifi") {
                this.$router.replace(
                  `/borrowSuccess?tradeNo=${data.out_trade_no}&loading=${
                    this.mifiOrder && !this.mifiOrder.xugou
                  }&payMode=wechat`
                )
                return
              }
              vm.$router.replace(
                `/borrowSuccess?tradeNo=${data.out_trade_no}&shopid=${this.cabinet.shopid}&payMode=wechat`
              )
            },
            fail: function (res) {
              console.log("wxpay fail", res)
              vm.$toast(res.errMsg)
            }
          })
        },
        (error) => {
          console.log("wechat pre pay error", error)
          this.$loading(false)
          this.$toast(error)
        }
      )
    },
    // 押金下单
    onMifiDeposit() {
      console.log("下单===", this.mifiOrder)
      this.$loading(true)
      this.$post(
        MIFI_RENT,
        this.params,
        (resp) => {
          console.log("下单===", resp)
          this.$router.replace(
            `/borrowSuccess?tradeNo=${resp.data.orderId}&loading=${
              this.mifiOrder && !this.mifiOrder.xugou
            }`
          )
        },
        (error) => {
          this.$loading(false)
          this.$toast(error)
          console.log(error)
        }
      )
    },
    // 用户钱包余额租借
    useBalanceToRent() {
      this.$loading(true)
      this.$get(
        ORDER_TEMP,
        {
          qrcode: this.$route.query.cabinetID
        },
        (resp) => {
          this.$loading(false)
          console.log("钱包余额租借===", resp)
          this.$router.replace(
            `/borrowSuccess?tradeNo=${resp.data.outTradNo}&loading=true`
          )
        },
        (error) => {
          this.$loading(false)
          this.$toast(error)
          console.log(error)
        }
      )
    },
    getPayMode() {
      console.log("hahah", this.cabinet, this.wallet)
      let agentId = ""
      let url = `${PAYMODE}${`?id=${
        this.qrcode || this.$route.query.cabinetID
      }`}`
      if (this.payType === "CZ") {
        agentId = this.wallet ? this.wallet.pDailiId : this.agentOpenId
        url = `${PAYMODE}?agentId=${agentId}`
      } else if (this.payType === "overbuy") {
        url = `${PAYMODE}?pOrderId=${this.$route.query.orderId}`
      }
      if (this.payType === "mifi") {
        if (this.mificode && this.mificode.indexOf("http") !== -1) {
          url = `${PAYMODE}?url=${encodeURIComponent(this.mificode)}`
        } else if (this.mifiOrder && this.mifiOrder.payType === "GM") {
          url = `${PAYMODE}?currency=${this.mifiOrder.currency}`
        } else if (this.mifiOrder && this.mifiOrder.xugou) {
          url = `${PAYMODE}?sn=${this.mifiOrder.productSendSn}`
        } else {
          url = `${PAYMODE}?id=${this.mificode}`
        }
      }
      this.$loading(true)
      this.$get(
        url,
        {},
        (resp) => {
          this.$loading(false)
          console.log(resp)
          if (resp.data) {
            let list = resp.data
            list.map((v, i, arr) => {
              if (v.pPaymentType === "STRIPE_CARD") {
                v.label = "Credit Card"
                v.phone = "all"
              } else if (
                v.pPaymentType === "STRIPE_WECHAT" ||
                v.pPaymentType === "WeChat_MP"
              ) {
                v.label = "WeChat"
                v.phone = "wechat"
              } else if (
                (v.pPaymentType === "APPLE_PAY" ||
                  v.pPaymentType === "STRIPE_APPLE") &&
                this.payType !== "mifi"
              ) {
                v.label = "Apple Pay"
                v.phone = "ios"
              } else if (
                (v.pPaymentType === "GOOGLE_PAY" ||
                  v.pPaymentType === "STRIPE_GOOGLE") &&
                this.payType !== "mifi"
              ) {
                v.label = "Google Pay"
                v.phone = "android"
              } else if (v.pPaymentType === "M-PESA") {
                v.label = "SMS Payment"
                v.phone = "all"
              } else if (
                v.pPaymentType === "STRIPE_IDAEL" ||
                v.pPaymentType === "STRIPE_IDEAL"
              ) {
                v.label = "Idael Pay"
                v.phone = "all"
              } else if (v.pPaymentType === "MercadoPago") {
                v.label = "Credit Card"
                v.phone = "all"
              } else if (v.pPaymentType === "Flutterwave_Mobile") {
                v.label = "Credit Card"
                v.phone = "all"
              } else if (v.pPaymentType === "SumUpOnlinePayment") {
                v.label = "Credit Card"
                v.phone = "all"
              } else if (v.pPaymentType === "Espees") {
                v.label = "Credit Card"
                v.phone = "all"
              } else {
                v.label = "Unknow"
                v.phone = "null"
              }

              //  else if ((v.pPaymentType === 'APPLE_PAY' || v.pPaymentType === 'STRIPE_APPLE') && this.payType !== 'mifi') {
              //   v.label = 'Apple Pay'
              //   v.phone = 'ios'
              // } else if ((v.pPaymentType === 'GOOGLE_PAY' || v.pPaymentType === 'STRIPE_GOOGLE') && this.payType !== 'mifi') {
              //   v.label = 'Google Pay'
              //   v.phone = 'android'
              // }
              // list.push(v)
            })
            this.payModeList = list
            if (list.length > 0) {
              let item = list.filter((v) => {
                if (this.browser === "wechat") {
                  return (
                    v.pPaymentType === "STRIPE_WECHAT" ||
                    v.pPaymentType === "WeChat_MP"
                  )
                } else {
                  return v.pPaymentType === "STRIPE_CARD"
                }
              })
              let useCrad = list.filter((v) => {
                return v.label !== "Unknow"
              })
              useCrad = useCrad.length ? useCrad : [{ configDetails: {} }]
              this.payMode = useCrad[0].pPaymentType
              this.currencySymbol = useCrad[0].currencySymbol // 货币符号
              this.currencyname = useCrad[0].configDetails.currency // 货币类型
              this.publicKey = useCrad[0].publicKey
              this.pId = useCrad[0].pId
              this.country = useCrad[0].configDetails.accountCountry
              console.log(this.publicKey, this.payMode)
              if (this.payMode.includes("STRIPE")) {
                this.loadStripeScript(this.publicKey)
              }
            } else {
              this.disabled = true
            }
            console.log("list======", list)
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: error,
            confirmText: this.i18n.msg_fail_retry,
            cancelText: this.i18n.msg_fail_cancel
          }).then(() => {
            this.getPayMode()
          })
          console.log(error)
        },
        {
          headers: {
            agentOpenId:
              this.mifiOrder && this.mifiOrder.xugou && process.env.VUE_APP_Domain === 'https://saas.dtoolsz.com'
                ? "BJCD0000000000000000000000000001"
                : ""
          }
        }
      )
    },
    getUserCardList() {
      let url = ""
      if (this.payType === "CZ") {
        url = `${USER_CARD}?userEnd=web${
          this.walletItem ? `&agentId=${this.wallet.pDailiId}` : ``
        }`
      } else if (this.payType === "mifi") {
        url = `${USER_CARD}?userEnd=mifi`
      } else if (
        (this.mificode && this.mificode.indexOf("http") === -1) ||
        this.payType === "YJ"
      ) {
        url = `${USER_CARD}?userEnd=web&qrcode=${this.mificode}`
      } else {
        url = `${USER_CARD}?userEnd=web&agentId=${this.agentOpenId}`
      }
      this.$loading(true)
      this.$get(
        url,
        {},
        (resp) => {
          this.$loading(false)
          console.log(resp)
          this.cardList = resp.data
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18nMsg.error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.getUserCardList()
          })
          console.log(error)
        },
        {
          headers: {
            agentOpenId: this.agentOpenId
          }
        }
      )
    },
    saveCard() {
      const { paymentWay, paymentId } = this.$route.query
      console.log("this.payMode", this.payMode)
      if (!this.user) {
        this.$toast(this.i18nMsg.unlogin)
        return
      }
      if (!this.payMode || !this.pId) {
        this.$toast(this.i18nMsg.nopaymode)
        return
      }
      let url = `${STRIPE_SAVE_CARD}?paymentId=${paymentId || this.pId}`
      this.$loading(true)
      this.$post(
        url,
        {},
        (resp) => {
          this.$loading(false)
          this.creditOrder = resp.data
          if (paymentWay === "PAYMENT_CARD") {
            this.onLoadElement()
          }
          // this.onCreditcard(this.publicKey)

          console.log("saveCard====", resp)
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18nMsg.msg_fail_title,
            content: error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.saveCard()
          })
          console.warn(error)
        }
      )
    },
    onLoadElement() {
      const options = {
        clientSecret: this.creditOrder.clientSecret,
        appearance: {
          theme: "stripe"
        }
      }
      if (window.stripe) {
        // this.loadStripeScript(this.payMode.publicKey)
        this.creditcard = window.stripe.elements(options)
        const paymentElement = this.creditcard.create("payment")
        this.isCreditcard = true
        // this.payListShow = false
        paymentElement.mount("#credit-card-element")
      } else {
        this.$toast("loading...")
        this.loadStripeScript()
        setTimeout(() => {
          this.onLoadElement()
        }, 500)
      }
    },
    checkOrder(tradeNo, payType, query) {
      if (payType === "CZ") {
        this.$confirm({
          title: this.i18nMsg.title,
          content: this.i18nMsg.success_payment,
          confirmText: this.i18nMsg.confirm,
          showCancel: false
        }).then(() => {
          this.$router.replace("/mine")
        })
      } else {
        if (payType === "FK") {
          this.$router.replace(
            `/result?tradeNo=${tradeNo}&payMode=stripe&payType=FK`
          )
          return
        }
        this.$router.replace(`/result?tradeNo=${tradeNo}&payMode=stripe`)
      }
    },
    onConfirmSaveCard(pid, id) {
      this.$loading(true)
      this.$post(
        STRIPE_SAVE_CARD_CONFIRM,
        {
          paymentId: pid,
          paymentMethod: id
        },
        (resp) => {
          this.$loading(false)
          console.log("saveCardConfirm====", resp)
          //   this.getCardList()
          this.saveToRent()
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18nMsg.msg_fail_title,
            content: error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.onConfirmSaveCard(pid, id)
          })
          console.log(error)
        }
      )
    },
    saveToRent() {
      this.$loading(true)
      this.$get(
        `${SAVED_CARD_TO_RENT}`,
        {
          qrcode: this.cabinet.cabinetID || this.$route.query.qrcode
        },
        (resp) => {
          this.$loading(false)
          console.log(resp)
          if (resp.data && resp.data.out_trade_no) {
            this.checkOrder(resp.data.out_trade_no, this.payType)
          }
        },
        (error) => {
          this.$loading(false)
          console.log(error)
        },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8"
          }
        }
      )
    }
  },
  created() {
    let price = this.$route.query.price
    let { paymentId, pmid } = this.$route.query
    let formId = this.$route.query.formId
    this.price = price ? Number(price) : 0
    this.orderId = this.$route.query.outTradeNo
    this.mifiOrderId = this.$route.query.mifiOrderId
    this.formId = formId
    this.payType = this.$route.query.payType
    this.cabinetID = this.$route.query.cabinetID
    this.yaJinType = this.$route.query.yaJinType
    this.getPayMode()
    if (
      this.payType === "YJ" ||
      this.payType === "FK" ||
      this.payType === "mifi"
    ) {
      this.getUserCardList()
    }
    const payId = new URLSearchParams(window.location.search).get(
      "payment_intent"
    )
    console.log(`query-payId:${payId}-paymentId:${paymentId}-pmid:${pmid}`)

    const { outTradeNo, pType } = this.$route.query
    // flutterwave-支付确认
    if (outTradeNo && pType === "flutterwave") {
      this.onFlutterwavConfirm({ outTradeNo })
    } else if (paymentId && pmid) {
      this.onConfirmSaveCard(paymentId, pmid)
    } else if (payId) {
      this.confirmSetup(payId)
    }
    // let isPaymentIntent = getQueryString('payment_intent')
    // let redirectStatus = getQueryString('redirect_status')
    // if (isPaymentIntent && redirectStatus === 'succeeded') {
    //   this.clientSecret = {
    //     outTradeNo: this.$route.query.orderNo
    //   }
    //   this.confirmType = 'czOrder'
    //   this.confirmSetup(isPaymentIntent, 'true')
    // }
    console.log(this)
  }
}
</script>

<style lang="less">
#form-checkout {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  .container {
    height: 50px;
    display: inline-block;
    border: 1px solid rgb(118, 118, 118);
    border-radius: 2px;
    padding: 1px 2px;
    margin-bottom: 10px;
  }
  & > select {
    height: 60px;
    margin-bottom: 10px;
  }
  & > input {
    height: 50px;
    margin-bottom: 10px;
  }
  & > button {
    background: #65b74e;
    color: #ffffff;
    border: none;
    outline: none;
    padding: 20px 0;
    margin-top: 20px;
  }
  .progress-bar {
    width: 100%;
  }
}

.noClick {
  opacity: 0.5;
  pointer-events: none; /* 禁止鼠标点击 */
}
.confirm-warp {
  overflow-y: scroll;
}
.confirm-info {
  background: #ffffff;
  text-align: center;
  padding: 40px;
}
.confirm-title {
  font-size: 36px;
}
.confirm-price {
  font-size: 80px;
  color: #fc0d1b;
}
.confirm-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  & img {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
  }
  & span {
    text-align: left;
    font-size: 28px;
    color: #999999;
  }
}
.confirm-payment {
  background: #ffffff;
  padding: 30px;
  &-label {
    padding: 10px 20px;
    font-size: 28px;
    color: #999999;
  }
  &-item {
    display: flex;
    align-items: center;
    padding: 30px 20px;
    border-bottom: 1px solid #dddddd;
    & img {
      width: 40px;
      height: 40px;
    }
    &-info {
      display: flex;
      align-items: center;
      flex: 1;
      & .content {
        padding-left: 10px;
      }
      & .sub_title {
        font-size: 28px;
        color: #fec348;
      }
    }
    &-operate {
      display: flex;
      justify-content: flex-end;
      &-text {
        padding: 4px 12px;
        font-size: 28px;
        color: #fc0d1b;
      }
    }
  }
}
.confirm-remark {
  padding: 0 32px;
}
.confirm-remark .title {
  margin-top: 40px;
  font-size: 32px;
}
.confirm-remark .desc {
  font-size: 28px;
  color: rgba(102, 102, 102, 1);
  font-weight: 400;
  word-break: normal !important;
  white-space: normal !important;
}
.confirm-btns {
  padding: 60px 40px 20px;
}
.confirm-btns .btn {
  margin-top: 20px;
}
/* #ifdef MP-WEIXIN */
.confirm-btns button.btn {
  background-color: #65b74e;
}
/* #endif */
.confirm-checkbox {
  padding: 30px;
  & label {
    display: flex;
    align-items: center;
  }
  & span {
    color: #52ac7a;
  }
}
.btn-paypal {
  background: #fec348 !important;
}
.btn-paypal img {
  width: 277px;
  height: 60px;
  margin: 18px 0;
}
.btn-applepay {
  background: #ffffff !important;
  color: #050608;
}
.btn-applepay img {
  width: 143px;
  height: 60px;
  margin: 18px 0;
}
.btn-googlepay {
  color: #5f6368;
  background: #ffffff !important;
}
.btn-googlepay img {
  width: 145px;
  height: 60px;
  margin: 18px 0;
}
.creditcard-input {
  margin: 48px 48px 100px;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid #e4e7ed;
}
.creditcard-btn {
  margin: 48px;
}
.confirm-row {
  margin: 36px 45px 140px;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 4px solid rgb(211, 211, 211);
  border-radius: 20px;
  .area {
    width: 100px;
    padding: 0 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  input {
    font-size: 32px;
    width: 300px;
    flex: 1;
    height: 80px;
    padding: 0 20px;
    margin: 0;
    border: none;
    outline: none;
    background-color: rgba(0, 0, 0, 0);
  }
  .area-down {
    // margin-left: 10px;
    width: 0;
    height: 0;
    border-width: 10px 10px 0;
    border-style: solid;
    border-color: black transparent transparent;
  }
  .login-code {
    width: 180px;
    height: 60px;
    background-image: linear-gradient(
      to right,
      rgba(194, 194, 194, 1),
      rgba(252, 252, 252, 1)
    );
    img {
      width: 180px;
      height: 60px;
    }
  }
}
.pay-amount {
  padding: 43px 20px;
  background: #ffffff;
  border-radius: 10px 10px 10px 10px;
  margin-top: 40px;

  .pay-amount-title {
    font-size: 30px;
    font-family: Work Sans-Medium, Work Sans;
    font-weight: 500;
    color: #444444;
  }

  .doller {
    font-size: 30px;
    font-family: Work Sans-Regular, Work Sans;
  }

  .pay-price {
    font-size: 54px;
    font-family: Work Sans-Bold, Work Sans;
    font-weight: bold;
    padding: 0 10px;
  }
}

.pay-h1 {
  font-size: 32px;
  font-family: Work Sans-Bold, Work Sans;
  font-weight: bold;
  color: #333333;
  padding: 40px 40px 20px 40px;
}
</style>
