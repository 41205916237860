<template>
  <div>
    <van-dialog
      v-model="dialogModel"
      show-cancel-button
      :confirmButtonText="$t('confirm.msg_fail_confirm')"
      :cancelButtonText="$t('confirm.msg_fail_cancel')"
      :before-close="onPayment"
    >
      <div class="pay-popup">
        <div class="pay-popup-number">
          <div class="area" @click="isAreaView = true">
            <span>+{{ payInfo.areaCode }}</span>
            <div class="area-down"></div>
          </div>
          <van-field
            type="number"
            v-model="payInfo.phone"
            class="pay-popup-phone"
            :placeholder="$t('login.phone_number')"
          />
        </div>
        <!-- <van-field
          class="pay-popup-email"
          v-model="payInfo.email"
          :placeholder="$t('order.enter_order_email')"
        /> -->
      </div>
    </van-dialog>
    <select-area v-model="isAreaView" @select="onSelct"></select-area>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { SelectArea } from "../select"
export default {
  props: {
    dialogPopup: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SelectArea
  },
  watch: {
    dialogPopup(val) {
      this.dialogModel = val
    }
  },
  computed: {
    ...mapState(["system", "user"]),
    i18n() {
      return this.$t("confirm")
    }
  },
  data() {
    return {
      dialogModel: false,
      isAreaView: false,
      payInfo: {
        show: false,
        phone: "", // "911234567",
        email: "",
        areaCode: "86"
      }
    }
  },
  methods: {
    onSelct(val) {
      this.payInfo.areaCode = val
    },
    // 确认支付
    onPayment(action, done) {
      const payInfo = { ...this.payInfo }
      if (action === "confirm") {
        if (!payInfo.phone) {
          this.$toast(this.$t("login.phone_number"))
          return done(false)
          // } else if (!payInfo.email) {
          //   this.$toast(this.$t("order.enter_order_email"))
          //   return done(false)
        }
        done()
        this.$emit("onPayment", { show: false, ...payInfo })
      } else if (action === "cancel") {
        this.$emit("onPayment", { show: false })
        done()
      }
    }
  },
  created() {
    if (this.user && this.user.email) {
      this.payInfo.email = this.user.email
    }
  }
}
</script>

<style lang="less" scoped>
.pay-popup {
  margin: 64px 0 32px;
  padding: 24px 46px;
  &-number {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    padding-left: 24px;
    border-radius: 16px;
    border: 1px solid #ccc;
    overflow: hidden;
  }
  &-phone {
    font-size: 32px;
  }
  &-email {
    border-radius: 16px;
    border: 1px solid #ccc;
    font-size: 32px;
  }
}
</style>
