import Vue from 'vue'
import Vuex from 'vuex'
import { USER_INFO } from '@/apis/user'
import { get } from '@/utils/http'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    safeArea: JSON.parse(localStorage.getItem('cdb_safe_area')) || { top: 0, bottom: 0 },
    user: JSON.parse(localStorage.getItem('cdb_user')),
    userPwd: JSON.parse(localStorage.getItem('cdb_user_pwd')),
    token: localStorage.getItem('cdb_token'),
    loading: false,
    appleIdToken: '',
    urlcode: sessionStorage.getItem('cdb_urlcode'),
    qrcode: sessionStorage.getItem('cdb_qrcode'),
    zhcode: sessionStorage.getItem('cdb_zhcode'),
    mificode: sessionStorage.getItem('cdb_mificode'),
    mifiPackage: JSON.parse(sessionStorage.getItem('cdb_package')) || '',
    mifiOrder: JSON.parse(sessionStorage.getItem('cdb_mifiorder')) || '',
    mifisn: sessionStorage.getItem('cdb_mifisn') || '',
    zujieOrder: JSON.parse(sessionStorage.getItem('cdb_zujieorder')) || '',
    lang: localStorage.getItem('cdb_lang') || process.env.VUE_APP_I18N_LOCALE,
    system: localStorage.getItem('cdb_system') || '',
    browser: localStorage.getItem('cdb_browser') || '',
    cabinet: null,
    localeArr: [
      { key: 0, label: '简体中文', value: 'zh-CN', currency: '元', money: '￥' },
      { key: 1, label: 'English', value: 'en-US', currency: 'dollar', money: '$' },
      { key: 2, label: 'Lithuanian', value: 'lt-LT', currency: 'LTL', money: 'Lt' },
      { key: 3, label: 'Português', value: 'pt-PT', currency: 'EUR', money: '€' },
      { key: 4, label: "Spanish", value: "es-ES", currency: "EUR", money: "€" }
    ],
    wallet: sessionStorage.getItem('cdb_wallet') ? JSON.parse(sessionStorage.getItem('cdb_wallet')) : null,
    agentOpenId: 'oDn2e4hi8M1iVtXcxm1uYpKTPEBE',
    userType: JSON.parse(localStorage.getItem('cdb_user_type')), // 游客类型(tourist_login)
    homePathName: localStorage.getItem('cdb_homename') || 'home'
  },
  getters: {
    locale: state => {
      return state.localeArr.find(obj => obj.value === state.lang)
    }
  },
  mutations: {
    safeArea(state, payload) {
      localStorage.setItem('cdb_safe_area', JSON.stringify(payload))
      state.safeArea = payload
    },
    user(state, payload) {
      localStorage.setItem('cdb_user', JSON.stringify(payload))
      state.user = payload
    },
    userPwd(state, payload) {
      localStorage.setItem('cdb_user_pwd', JSON.stringify(payload))
      state.userPwd = payload
    },
    token(state, payload) {
      localStorage.setItem('cdb_token', payload)
      state.token = payload
    },
    appleIdToken(state, payload) {
      state.appleIdToken = payload
    },
    loading(state, payload) {
      state.loading = payload
    },
    urlcode(state, payload) {
      sessionStorage.setItem('cdb_urlcode', payload)
      state.urlcode = payload
    },
    qrcode(state, payload) {
      sessionStorage.setItem('cdb_qrcode', payload)
      state.qrcode = payload
    },
    zhcode(state, payload) {
      sessionStorage.setItem('cdb_zhcode', payload)
      state.zhcode = payload
    },
    mificode(state, payload) {
      sessionStorage.setItem('cdb_mificode', payload)
      state.mificode = payload
    },
    mifiPackage(state, payload) {
      sessionStorage.setItem('cdb_package', JSON.stringify(payload))
      state.mifiPackage = payload
    },
    mifiOrder(state, payload) {
      sessionStorage.setItem('cdb_mifiorder', JSON.stringify(payload))
      state.mifiOrder = payload
    },
    mifisn(state, payload) {
      sessionStorage.setItem('cdb_mifisn', payload)
      state.mifisn = payload
    },
    zujieOrder(state, payload) {
      sessionStorage.setItem('cdb_zujieorder', JSON.stringify(payload))
      state.zujieOrder = payload
    },
    lang(state, payload) {
      localStorage.setItem('cdb_lang', payload)
      state.lang = payload
    },
    system(state, payload) {
      localStorage.setItem('cdb_system', payload)
      state.system = payload
    },
    browser(state, payload) {
      localStorage.setItem('cdb_browser', payload)
      state.browser = payload
    },
    cabinet(state, payload) {
      // sessionStorage.setItem('cdb_cabinet', payload)
      state.cabinet = payload
    },
    wallet(state, payload) {
      sessionStorage.setItem('cdb_wallet', JSON.stringify(payload))
      state.wallet = payload
    },
    userType(state, payload) {
      sessionStorage.setItem('cdb_user_type', JSON.stringify(payload))
      state.userType = payload
    },
    homePathName(state, payload) {
      localStorage.setItem('cdb_homename', payload)
      state.homePathName = payload
    }
  },
  actions: {
    loadUser({ commit }) {
      return new Promise((resolve, reject) => {
        get(
          USER_INFO,
          {},
          resp => {
            if (resp.user) {
              commit('user', resp.user)
              resolve(resp.user)
            } else {
              reject(new Error('Login Failed'))
            }
          },
          error => {
            reject(error)
          }
        )
      })
    }
  }
})
