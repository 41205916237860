<template>
  <div>
    <van-search
      v-model="searchValue"
      placeholder="Enter the place you want to go"
      shape="round"
      :clearable="false"
      custom-class='custom-class'
      field-class='custom-class'
      background='#f5f5f5'
      @keyup.enter="onSearch"
      :use-right-icon-slot = 'true'>
      <template #right-icon >
        <van-button round size='small'  type='primary' @click="onSearch">Search</van-button>
      </template>
      </van-search>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    value: {
      type: String
    }
  },
  data () {
    return {
      searchValue: ''
    }
  },
  watch: {
    value (val) {
      this.searchValue = val
    },
    searchValue (val) {
      this.$emit('input', val)
    }
  },
  methods: {
    onSearch () {
      this.$emit('handleValue')
    },
    keyupSubmit () {
      document.onkeydown = (e) => {
        var _key = window.event.keyCode
        // 防止用户重复回车
        if (_key === 13 && !this.clickState) {
          this.onSearch()
        }
      }
    }

  },
  created () {
    this.keyupSubmit()
  },
  destroyed () {
    document.onkeydown = undefined
  }
}
</script>

<style lang="less" scoped>
.custom-class{
  padding: 0px;
}
/deep/.van-search{
    padding: 0px;
}
/deep/.van-cell__left-icon-wrap{
  // display: none;
}
/deep/.van-search__content{
  background: #fff;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.2);
}
/deep/.van-button{
    padding: 0 40rpx !important;
    font-size: 21rpx!important;
    // background: #2dbb66!important;
    // color: #fff!important;
}
/deep/.van-cell--borderless{
  display: flex;
  align-items: center;
}
/deep/ .van-icon{
  position: unset;
  width: unset;
  height: unset;

}
/deep/ .van-button--primary{
  background: linear-gradient(132deg, #1A2229 0%, #00AB46 100%);
  border: 0px;
}
</style>
