<template>
  <page title="Order Details" :showBack="false">
    <div class="order-detail">
       <div class="left">
        <h2 class="text-primary">{{ statusMap[info.pZujie] }}</h2>
        <img src="../../assets/imgs/icon_swap.png" alt="" @click="getOrderDtl">
       </div>
    </div>
    <div class="time-feerule">
      <div class="time-rule">
        <div class="time-rule-item">
          <h3>{{ timeText }}</h3>
          <p>{{i18n.usetime}}</p>
        </div>
        <div class="time-rule-item">
          <h3>{{ info.pCurrencyName || '--' }}{{ priceFormat(jieSuan) }}</h3>
          <p>{{i18n.estimatedamount}}</p>
        </div>
      </div>
      <div class="fee-rule">
        <p>{{ i18n.feerule }}</p>
        <h3>
          <span>{{ $t('orderDetail.price1', { pCurrencyName: info.pCurrencyName, pJifei: priceFormat(info.pJifei), pJifeiDanwei: info.pJifeiDanwei, priceUnit: $t(`home.${priceUnitMap[info.priceUnit]}`) }) }}</span><br>
          <span>{{ $t('orderDetail.price2', { pCurrencyName: info.pCurrencyName, pFengding: priceFormat(info.pFengding) }) }}</span><br>
          <span>{{ $t('orderDetail.price3', { pCurrencyName: info.pCurrencyName, overtimeAmount: priceFormat(info.overtimeAmount) }) }}</span>
        </h3>
        <div v-if="info && info.pZujie === 1" class="btn buy-btn" @click="overBuy">{{ i18n.buypowerbank }}</div>
      </div>
    </div>
    <div class="order-info">
      <div class="order-no">
        <span>No.{{ info.pOrderid }}</span>
        <span class="copy" @click="copy(info.pOrderid, $event)">
          <img src="../../assets/imgs/order_icon_copy@2x.png" alt="">copy
        </span>
      </div>
      <p class="order-info-row">
        <span class="label">{{ i18n.venuerented }}</span>
        <span class="value">{{ info.pShopName }}</span>
      </p>
      <p class="order-info-row">
        <span class="label">{{ i18n.timerented }}</span>
        <span class="value">{{ info.pBorrowtime }}</span>
      </p>
      <p class="order-info-row">
        <span class="label">{{ i18n.venuereturned }}</span>
        <span class="value">{{ info.pGhShopName }}</span>
      </p>
      <p class="order-info-row">
        <span class="label">{{ i18n.timereturned }}</span>
        <span class="value">{{ info.pGhtime }}</span>
      </p>
    </div>
    <!-- <div v-if="info && !info.pAlreadySendInvoices" class="btn receipt-btn" @click="onSend">{{ i18n.getreceipt }}</div> -->
  </page>
</template>

<script>
import dayjs from 'dayjs'
import Cipboard from 'clipboard'
import { INVOICE_ORDERDTL, INVOICE_ORDERTIME, SEND_EMAIL, LOGIN_BYOPENID } from '@/apis/user'
let timer
export default {
  components: {
  },
  computed: {
    i18n () {
      return this.$t('orderDetail')
    },
    i18nMsg () {
      return this.$t('msg')
    },
    priceUnitMap() {
      return {
        0: "分钟",
        1: "小时",
        2: "天"
      }
    },
    statusMap () {
      return {
        1: this.i18n.onrental,
        2: this.i18n.revoked,
        3: this.i18n.returned,
        4: this.i18n.issue,
        5: this.i18n.overtimereturn
      }
    }
  },
  watch: {
  },
  data () {
    return {
      info: {},
      timeText: '00:00:00',
      jieSuan: 0
    }
  },
  methods: {
    copy (val, event) {
      let clip = new Cipboard(event.target, {
        text: () => val
      })
      clip.onClick(event)
      this.$toast(this.i18nMsg.copysuccess)
    },
    priceFormat (val) {
      if (val) {
        return val % 1 === 0 ? val : val.toFixed(2)
      }
      return val
    },
    overBuy () {
      this.$router.push({
        path: "/confirm",
        query: {
          price: this.info.overtimeAmount,
          payType: "overbuy",
          orderId: this.info.pOrderid
        }
      })
    },
    getOrderDtl () {
      this.$loading(true)
      this.$get(
        INVOICE_ORDERDTL,
        {
          orderId: this.$route.query.orderId
        },
        resp => {
          this.$loading(false)
          if (resp.code === 0 && resp.data) {
            this.info = resp.data
            this.getOrderTime()
            this.getOpenid(resp.data.pUserid)
          }
        },
        error => {
          this.$loading(false)
          // this.$toast(error)
          console.log(error)
        },
        {
          headers: {
            noToken: true
          }
        }
      )
    },
    getOrderTime () {
      this.$loading(true)
      this.$get(
        INVOICE_ORDERTIME,
        {
          orderId: this.$route.query.orderId
        },
        resp => {
          this.$loading(false)
          if (resp.code === 0 && resp.data) {
            this.jieSuan = resp.data.pJiesuan
            if (this.info && this.info.pZujie === 1) {
              timer = setInterval(() => {
                this.initTime()
              }, 1000)
            } else {
              this.initTime()
            }
          }
        },
        error => {
          this.$loading(false)
          // this.$toast(error)
          console.log(error)
        }
      )
    },
    getOpenid (userid) {
      this.$loading(true)
      this.$post(
        LOGIN_BYOPENID,
        {
          openId: userid
        },
        resp => {
          this.$loading(false)
          if (resp.code === 0 && resp.token) {
            this.$store.commit('token', resp.token)
            this.$store.commit('user', resp.user)
          }
        },
        error => {
          this.$loading(false)
          // this.$toast(error)
          console.log(error)
        }
      )
    },
    onSend () {
      this.$loading(true)
      this.$post(
        SEND_EMAIL,
        {
          orderId: this.$route.query.orderId
        },
        resp => {
          this.$loading(false)
          this.$toast(this.i18nMsg.sendsuccess)
        },
        error => {
          this.$loading(false)
          // this.$toast(error)
          console.log(error)
        }
      )
    },
    initTime () {
      let vm = this
      let timeCha = {}
      if (this.info.pZujie === 1) {
        timeCha = dayjs().diff(dayjs(vm.info.pBorrowtime))
      } else {
        timeCha = dayjs(vm.info.pGhtime).diff(dayjs(vm.info.pBorrowtime))
      }
      let lastHour = Math.floor(timeCha / 3600000)
      let lastMin = Math.floor(timeCha / 1000 / 60 % 60)
      let lastSecond = Math.floor(timeCha / 1000 % 60)
      lastHour = lastHour < 10 ? `0${lastHour}` : lastHour
      lastMin = lastMin < 10 ? `0${lastMin}` : lastMin
      lastSecond = lastSecond < 10 ? `0${lastSecond}` : lastSecond
      this.timeText = `${lastHour}:${lastMin}:${lastSecond}`
    }
  },
  created () {
    this.getOrderDtl()
  },
  beforeDestroy () {
    clearInterval(timer)
  }
}
</script>

<style lang="less" scoped>
@textPrimary: #00B578;
.text-primary {
  color: @textPrimary;
}
.order-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 40px;
}
.order-detail .left {
  display: flex;
  h2 {
    margin: 0;
  }
  img {
    vertical-align: text-top;
    width: 55px;
    height: 55px;
    margin-left: 10px;
  }
}
.time-feerule {
  margin: 20px 40px;
  border: solid 1px #ccc;
  border-radius: 20px;
  padding: 0 40px;
  .time-rule {
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px #ccc;
  }
  .fee-rule {
    h3 {
      text-align: center;
      color: @textPrimary;
    }
    .buy-btn {
      background: none;
      box-shadow: none;
      border: solid 6px @textPrimary;
      color: #000;
      font-weight: bold;
    }
  }
}
.order-info {
  margin: 20px 40px;
  border: solid 1px #ccc;
  border-radius: 20px;
  padding: 0 40px;
  .order-no {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    border-bottom: solid 1px #ccc;
    font-weight: bold;
    .copy {
      display: flex;
      align-items: center;
      color: @textPrimary;
    }
    img {
      width: 55px;
      height: 55px;
      margin-right: 10px;
    }
  }
  .order-info-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .label {
      color: #999;
    }
    .value {
      color: #000;
      font-weight: bold;
    }
  }
}
.receipt-btn {
  background: @textPrimary;
  color: #fff;
  font-weight: bold;
}
</style>
