import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import AboutUs from '../views/aboutUs.vue'
import contactUs from '../views/contactUs.vue'
import Login from '../views/login/login.vue'
import otherLogin from '../views/login/otherLogin.vue'
import LoginWechat from '../views/login/wechat.vue'
import LoginAlipay from '../views/login/alipay.vue'
import Confirm from '../views/confirm/confirm.vue'
import Wallet from '../views/wallet/wallet.vue'
import Result from '../views/result/result.vue'
import Order from '../views/order/order.vue'
import Setting from '../views/setting/setting.vue'
import Shop from '../views/shop/shop.vue'
import Help from '../views/help/help.vue'
import WalletIn from '../views/wallet/wallet_in.vue'
import WalletOut from '../views/wallet/wallet_out.vue'
import WalletList from '../views/wallet/wallet_list.vue'
import GoogleMap from '../views/map/google-index.vue'
import ShopDetail from '../views/nearby/shop'
import selectPakage from '../views/selectPakage/selectPakage.vue'
import wifiMall from '../views/wifiMall/wifiMall.vue'
import packageOrder from '../views/packageOrder/packageOrder.vue'
import scanOrder from '../views/scanOrder/scanOrder.vue'
import scanPickUpOrder from '../views/scanPickUpOrder/scanPickUpOrder.vue'
import pickUp from '../views/pickUp/pickUp.vue'
import borrowSuccess from '../views/borrowSuccess/borrowSuccess.vue'
import payMethod from '../views/payMethod/payMethod.vue'
import MercadoPago from '../views/payMethod/MercadoPago.vue'
import packageDetail from '../views/packageDetail/packageDetail.vue'
import orderDetail from '../views/orderDetail/orderDetail.vue'
import invoiceOrderDtl from '../views/orderDetail/invoiceOrderDtl.vue'
import usage from '../views/usage/usage.vue'
import homeMap from '../views/homeMap/homeMap.vue'
import homeScan from '../views/homeMap/homeScan.vue'
import pastOrder from '../views/pastOrder/pastOrder.vue'
import scanQr from '../views/scanQr/scanQr.vue'
import cdbUsage from '../views/cdbUsage/cdbUsage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/loginborrow',
    name: 'loginborrow',
    component: otherLogin,
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/otherLogin',
    name: 'otherLogin',
    component: otherLogin,
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/',
    name: 'homeScan',
    component: homeScan,
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/homeMap',
    name: 'homeMap',
    component: homeMap,
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/login_wechat',
    name: 'login_wechat',
    component: LoginWechat,
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/login_alipay',
    name: 'login_alipay',
    component: LoginAlipay,
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/login_alipay',
    name: 'login_alipay',
    component: LoginAlipay,
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/aboutUsApp',
    redirect: to => {
      // 方法接收目标路由作为参数
      // return 重定向的字符串路径/路径对象
      return { path: '/aboutUs', query: { type: 'app' } }
    },
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/aboutUsApp/:lang',
    component: AboutUs,
    meta: {
      ignoreAuth: true,
      type: 'app'
    }
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: AboutUs,
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/contactUsApp',
    redirect: to => {
      // 方法接收目标路由作为参数
      // return 重定向的字符串路径/路径对象
      return { path: '/contactUs', query: { type: 'app' } }
    },
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/contactUsApp/:lang',
    component: contactUs,
    meta: {
      ignoreAuth: true,
      type: 'app'
    }
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: contactUs,
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: Confirm
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: Wallet
  },
  {
    path: '/result',
    name: 'result',
    component: Result
  },
  {
    path: '/order',
    name: 'order',
    component: Order
  },
  {
    path: '/setting',
    name: 'setting',
    component: Setting
  },
  {
    path: '/shop',
    name: 'shop',
    component: Shop
  },
  {
    path: '/help',
    name: 'help',
    component: Help
  },
  {
    path: '/wallet_in',
    name: 'wallet_in',
    component: WalletIn
  },
  {
    path: '/wallet_out',
    name: 'wallet_out',
    component: WalletOut
  },
  {
    path: '/wallet_list',
    name: 'wallet_list',
    component: WalletList
  },
  {
    path: '/google_map',
    name: 'google_map',
    component: GoogleMap,
    alias: '/map'
  },
  {
    path: '/nearby_shop',
    name: 'nearby_shop',
    component: ShopDetail
  },
  {
    path: '/selectPakage',
    name: 'selectPakage',
    component: selectPakage
  },
  {
    path: '/wifiMall',
    name: 'wifiMall',
    component: wifiMall
  },
  {
    path: '/packageOrder',
    name: 'packageOrder',
    component: packageOrder
  },
  {
    path: '/scanOrder',
    name: 'scanOrder',
    component: scanOrder
  },
  {
    path: '/scanPickUpOrder',
    name: 'scanPickUpOrder',
    component: scanPickUpOrder
  },
  {
    path: '/pickUp',
    name: 'pickUp',
    component: pickUp
  },
  {
    path: '/borrowSuccess',
    name: 'borrowSuccess',
    component: borrowSuccess
  },
  {
    path: '/payMethod',
    name: 'payMethod',
    component: payMethod,
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/MercadoPago',
    name: 'MercadoPago',
    component: MercadoPago,
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/packageDetail',
    name: 'packageDetail',
    component: packageDetail
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    component: orderDetail
  },
  {
    path: '/invoiceOrderDtl',
    name: 'invoiceOrderDtl',
    component: invoiceOrderDtl,
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/usage',
    name: 'usage',
    component: usage
  },
  {
    path: '/homeMap',
    name: 'homeMap',
    component: homeMap
  },
  {
    path: '/pastOrder',
    name: 'pastOrder',
    component: pastOrder
  },
  {
    path: '/scanQr',
    name: 'scanQr',
    component: scanQr
  }, {
    path: '/payment_card',
    name: 'payment_card',
    component: () => import('../views/payment/card')
  },
  {
    path: '/cdbUsage',
    name: 'cdbUsage',
    component: cdbUsage
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  console.log('to', to)
  const token = store.state.token
  if (to.name === 'home') {
    if (to.query.qrcode) {
      store.commit('qrcode', to.query.qrcode)
      store.commit('zhcode', '')
    }
    if (to.query.zhcode) {
      store.commit('zhcode', to.query.zhcode)
      store.commit('qrcode', '')
    }
  }
  if (to.query.AppleIdToken) {
    store.commit('appleIdToken', to.query.AppleIdToken)
  }
  if (to.matched.some(r => r.meta.ignoreAuth) || token) {
    next()
  } else {
    next({
      path: '/login'
    })
  }
})

export default router
