<template>
  <page title=" " :backHandel="true">
    <div v-if="second > 0" class="borrow-loading-warp">
      <div>
        <img class="loading-img" src="@/assets/imgs/loading.gif" alt="" />
        <!-- <circle-progress v-model="percent" />
        <span>{{secondFmt}}s</span> -->
      </div>
      <div class="loading-title">
        {{ i18n.loading_title }}
      </div>
      <div class="loading-tips">
        {{ i18n.loading_tips }}
      </div>
    </div>
    <template v-else-if="!error">
      <div class="flex-c" style="margin-bottom: 30px">
        <div class="success-box">
          <div class="success-title">{{ i18n.success }}</div>
          <van-image
            width="7rem"
            fit="contain"
            :src="require('assets/imgs/device2.png')"
          />
          <div class="success-tips">{{ i18n.download_tips }}</div>
          <div class="success-email" v-if="showInvoice">
            <input
              type="email"
              :placeholder="$t('aboutus.email')"
              v-model.trim="email"
            />
            <span @click="onSend">{{ i18n.send }}</span>
          </div>
        </div>
      </div>
      <div class="btn btn-download" @click="onDownload">
        <span>{{ i18n.download.toUpperCase() }}</span>
      </div>
      <div class="success-tips">{{ i18n.download_tips2 }}</div>
      <!-- <div class="p-20" v-if="this.mifiOrder && !this.mifiOrder.payType && !isCdb">
        <van-button type="primary" round style="width:100%;" class="bottom-pakage-btn" @click="onSubmit"> {{i18n.renewalTraffic}}</van-button>
      </div>
      <div class="p-v-20">
        <van-button plain type="primary" round style="width:100%;" @click="goOrder"> {{i18n.viewOrder}}</van-button>
      </div>
        <div class="p-v-20">
        <van-button plain type="primary" round style="width:100%;" @click="goWhere"> {{i18n.HowUseDevice}}</van-button>
      </div> -->
    </template>
    <!-- <result-msg v-else-if="loading" type="100" :text="i18n.msg_result_loading" /> -->
    <result-msg v-else-if="error" type="500" :text="error" @click="onReload" />
    <result-msg v-else />
  </page>
</template>

<script>
import { mapState } from "vuex"
// import CircleProgress from '../../components/result/circle_progress.vue'
import ResultMsg from "../../components/result/msg.vue"
import { ORDER_CHECK } from "../../apis/cabinet.js"
import { SEND_EMAIL, INVOICE_USERCHECK } from "../../apis/user.js"
import {
  WECHAT_PAYCONFIRM,
  SAVED_CARD_TO_RENT,
  SUMUP_SAVEPAYMENTMETHORD,
  ESPEES_PAYCONFIRM
} from "../../apis/payment"

let loadingTimer
export default {
  components: {
    // CircleProgress,
    ResultMsg
  },
  computed: {
    ...mapState(["mifiOrder", "mificode", "system", "qrcode", "user"]),
    i18n() {
      return this.$t("borrowSuccess")
    },
    i18nResult() {
      return this.$t("result")
    },
    i18nMsg() {
      return this.$t("msg")
    },
    secondFmt() {
      return (this.second + 0.45).toFixed(0)
    },
    percent() {
      return (this.second % 30) * 3.334
    },
    isCdb() {
      return !this.$route.query.loading || this.$route.query.payMode
    }
  },
  data() {
    return {
      second: 0,
      error: "",
      tradeNo: "",
      payType: "",
      email: "",
      showInvoice: false
    }
  },
  methods: {
    goRouter(path) {
      this.$router.replace(path)
    },
    onReload() {
      this.checkOrder(
        this.tradeNo,
        this.$route.query.payMode || "stripe",
        this.payType
      )
    },
    goOrder() {
      if (this.mifiOrder && this.mifiOrder.payType === "GM") {
        this.$router.replace("/scanOrder")
      } else {
        this.$router.replace("/pastOrder")
      }
    },
    goWhere() {
      if (this.isCdb) {
        this.$router.replace("/cdbUsage")
      } else {
        this.$router.replace("/usage")
      }
    },
    startTimer() {
      if (this.second === 0) {
        loadingTimer = setInterval(() => {
          this.second += 0.1
        }, 100)
      }
    },
    stopTimer() {
      clearInterval(loadingTimer)
      this.second = 0
    },
    onSubmit() {
      console.log("续购按钮")
      if (this.mifiOrder && this.mifiOrder.productSendSn) {
        this.$store.commit("mifisn", this.mifiOrder.productSendSn)
        this.$router.push("/wifiMall?type=XG")
      } else {
        this.$router.push(`/packageDetail?orderId=${this.$route.query.tradeNo}`)
      }
    },
    invoiceUserCheck() {
      this.$loading(true)
      this.$get(
        INVOICE_USERCHECK,
        {
          orderId: this.$route.query.tradeNo
        },
        (resp) => {
          this.$loading(false)
          if (resp.code === 0 && resp.data) {
            this.showInvoice = true
          }
        },
        (error) => {
          this.$loading(false)
          setTimeout(() => {
            this.$toast(error)
          }, 500)
          console.log(error)
        }
      )
    },
    onSend() {
      if (!this.email) {
        return
      }
      this.$loading(true)
      this.$post(
        SEND_EMAIL,
        {
          orderId: this.$route.query.tradeNo,
          email: this.email
        },
        (resp) => {
          this.$loading(false)
          setTimeout(() => {
            this.$toast(this.i18nMsg.sendsuccess)
            this.$store.dispatch("loadUser")
          }, 500)
        },
        (error) => {
          this.$loading(false)
          setTimeout(() => {
            this.$toast(error)
          }, 500)
          console.log(error)
        },
        {
          headers: {
            "content-type": "application/json;charset=UTF-8"
          }
        }
      )
    },
    checkOrder(tradeNo, payMode = "stripe", payType = "") {
      this.startTimer()
      // if (payMode === 'mpesa') {
      //   this.onSMSCheckOrder(tradeNo, payMode, payType)
      //   return
      // }
      let url = `${ORDER_CHECK}/${tradeNo}/${payMode}`
      if (payMode === "wechat") {
        url = `${WECHAT_PAYCONFIRM}?outTradeNo=${tradeNo}`
      }
      this.tradeNo = tradeNo
      this.loading = true
      this.$post(
        url,
        {},
        (resp) => {
          if (payType === "FK") {
            this.$router.replace({ path: "/pastOrder" })
            return
          } else if (payType === "CZ") {
            this.$router.replace({ path: "/wallet" })
            return
          }
          this.error = ""
          this.stopTimer()
          this.order = resp
          if (this.user && this.user.pname.includes('Tourist')) {
            this.invoiceUserCheck()
          }
        },
        (error) => {
          if (payType === "FK") {
            setTimeout(() => {
              this.$router.replace({ path: "/pastOrder" })
            }, 1500)
          } else if (payType === "CZ") {
            setTimeout(() => {
              this.$router.replace({ path: "/wallet" })
            }, 1500)
          } else if (error === "正在请求中" || error === "Processing") {
            setTimeout(() => {
              this.checkOrder(tradeNo, payMode, payType)
            }, 1000)
          } else {
            this.stopTimer()
            this.error = error
            console.log(error)
          }
        }
      )
    },
    onDownload() {
      if (this.system === "ios") {
        window.location.href = process.env.VUE_APP_APP_STORE
      } else {
        window.location.href = process.env.VUE_APP_GOOGLE_PLAY
      }
    },
    // sumup 支付订单确认
    async onSumUpConfirm(payMode) {
      this.loading = true
      let { tradeNo, paymentId, payType } = this.$route.query
      if (tradeNo && !paymentId) {
        this.checkOrder(tradeNo, payMode, payType)
        return
      }
      let requestRetryCount = 10
      const qrcode =
        this.mificode || this.qrcode || (this.cabinet && this.cabinet.cabinetID)
      const confirmRequest = () => {
        this.$get(
          SAVED_CARD_TO_RENT,
          { qrcode },
          (resp) => {
            this.loading = false
            this.error = ""
            const data = resp.data || {}
            if (data.out_trade_no) {
              if (payType === "CZ") {
                this.$confirm({
                  title: this.i18nMsg.title,
                  content: this.i18nMsg.success_payment,
                  confirmText: this.i18nMsg.confirm,
                  showCancel: false
                }).then(() => {
                  this.$router.push("/mine")
                })
              }
              if (payType === "YJ") {
                this.checkOrder(data.out_trade_no, payMode, payType)
              }
            }
          },
          (error) => {
            if (error === "正在请求中" || error === "Processing") {
              if (requestRetryCount > 0) {
                requestRetryCount--
                setTimeout(confirmRequest, 1000)
              }
            } else {
              this.stopTimer()
              this.loading = false
              this.error = error
              console.log(error)
            }
          }
        )
      }
      if (paymentId) {
        const headers = {
          headers: { "Content-Type": "application/json;charset=UTF-8" }
        }
        const params = { paymentId }
        this.$Request("post", SUMUP_SAVEPAYMENTMETHORD, params, headers).then(
          (res) => {
            if (res && res.code === 0) confirmRequest()
          }
        )
      }
    },
    // Espees支付确认
    onEspeesPayConfirm(payMode) {
      const { tradeNo = "", outTradeNo = "", type = "" } = this.$route.query
      const datas = {
        tradeNo: tradeNo,
        outTradeNo: outTradeNo
      }
      this.startTimer()
      this.$Request("get", ESPEES_PAYCONFIRM, datas)
        .then((resp) => {
          if (type === "CZ" || resp.data.type === "CZ") {
            setTimeout(() => {
              this.$router.replace({ path: "/wallet" })
            }, 1500)
            // 付款订单 RENT
          } else if (type === "RENT") {
            this.$router.replace({ path: "/pastOrder" })
          } else {
            this.checkOrder(outTradeNo, payMode, this.payType)
          }
        })
        .catch((error) => {
          this.error = error
          this.stopTimer()
          console.log("ESPEES_PAYCONFIRM", error)
        })
    }
  },
  created() {
    let { payMode } = this.$route.query
    if (this.user && (this.user.pUnionid || this.user.email)) {
      this.email = this.user.pUnionid || this.user.email
    }
    if (payMode === "SumUpOnlinePayment") {
      this.onSumUpConfirm(payMode)
    } else if (payMode === "Espees") {
      this.onEspeesPayConfirm(payMode)
    } else if (this.$route.query.loading !== "false") {
      this.payType = this.$route.query.payType
      this.checkOrder(
        this.$route.query.tradeNo,
        this.$route.query.payMode || "stripe",
        this.payType
      )
    }
  },
  beforeDestroy() {
    this.$store.commit("mificode", "")
    this.$store.commit("mifiPackage", "")
    this.$store.commit("qrcode", "")
  }
}
</script>

<style lang="less" scoped>
.success-title {
  font-size: 56px;
  font-weight: bold;
  color: #000000;
}
.success-tips {
  padding: 0 56px;
  text-align: center;
}
.success-email {
  display: flex;
  margin: 40px 40px 20px 60px;
  align-items: center;
  input {
    flex: 1;
    padding: 20px 15px;
    border: solid 1px #e2e2e2;
    border-radius: 20px;
    font-size: 32px;
    outline: none;
  }
  span {
    width: max-content;
    margin-left: 20px;
    color: #00ab46;
    font-weight: bold;
  }
}
.success-box {
  text-align: center;
  margin-top: 80px;
}
.p-20 {
  padding: 0 20px;
}
.p-v-20 {
  padding: 20px 20px 0px 20px;
}
.bottom-pakage-btn {
  background: linear-gradient(132deg, #1a2229 0%, #00ab46 100%);
  font-size: 36px;
  font-weight: bold;
  color: #ffffff;
}
.borrow-loading-warp {
  min-height: 100vh;
  background-color: #fff;
  .loading-img {
    width: 500px;
    display: block;
    margin: 0 auto;
  }
  .loading-title {
    font-size: 48px;
    text-align: center;
    padding: 120px 0 12px;
    font-weight: 600;
  }
  .loading-tips {
    font-size: 36px;
    text-align: center;
  }
}
.btn-download {
  font-family: DIN-Condensed-Bold;
  font-size: 60px;
  margin: 0 32px 40px 32px;
  background: #00b578;
  border-radius: 18px;
  font-weight: normal;
  vertical-align: middle;
  line-height: 1;
  padding-top: 32px;
  span {
    height: 76px;
  }
}
</style>
