<template>
  <div>
  </div>
</template>

<script>
export default {
  components: {
  },
  computed: {
  },
  data () {
    return {
    }
  },
  methods: {
  },
  created () {
  }
}
</script>

<style lang="less" scoped>
</style>
