<template>
  <page title="Order Details">
    <div v-if="zujieOrder && zujieOrder.pZujie !== 3" class="flex-c" style="margin:50px 0;">
      <van-circle v-model="currentRate" layer-color="#A1DFC6" color="#00AB46"  :stroke-width="100" size="150" :rate="100" :text="text" ></van-circle>
    </div>
    <div v-else class='price-time flex-s'>
      <div class="time">{{zujieOrder && zujieOrder.pUseMins}} {{i18nOrderItem.min}}</div>
      <div>{{ zujieOrder && zujieOrder.pBorrowtime }}</div>
      <div class="time">{{zujieOrder && zujieOrder.pCurrencyName}} {{zujieOrder && zujieOrder.pJiesuan}}</div>
    </div>
    <div class="vcard" style="padding: 0 0 10px 0;">
      <div class="flex-s order-box">
        <div class="ordert-title">{{i18n.orderNo}} : {{ zujieOrder && zujieOrder.pOrderid }}</div>
        <!-- <van-icon :name="require('assets/imgs/order_icon_copy@2x.png')" size="0.58rem" @click="copy(zujieOrder.pOrderid)"></van-icon> -->
        <img src="../../assets/imgs/order_icon_copy@2x.png" @click="copy(zujieOrder.pOrderid, $event)">
      </div>
      <van-cell :title="i18n.LeaseMerchant" :label="zujieOrder && zujieOrder.pShopName" />
      <van-cell :title="i18n.startTime" :label="zujieOrder && zujieOrder.pBorrowtime" />
      <van-cell :title="i18n.ReturningMerchants" :label="zujieOrder && zujieOrder.pGhShopName" />
      <van-cell v-if="zujieOrder && zujieOrder.pType === 'chargeNowMifi'" :title="i18n.EndTime" :label="zujieOrder && zujieOrder.pGhtime" />
      <van-cell v-else :title="i18n.EndTime" :label="zujieOrder && zujieOrder.pGhtime" />
      <van-cell v-if="zujieOrder && zujieOrder.pType === 'chargeNowMifi'" :title="i18n.Deposit" :label="zujieOrder && zujieOrder.pYajin" value="*Package not included"/>
    </div>
    <div v-if="zujieOrder && zujieOrder.pType === 'chargeNowMifi' && zujieOrder.pZujie !== 3" class='flex-s equipment' @click="goRouter(`/packageDetail?orderId=${zujieOrder.pOrderid}`)">
      <div class="equipment-text" >{{i18n.trafficDetails}}</div>
      <div>
        <van-icon name="arrow" color="#00AB46"/>
      </div>
    </div>
    <div v-if="zujieOrder && zujieOrder.pType === 'chargeNowMifi'" class="detail-btn" @click="onSubmit">
      <van-button  class="bottom-pakage-btn" round >{{ zujieOrder && zujieOrder.pZujie !== 3 ? i18n.Renewals : i18n.rentagain}}</van-button>
    </div>
    <div v-if="zujieOrder.palipayjsSm === '代扣支付失败' || zujieOrder.palipayjsSm === '预授权扣款失败'" class="payment">
      <van-button round size='small' type='primary' @click.stop="onPayment">{{ i18n.payment }}</van-button>
    </div>
    <!-- <div v-if="zujieOrder && zujieOrder.pType === 'chargeNowMifi'" class="fee-box flex-c" @click="show=true">
      <van-icon :name="require('assets/imgs/icon_toll.png')" size="0.6rem"></van-icon>
      <div class="fee-btn">{{i18n.FeeSchedule}}</div>
    </div> -->
    <div class="flex-c foot" @click="goUse">
      <div style="text-align: center;margin: 20px 0;">
        <van-icon :name="require('assets/imgs/icon_usetype.png')" size="0.7rem"></van-icon>
        <div>{{i18n.HowToUse}}</div>
      </div>
    </div>
     <van-dialog v-model="show" title="Fee Schedule" :show-cancel-button="false" :show-confirm-button="false">
      <van-icon name="cross" class="cross-btn" @click="show = false"/>
      <div class="feeSchedle">
        <span class="SchedleInfo">{{i18n.fee1}}</span>
        <span class="SchedleInfo">{{i18n.fee2}}</span>
        <span class="SchedleInfo">{{i18n.fee3}}</span>
        <span class="SchedleInfo">{{i18n.fee4}}</span>
      </div>
    </van-dialog>
  </page>
</template>

<script>
import { mapState } from 'vuex'
import { MIFI_ORDERDETAIL } from '../../apis/user'
import dayjs from 'dayjs'
import Cipboard from 'clipboard'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
let timer
export default {
  components: {
  },
  computed: {
    ...mapState(['zujieOrder', 'mificode']),
    i18n () {
      return this.$t('orderDetail')
    },
    i18nOrderItem () {
      return this.$t('order_item')
    },
    i18nMsg () {
      return this.$t('msg')
    }
  },
  watch: {
    currentRate (val, oldval) {
      if (val >= 100) {
        this.currentRate = 1
      }
    }
  },
  data () {
    return {
      currentRate: 2,
      text: '1:43:00',
      show: false,
      id: '',
      mifiData: null,
      deviceInfo: null
    }
  },
  methods: {
    onPayment () {
      this.$router.push({ path: '/confirm', query: { price: this.zujieOrder.pJiesuan, payType: 'FK', outTradeNo: this.zujieOrder.pOrderid, cabinetID: this.zujieOrder.pCabinetid } })
      this.$store.commit('mificode', this.zujieOrder.pCabinetid)
    },
    goRouter (path) {
      this.$router.push(path)
    },
    goUse () {
      if (this.zujieOrder && this.zujieOrder.pType !== 'chargeNowMifi') {
        this.$router.push('/cdbUsage')
      } else {
        this.$router.push('/usage')
      }
    },
    copy (val, event) {
      console.log(val, Cipboard)
      let clip = new Cipboard(event.target, {
        text: () => val
      })
      clip.onClick(event)
      this.$toast(this.i18nMsg.copysuccess)
    },
    onSubmit () {
      console.log('续购按钮')
      if (this.mifiData && this.mifiData.data && this.mifiData.data.sn && this.zujieOrder && this.zujieOrder.pZujie !== 3) {
        this.$store.commit('mifisn', this.mifiData.data.sn)
        this.$router.push('/wifiMall?type=DDXG')
      } else {
        this.$router.replace({ name: 'homeMap' })
        // this.$toast(this.i18nMsg.nodata)
      }
    },
    timeRange () {
      let time = dayjs(this.zujieOrder.pBorrowtime).fromNow(true)
      this.text = time
      console.log(this.text)
      timer = setTimeout(() => {
        this.timeRange()
        this.currentRate += 2
      }, 2000)
    },
    getDetail () {
      this.$loading(true)
      this.$get(
        `${MIFI_ORDERDETAIL}?orderId=${this.id}`,
        {},
        resp => {
          this.$loading(false)
          console.log('数据===', resp)
          this.mifiData = resp
          this.deviceInfo = resp.data && resp.data.deviceJson ? JSON.parse(resp.data.deviceJson) : null
        },
        error => {
          this.$loading(false)
          this.$toast(error)
        }
      )
    }
  },
  created () {
    this.id = this.$route.query.orderId
    if (this.zujieOrder && this.zujieOrder.pType === 'chargeNowMifi') {
      this.getDetail()
    }
    if (this.zujieOrder && this.zujieOrder.pZujie !== 3) {
      this.timeRange()
    }
  },
  beforeDestroy () {
    clearInterval(timer)
  }
}
</script>

<style lang="less" scoped>
.payment{
  padding: 40px;
  button{
    width: 100%;
    font-size: large;
    padding: 40px;

  }
}
/deep/ .van-circle__text{
font-size: 40px;
font-family: Work Sans-Bold, Work Sans;
font-weight: bold;
color: #00AB46;
}
  .ordert-title{
font-size: 28px;
font-family: Work Sans-Medium, Work Sans;
font-weight: 500;
color: #333333;
  }
  .order-box{
    padding: 20px 20px 0 20px;
    img{
      width: 50px;
    }
  }
  /deep/ .van-cell{
    padding: 20px;
  }
  /deep/ .van-cell__label{
font-size: 24px;
font-family: Work Sans-Regular, Work Sans;
font-weight: 400;
color: #333333;
  }
  /deep/ .van-cell__title{
font-size: 22px;
font-family: Work Sans-Regular, Work Sans;
color: #999999;
  }

.orderhead-box {
  margin: 40px 40px 20px 40px;
}
.equipment {
  background: #DBEDE2;
  border-radius: 18px 18px 18px 18px;
  margin: 20px 40px;
  padding: 23px 20px;
}

.equipment-text {
font-size: 28px;
font-family: Work Sans-Regular, Work Sans;
color: #00AB46;
}
.bottom-pakage-btn{
background: linear-gradient(132deg, #1A2229 0%, #00AB46 100%);
box-shadow: 0px 6px 10px 1px rgba(0,171,70,0.2);
border-radius: 60px 60px 60px 60px;
padding: 23px 73px;
color: #fff ;
width: 100%;
font-size: 36px;
font-family: Work Sans-Bold, Work Sans;
font-weight: bold;
  }
  .detail-btn{
    padding: 40px;
  }
.fee-btn{
font-size: 24px;
font-family: Work Sans-Regular, Work Sans;
color: #00AB46;
}
.fee-box{
  padding: 0 40px 40px 40px;
}
.price-time{
background: linear-gradient(137deg, #1A2229 0%, #1A2229 0%, #00AB46 100%);
border-radius: 18px 18px 18px 18px;
opacity: 1;
color: #fff;
padding: 38px 20px;
margin: 40px;
text-align: center;
font-size: 22px;
font-family: Work Sans-Light Italic, Work Sans;
}
.time{
  font-size: 40px;
font-family: Work Sans-Bold, Work Sans;
font-weight: bold;
}
.foot{

}
.feeSchedle{
  padding: 20px;
  width: 586px;
font-size: 26px;
font-weight: 400;
color: #333333;
line-height: 36px;
}
.SchedleInfo{
  margin: 40px 0;
  display: block;
  text-align: center;
}
</style>
