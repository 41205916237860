<template>
  <div class="creditcard-input">
    <form id="form-checkout">
      <div id="form-checkout__cardNumber" class="container"></div>
      <div id="form-checkout__expirationDate" class="container"></div>
      <div id="form-checkout__securityCode" class="container"></div>
      <input type="text" id="form-checkout__cardholderName" />
      <select id="form-checkout__issuer"></select>
      <select id="form-checkout__installments"></select>
      <select id="form-checkout__identificationType"></select>
      <input type="text" id="form-checkout__identificationNumber" />
      <input type="email" id="form-checkout__cardholderEmail" />

      <button type="submit" id="form-checkout__submit">Pay</button>
      <progress value="0" class="progress-bar">Loading...</progress>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { loadMercadoPago } from '@mercadopago/sdk-js'
import { domain } from '../../utils/http'
import { MERCADO_PAYMENTINFO } from '../../apis/payment'
let mp = null
let cardForm = null
export default {
  components: {
  },
  computed: {
    ...mapState(['system', 'cabinet', 'agentOpenId']),
    i18n () {
      return this.$t('paymethod')
    },
    i18nConfirm () {
      return this.$t('confirm')
    }
  },
  data () {
    return {
      price: '',
      payKey: ''
    }
  },
  methods: {
    async loadMercadopagoVo () {
      let that = this
      await loadMercadoPago()
      mp = new window.MercadoPago(this.payKey)
      cardForm = mp.cardForm({
        amount: that.price + '',
        iframe: true,
        form: {
          id: 'form-checkout',
          cardNumber: {
            id: 'form-checkout__cardNumber',
            placeholder: 'Card Number'
          },
          expirationDate: {
            id: 'form-checkout__expirationDate',
            placeholder: 'MM/YY'
          },
          securityCode: {
            id: 'form-checkout__securityCode',
            placeholder: 'Security Code'
          },
          cardholderName: {
            id: 'form-checkout__cardholderName',
            placeholder: 'Cardholder'
          },
          issuer: {
            id: 'form-checkout__issuer',
            placeholder: 'Issuing bank'
          },
          installments: {
            id: 'form-checkout__installments',
            placeholder: 'Installments'
          },
          identificationType: {
            id: 'form-checkout__identificationType',
            placeholder: 'Document type'
          },
          identificationNumber: {
            id: 'form-checkout__identificationNumber',
            placeholder: 'Document number'
          },
          cardholderEmail: {
            id: 'form-checkout__cardholderEmail',
            placeholder: 'Email'
          }
        },
        callbacks: {
          onFormMounted: error => {
            if (error) return console.warn('Form Mounted handling error: ', error)
            console.log('Form mounted')
          },
          onSubmit: event => {
            event.preventDefault()

            const {
              paymentMethodId,
              issuerId,
              cardholderEmail: email,
              amount,
              token,
              installments,
              identificationNumber,
              identificationType
            } = cardForm.getCardFormData()
            console.log('MercadoPagotoken====', token)
            if (this.system === 'android') {
              window.open(`${domain}/#/MercadoPago?token=${token}&installments=${installments}&paymentMethodId=${paymentMethodId}&issuerId=${issuerId}`)
            } else {
              window.location.href = `${domain}/#/MercadoPago?token=${token}&installments=${installments}&paymentMethodId=${paymentMethodId}&issuerId=${issuerId}`
            }
          },
          onFetching: (resource) => {
            console.log('Fetching resource: ', resource)

            // Animate progress bar
            const progressBar = document.querySelector('.progress-bar')
            progressBar.removeAttribute('value')

            return () => {
              progressBar.setAttribute('value', '0')
            }
          }
        }
      })
    },
    onMercadopago () {
      mp = null
      cardForm = null
      this.loadMercadopagoVo()
    },
    getPayKey () {
      this.$loading(true)
      this.$get(
        MERCADO_PAYMENTINFO,
        {},
        res => {
          this.$loading(false)
          if (res.data) {
            this.payKey = res.data
            this.onMercadopago()
          }
        },
        err => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: err,
            showCancel: false
          }).then(() => {
            this.getPayKey()
          })
        },
        {
          headers: {
            agentOpenId: this.$route.query.agentId || this.agentOpenId
          }
        }
      )
    }
  },
  created () {
    console.log('路由数据===', this.$route.query)
    if (this.$route.query && this.$route.query.price) {
      this.price = this.$route.query.price
    } else {
      this.price = '500'
    }
    this.getPayKey()
  }
}
</script>

<style lang="less" scoped>
.bottom-pakage-btn {
  background: linear-gradient(132deg, #1A2229 0%, #00AB46 100%);
  box-shadow: 0px 6px 10px 1px rgba(0, 171, 70, 0.2);
  border-radius: 60px 60px 60px 60px;
  color: #fff;
  font-size: 36px;
  font-family: Segoe UI-Semibold, Segoe UI;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 44px;
}

.submit-bar {
  position: fixed;
  bottom: 68px;
  margin: 0 40px;
  width: 90%;

}

.submit-bar {
  position: fixed;
  bottom: 68px;
  margin: 0 40px;
  width: 90%;

}

.pay-amount {
  padding: 43px 20px;
  background: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  margin-top: 40px;

  .pay-amount-title {
    font-size: 30px;
    font-family: Work Sans-Medium, Work Sans;
    font-weight: 500;
    color: #444444;
  }

  .doller {
    font-size: 30px;
    font-family: Work Sans-Regular, Work Sans;
  }

  .pay-price {
    font-size: 54px;
    font-family: Work Sans-Bold, Work Sans;
    font-weight: bold;
    padding: 0 10px;
  }
}

.pay-h1 {
  font-size: 32px;
  font-family: Work Sans-Bold, Work Sans;
  font-weight: bold;
  color: #333333;
  padding: 40px 40px 20px 40px;
}

.pay-option {
  margin-bottom: 20px;
  padding-left: 40px;
  .pay-info{
    margin-left: 40px;
    .pay-info-title{
font-size: 30px;
font-family: Work Sans-Medium, Work Sans;
color: #444444;
margin-bottom: 10px;
    }
    .pay-info-price{
font-size: 26px;
font-family: Work Sans-Regular, Work Sans;
font-weight: 400;
color: #00AB46;
    }
  }
}
.addcard{
  margin: 0 40px;
  padding: 40px;
border-radius: 18px 18px 18px 18px;
border: 2px dotted #00AB46;
font-size: 30px;
font-family: Work Sans-Medium, Work Sans;
color: #00AB46;
}
</style>
